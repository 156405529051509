import React, { useState } from 'react';
import { StyleSheet, View, TextInput, Text, Pressable } from 'react-native';

import _noop from 'lodash/noop';
import _get from 'lodash/get';
import { COLORS } from '../../constants/colors';
import { IMAGES_VS_SEARCH_TYPE } from '../../constants/common';
import { SVGS } from '../../assets/svgs';
import { FlatList } from 'react-native-gesture-handler';

const SearchBarWithAutocomplete = props => {
  const [inputSize, setInputSize] = useState({ width: 0, height: 0 });

  const {
    value,
    style,
    onChangeText,
    onPredictionTapped,
    predictions,
    showPredictions,
    renderLeftButton = _noop,
    renderRightButton = _noop,
  } = props;

  const { container, inputStyle } = styles;
  const passedStyles = Array.isArray(style) ? Object.assign({}, ...style) : style;

  const renderItem = ({ item }) => {
    const SearchTypeImage = IMAGES_VS_SEARCH_TYPE[_get(item, 'searchType')] || SVGS.GOOGLE_SEARCH;

    return (
      <Pressable style={styles.predictionRow} onPress={() => onPredictionTapped(item)}>
        <View style={{ width: 24 }}>
          <SearchTypeImage width={24} height={24} />
        </View>
        <Text numberOfLines={1} style={{ flex: 1,textAlign: 'left' }}>
          {item.description}
        </Text>
      </Pressable>
    );
  };

  const renderPredictions = predictionsArray => {
    const { predictionsContainer } = styles;

    return (
      <View style={predictionsContainer}>
        <FlatList
          data={predictionsArray}
          renderItem={renderItem}
          keyExtractor={item => item.place_id || item.spaceId || item.spaceDisplayName}
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
          bounces={false}
        />
      </View>
    );
  };

  return (
    <View style={[container, { ...passedStyles }]}>
      <View style={[inputStyle, { flexDirection: 'row' }]}>
        {renderLeftButton()}
        <TextInput
          placeholder="Search by address"
          placeholderTextColor="gray"
          value={value}
          onChangeText={onChangeText}
          returnKeyType="search"
          onLayout={event => {
            const { height, width } = event.nativeEvent.layout;
            setInputSize({ height, width });
          }}
          style={styles.textInput}
        />
        {renderRightButton()}
      </View>
      {showPredictions && renderPredictions(predictions)}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    position: 'relative',
  },
  inputStyle: {
    height: 45,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 12,
    borderWidth: 1,
    borderColor: COLORS.PRIMARY_BORDER_COLOR,
    borderRadius: 8,
    position: 'relative',
  },
  // predictionsContainer: {
  //   paddingHorizontal: 16,
  //   borderBottomLeftRadius: 10,
  //   borderBottomRightRadius: 10,
  //   backgroundColor: '#FFF',
  //   position: 'absolute',
  //   top: 50,
  //   zIndex: 100000, // Forcing it to front
  //   width: '100%',
  //   maxHeight: 250,
  //   overflow: 'scroll',
  //   borderRadius: 5,
  //   shadowColor: '#00000099',
  //   shadowOffset: {
  //     width: 0,
  //     height: 12,
  //   },
  //   shadowOpacity: 0.3,
  //   shadowRadius: 15.46,

  //   elevation: 20,
  // },
  predictionsContainer: {
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 4,
    shadowOpacity: 0.2,
    backgroundColor: 'white',
    position: 'absolute',
    top: 50,
    width: '100%',
    maxHeight: 250,
    borderRadius: 8,
    paddingHorizontal: 8,
    elevation: 20,
  },
  predictionRow: {
    paddingVertical: 12,
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 4,
  },
  textInput: {
    flex: 1,
    paddingHorizontal: 12,
  },
});

export default SearchBarWithAutocomplete;
