import { StyleSheet } from 'react-native';

export const ColorfulStyle = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    height: 60,
    width: '100%',
    flexDirection: 'row',
  },
  item: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 6,
    // alignItems: 'center',
  },
  touchableItem: {
    flex: 1,
    padding: 5,
  },
  filterColor: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 1,
    padding: 10,
    borderRadius: 30,
    overflow: 'hidden',
  },
  itemIconNotFound: {
    borderWidth: 2,
    width: 23,
    height: 23,
  },
  itemText: {
    fontWeight: 'bold',
    maxWidth: 90,
    fontSize: 14,
  },
});
