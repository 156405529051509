import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  View,
  StyleSheet,
  ScrollView,
  Dimensions,
  ActivityIndicator,
  ImageBackground,
  Linking,
  Pressable,
} from 'react-native';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Feather } from '@expo/vector-icons';
import { useToast } from 'react-native-toast-notifications';
import _get from 'lodash/get';
import _once from 'lodash/once';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import { auth } from '../../../firebaseConfig';
import { addUserToEventForMeetingRoomBooking, getEvent } from '../../api/common';
import Header from '../../components/Header';
import Button from '../../components/Button';
import { ROUTES } from '../../constants/routes';
import { COLORS } from '../../constants/colors';
import { SPACING } from '../../constants/common';
import Carousel from '../../components/carousel';
import Text from '../../components/Text';
import IconLabelCard from '../../components/IconLabelCard';
import { getFullAddress, getGoogleMapsLinkForSpaceDetails, getLandmark, isOfficeSpace } from '../../utils/common';

const { width } = Dimensions.get('window');
const IMAGE_WIDTH = width - 2 * SPACING;

const EventDetails = ({ route, user, navigation }) => {
  const { eventId } = route.params;
  const uid = _get(user, 'uid');
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [eventDetails, setEventDetails] = useState(null);
  const idToken = auth?.currentUser?.getIdToken();
  useEffect(() => {
    async function fetchEvent() {
      setLoading(true);
      const response = await getEvent(eventId, uid);
      setEventDetails(response);
      setLoading(false);
    }
    const initialzeEvent = _once(fetchEvent);
    if (eventId && uid && idToken && _isEmpty(eventDetails) && loading) initialzeEvent();
  }, [eventId, uid, idToken, eventDetails, loading]);

  const takeUserBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.replace(ROUTES.EVENTS.name);
    }
  }, [navigation]);

  const onJoinNowPress = useCallback(async () => {
    if (isOfficeSpace(eventDetails)) {
      const { spaceId, startTime } = eventDetails;
      navigation.navigate(ROUTES.SPACE_BOOKINGS.name, { spaceId, eventId, timeStamp: startTime });
    } else {
      const response = await addUserToEventForMeetingRoomBooking(eventId, uid);
      if (!_isEmpty(response)) {
        toast.show('Thanks for joining the event.');
        takeUserBack();
      } else toast.show('Error joining the event. Please try again');
    }
  }, [navigation, eventId, eventDetails, toast, uid, takeUserBack]);

  const onClickOpenMaps = useCallback(() => {
    Linking.openURL(getGoogleMapsLinkForSpaceDetails(eventDetails));
  }, [eventDetails]);

  const userJoinedEvent = useMemo(() => {
    const attendeeUids = _map(_get(eventDetails, 'attendees'), attendee => _get(attendee, 'uid'));
    return _includes(attendeeUids, uid);
  }, [uid, eventDetails]);

  if (loading)
    return (
      <View style={[styles.containerPadding, styles.centerView]}>
        <ActivityIndicator />
      </View>
    );

  return (
    <View style={styles.container}>
      <Header title={_get(eventDetails, 'eventName')} />
      <ScrollView style={styles.containerPadding} contentContainerStyle={{ paddingBottom: 100 }}>
        <View style={[styles.image, styles.marginBottom]}>
          <Carousel
            data={[_get(eventDetails, 'spacePhoto')]}
            hidePagination
            paginationWidth={IMAGE_WIDTH}
            renderItem={({ item }) => (
              <ImageBackground style={styles.image} source={{ uri: item }} resizeMode="cover" />
            )}
            keyExtractor={item => item}
          />
        </View>

        <Text size={2} variant="bold">
          {_get(eventDetails, 'eventName')}
        </Text>
        <Text variant="bold" size={2} color={COLORS.PRIMARY_COLOR} style={styles.marginBottom}>
          {moment(_get(eventDetails, 'startTime')).calendar()}
        </Text>

        <Text size={3} variant="bold" style={styles.subMarginBottom}>
          Description
        </Text>
        <Text size={3} variant="medium" style={styles.marginBottom} color={COLORS.TEXT_COLOR_SECONDARY}>
          {_get(eventDetails, 'eventDescription')}
        </Text>
        <Text size={3} variant="bold" style={styles.subMarginBottom}>
          Organizer
        </Text>
        <Text size={3} variant="medium" style={styles.marginBottom} color={COLORS.TEXT_COLOR_SECONDARY}>
          {_get(eventDetails, 'createdByDisplayName')}
        </Text>

        <Text size={3} variant="bold" style={styles.subMarginBottom}>
          {`Attendees (${_size(_get(eventDetails, 'attendees'))})`}
        </Text>
        <View style={styles.marginBottom}>
          {_map(_get(eventDetails, 'attendees'), attendee => (
            <Text size={3} variant="medium" color={COLORS.TEXT_COLOR_SECONDARY}>
              {`${_get(attendee, 'displayName')} ${
                _get(attendee, 'displayName') ? ` (${_get(attendee, 'orgTeamName')})` : ''
              }`}
            </Text>
          ))}
        </View>

        <Text size={3} variant="bold" style={styles.subMarginBottom}>
          Location
        </Text>
        <Text size={3} variant="medium" style={styles.subMarginBottom} color={COLORS.TEXT_COLOR_SECONDARY}>
          {getFullAddress(_get(eventDetails, 'spaceaddress'))}
        </Text>

        <View style={[styles.textInlineStyle, styles.marginBottom]}>
          <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY} variant="semiBold">
            Landmark:{' '}
          </Text>
          <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY}>
            {getLandmark(_get(eventDetails, 'spaceaddress'))}
          </Text>
        </View>

        <Text size={3} variant="bold" style={styles.subMarginBottom}>
          Quick Actions
        </Text>
        <Pressable style={styles.iconLabelStyle} onPress={onClickOpenMaps}>
          <IconLabelCard
            label="Open in Maps"
            iconFamily={Feather}
            iconProps={{ name: 'send', size: 16 }}
            containerStyle={styles.iconLabelStyle}
          />
        </Pressable>
      </ScrollView>

      {!userJoinedEvent ? (
        <View style={styles.buttonStyle}>
          <Button textProps={{ variant: 'bold', size: 3 }} onPress={onJoinNowPress}>
            Join
          </Button>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
  },
  containerPadding: {
    paddingHorizontal: SPACING,
    paddingTop: SPACING,
  },
  image: {
    width: width - 2 * SPACING,
    height: (1 / 1.78) * (width - 2 * SPACING),
    resizeMode: 'cover',
    borderRadius: 16,
    overflow: 'hidden',
  },
  buttonStyle: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    padding: 16,
    backgroundColor: COLORS.WHITE,
  },
  marginBottom: {
    marginBottom: 16,
  },
  subMarginBottom: {
    marginBottom: 8,
  },
  textInlineStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(EventDetails);
