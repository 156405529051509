import { useCallback, useRef, useState } from 'react';
import { View, StyleSheet, Pressable, ActivityIndicator, Dimensions } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { connect } from 'react-redux';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { useToast } from 'react-native-toast-notifications';
import { query, ref, equalTo, orderByChild, get } from 'firebase/database';
import _values from 'lodash/values';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';

import TextInput from '../TextInput';
import Button from '../Button';
import Text from '../Text';
import { database } from '../../../firebaseConfig';
import { STATUS } from '../../constants/common';
import { createEventPayload, getActiveBookingsForEvents } from '../../utils/common';
import Separator from '../Separator';
import { COLORS } from '../../constants/colors';
import { SpaceCardSmallWithSelection } from '../cards';

const { width } = Dimensions.get('window');

const CreateEvent = ({ user, customView, onCreateEvent }) => {
  const toast = useToast();
  const [eventName, setEventName] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [activeBookings, setActiveBookings] = useState([]);
  const [gettingBookings, setGettingBookings] = useState(false);
  const [creatingEvent, setCreatingInvite] = useState(false);
  const createEventRef = useRef(null);

  const uid = _get(user, 'uid');

  const onOpen = () => {
    if (createEventRef?.current) {
      createEventRef.current?.open();
    }
  };

  const onCreateEventClick = async () => {
    setCreatingInvite(true);
    try {
      const payload = createEventPayload(eventName, eventDescription, selectedBooking, user);
      if (_get(payload, 'status') === STATUS.SUCCESS) {
        await onCreateEvent(_get(payload, 'payload'));
        toast.show('Event created successfully');
        onCancel();
      } else {
        toast.show(_get(payload, 'msg'));
      }
    } catch {
      toast.show('Error creating event. please try again');
    } finally {
      setCreatingInvite(false);
    }
  };

  const onCancel = () => {
    createEventRef.current?.close();
    setEventName('');
    setEventDescription('');
    setSelectedBooking(null);
    setActiveBookings([]);
  };

  const getActiveBookings = async () => {
    if (uid) {
      setGettingBookings(true);
      const bookingsRef = query(ref(database, 'bookings'), ...[orderByChild('custId'), equalTo(`${uid}`)]);
      const snapshot = await get(bookingsRef);
      let data = null;
      if (snapshot.exists()) {
        data = getActiveBookingsForEvents(_values(snapshot.val()));
      } else {
        data = [];
      }
      if (!_isEmpty(data)) {
        setActiveBookings(data);
        setGettingBookings(false);
        onOpen();
      } else {
        toast.show("You don't have any active bookings. Please create a booking to create event.");
        setGettingBookings(false);
      }
    }
  };

  const renderVisibleView = () => (
    <Pressable onPress={getActiveBookings}>{gettingBookings ? <ActivityIndicator /> : customView}</Pressable>
  );

  const onSelectBooking = useCallback(
    bookingId => {
      if (selectedBooking === bookingId) setSelectedBooking(null);
      else setSelectedBooking(bookingId);
    },
    [selectedBooking]
  );

  const renderActiveBooking = ({ item }) => {
    let style = { width: width - 40 };
    if (_size(activeBookings) > 1) style = { width: width * 0.8 };
    return (
      <SpaceCardSmallWithSelection
        space={item}
        selected={selectedBooking === _get(item, 'bookingkey')}
        style={style}
        showGoFLoatersName={false}
        onPress={onSelectBooking}
      />
    );
  };

  const separator = useCallback(() => <Separator color={COLORS.PRIMARY_BACKGROUND} gap={8} />, []);

  return (
    <View style={styles.container}>
      {renderVisibleView()}
      <Portal>
        <Modalize
          adjustToContentHeight
          ref={createEventRef}
          withHandle={false}
          onOverlayPress={onCancel}
          modalStyle={{ padding: 16 }}
          panGestureEnabled={false}
          scrollViewProps={{
            keyboardShouldPersistTaps: 'handled',
            scrollEnabled: false,
            nestedScrollEnabled: true,
            keyboardDismissMode: 'none',
          }}>
          <View style={styles.fullFlex}>
            <Text size={9} style={styles.subMargin}>
              Add Event
            </Text>
            <View style={styles.fullFlex}>
              <TextInput
                label="Event Name"
                value={eventName}
                onChangeText={setEventName}
                placeholder="Comments"
                containerStyle={styles.marginBottom}
              />
              <TextInput
                label="Description"
                multiline
                value={eventDescription}
                onChangeText={setEventDescription}
                placeholder="Comments"
                containerStyle={[styles.eventDesciptionContainer, styles.marginBottom]}
                style={styles.eventDescription}
              />
              <Text style={{ marginBottom: 4 }} size={2} color={COLORS.TEXT_COLOR_SECONDARY} variant="semiBold">
                Select Booking
              </Text>
              <FlatList
                data={activeBookings}
                renderItem={renderActiveBooking}
                keyExtractor={item => _get(item, 'bookingkey')}
                horizontal
                ItemSeparatorComponent={separator}
                showsHorizontalScrollIndicator={false}
              />
            </View>
            <View style={styles.userActionStyle}>
              <Button style={{ flex: 1 }} variant="medium" onPress={onCreateEventClick} loading={creatingEvent}>
                Create
              </Button>
              <Button style={{ flex: 1 }} variant="medium" bordered onPress={onCancel}>
                Cancel
              </Button>
            </View>
          </View>
        </Modalize>
      </Portal>
    </View>
  );
};

const styles = StyleSheet.create({
  eventDesciptionContainer: {
    height: 'auto',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  fullFlex: {
    flex: 1,
  },
  eventDescription: {
    height: 100,
  },
  marginBottom: {
    marginBottom: 16,
  },
  flexRowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconButtonContainer: {
    columnGap: 8,
  },
  iconBackgroundStyle: {
    width: 24,
    height: 24,
    padding: 6,
    borderRadius: 4,
  },
  subMargin: {
    marginBottom: 8,
  },
  userActionStyle: {
    flexDirection: 'row',
    columnGap: 16,
    marginBottom: 16,
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(CreateEvent);
