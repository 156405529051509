import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

export const getShowOrgLevelCredits = state => _get(state, 'user.organization.config.showOrgLevelCredits');
export const getShowWhereIsOrg = state => _get(state, 'user.organization.config.showWhereIsOrg');
export const getShowMemberNames = state => _get(state, 'user.organization.config.showMemberNames');
export const getOrgCredits = state => _get(state, 'user.organization.orgCredits');
export const getOrganizationId = state => _get(state, 'user.organization.organizationId');
// export const getShowMemberNames = state => _get(state, 'user.organization.config.showMemberNames')
export const getShowRates = state => _get(state, 'user.organization.config.showRates');
export const getBlockAppUsage = state => _get(state, 'user.organization.config.blockAppUsage');
export const getBlockWebUsage = state => _get(state, 'user.organization.config.blockWebUsage');
export const getCanUseSOSFeature = state => !_isEmpty(_get(state, 'user.organization.config.sosContacts'));
export const getSOSHelpline = state => {
  // console.log({ organisation: _get(state, 'user.organization') });
  return _get(state, 'user.organization.config.sosHelpLine');
};
