import { useCallback, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, FlatList, ActivityIndicator } from 'react-native';
import { connect } from 'react-redux';
import { query, onValue, ref, equalTo, orderByChild } from 'firebase/database';
import _get from 'lodash/get';
import _values from 'lodash/values';
import _map from 'lodash/map';
import _isNull from 'lodash/isNull';
import { BookingsCard, SpaceCardSmall } from '../../components/cards';
import { COLORS } from '../../constants/colors';
import { database } from '../../../firebaseConfig';
import { BOOKING_TYPES, BOOKING_TYPE_VALUES, SPACING } from '../../constants/common';
import { getBookingsByType } from '../../utils/common';
import Button from '../../components/Button';
import Text from '../../components/Text';
import { ROUTES } from '../../constants/routes';

function Bookings({ user, navigation, organization }) {
  const [bookings, setBookings] = useState(null);
  const [bookingType, setBookingType] = useState(BOOKING_TYPE_VALUES.ACTIVE);
  const uid = _get(user, 'uid');
  useEffect(() => {
    let unsubscribe = () => {};
    if (uid) {
      const bookingsRef = query(ref(database, 'bookings'), ...[orderByChild('custId'), equalTo(`${uid}`)]);
      unsubscribe = onValue(bookingsRef, snapshot => {
        const bookingsResponse = snapshot.val();
        setBookings(_values(bookingsResponse));
      });
    }
    return unsubscribe;
  }, [uid]);

  const bookingsByType = useMemo(() => getBookingsByType(bookings, bookingType), [bookings, bookingType]);

  const onBookingCardPress = bookingkey => {
    navigation.navigate(ROUTES.BOOKING_DETAILS.name, { bookingkey });
  };

  const renderBookingItem = useCallback(
    ({ item: booking }) => {
      if (bookingType === BOOKING_TYPE_VALUES.ACTIVE)
        return (
          <BookingsCard
            bookingInfo={booking}
            organization={organization}
            navigation={navigation}
            user={user}
            onPress={onBookingCardPress}
          />
        );
      return <SpaceCardSmall space={booking} navigation={navigation} user={user} onPress={onBookingCardPress} />;
    },
    [bookingType]
  );

  const renderBookingTypes = useMemo(
    () => (
      <View style={styles.bookingTypesContainer}>
        {_map(BOOKING_TYPES, type => {
          const buttonStyle = [styles.bookingTypeStyle];
          const textStyle = [{ color: COLORS.WHITE }];
          if (_get(type, 'value') !== bookingType) {
            buttonStyle.push(styles.bookingInactivestyle);
            textStyle.push({ color: COLORS.TEXT_COLOR_SECONDARY });
          }
          return (
            <Button
              onPress={() => setBookingType(_get(type, 'value'))}
              style={buttonStyle}
              variant="small"
              textProps={{
                size: 3,
                style: textStyle,
              }}
              key={_get(type, 'value')}>
              {_get(type, 'label')}
            </Button>
          );
        })}
      </View>
    ),
    [bookingType]
  );

  const listEmptyComponent = useMemo(() => {
    if (_isNull(bookings)) {
      return (
        <Text variant="bold" size={4} style={{ alignSelf: 'center' }}>
          Loading…
        </Text>
      );
    }
    return (
      <Text variant="bold" size={4} style={{ alignSelf: 'center' }}>
        No Bookings
      </Text>
    );
  }, [bookingsByType, bookings]);

  return (
    <View style={styles.container}>
      <FlatList
        data={bookingsByType}
        renderItem={renderBookingItem}
        keyExtractor={(item, index) => _get(item, 'bookingid') + index}
        stickyHeaderIndices={[0]}
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={renderBookingTypes}
        ListEmptyComponent={listEmptyComponent}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  containerPadding: {
    paddingHorizontal: SPACING,
    paddingTop: SPACING,
  },
  centerView: {
    flex: 1,
    alignItems: 'center',
  },
  bookingTypeStyle: {
    paddingHorizontal: 12,
  },
  bookingInactivestyle: {
    backgroundColor: COLORS.BUTTON_COLORS.TERTIARY_COLOR,
    color: COLORS.TEXT_COLOR_SECONDARY,
  },
  bookingTypesContainer: {
    flexDirection: 'row',
    columnGap: 16,
    paddingBottom: 20,
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
  organization: state.user.organization,
});

export default connect(mapStateToProps, {})(Bookings);
