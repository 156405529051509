import { useCallback, useMemo, useRef, useState } from 'react';
import { View, StyleSheet, ScrollView, Pressable, ActivityIndicator } from 'react-native';
import { connect } from 'react-redux';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { useToast } from 'react-native-toast-notifications';
import _size from 'lodash/size';
import _concat from 'lodash/concat';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

import { COLORS } from '../../constants/colors';
import Button from '../Button';
import { getUserTeam, inviteTeam } from '../../api/common';
import Text from '../Text';
import { AutocompleteDropdown } from '../searchDropDown';
import { getInviteTeamPayload, getSearchableTeamDropDownOptions } from '../../utils/common';
import IndividualMemberView from './IndividualMemberView';

const InviteTeam = ({
  user,
  onSavePress,
  customView = null,
  saveButtonLabel = 'Invite',
  clearOnCancel = true,
  customViewStyle = {},
  modalHeading = 'Invite Team Members',
}) => {
  const toast = useToast();
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [gettingTeamMembers, setGettingteamMembers] = useState(false);
  const [invitingMembers, setInvitingMembers] = useState(false);
  const inviteTeamSheetRef = useRef(null);
  const searchTeamRef = useRef(null);
  const onOpen = () => {
    if (inviteTeamSheetRef?.current) {
      inviteTeamSheetRef.current?.open();
    }
  };

  const onCancel = () => {
    inviteTeamSheetRef.current?.close();
    if (clearOnCancel) {
      setSelectedMembers([]);
      setTeamMembers([]);
    }
  };

  const onAddTeamPress = useCallback(async () => {
    // if (_size(selectedMembers) > 0) {
    //   setInvitingMembers(true);
    //   try {
    //     const payload = getInviteTeamPayload(booking, user, selectedMembers);
    //     await inviteTeam(payload);
    //     toast.show('Team members invited successfully');
    //     onCancel();
    //   } catch {
    //     toast.show('Error inviting team. Please try again.');
    //   } finally {
    //     setInvitingMembers(false);
    //   }
    // } else {
    //   toast.show('Please select team members to invite.');
    // }
    onSavePress(selectedMembers, setInvitingMembers, onCancel);
  }, [selectedMembers]);

  const removeSelectedMember = uid => {
    setSelectedMembers(_filter(selectedMembers, selectedMember => _get(selectedMember, 'uid') !== uid));
  };

  const getTeamMembers = async () => {
    setGettingteamMembers(true);
    try {
      const response = await getUserTeam(user);
      setTeamMembers(response);
      onOpen();
    } catch {
      toast.show('Error getting team details. Please try again');
    } finally {
      setGettingteamMembers(false);
    }
  };

  const onSelectTeamMember = selectedMember => {
    if (selectedMember) {
      setSelectedMembers(_concat(selectedMembers, _find(teamMembers, { uid: _get(selectedMember, 'id') })));
      setTimeout(() => {
        searchTeamRef?.current?.close();
      }, 0);
    }
  };

  const memberOptions = useMemo(
    () => getSearchableTeamDropDownOptions(teamMembers, selectedMembers),
    [teamMembers, selectedMembers]
  );
  // const memberOptions = TEMP_MEMBERS;

  const renderVisibleView = () =>
    customView ? (
      <Pressable style={customViewStyle} onPress={getTeamMembers}>
        {gettingTeamMembers ? <ActivityIndicator /> : customView}
      </Pressable>
    ) : (
      <Button
        onPress={getTeamMembers}
        loading={gettingTeamMembers}
        textProps={{
          style: { letterSpacing: 0, color: COLORS.WHITE },
          size: 2,
          variant: 'semiBold',
        }}>
        Invite Team
      </Button>
    );

  return (
    <View style={styles.container}>
      {renderVisibleView()}
      <Portal>
        <Modalize
          // adjustToContentHeight
          ref={inviteTeamSheetRef}
          withHandle={false}
          modalStyle={{ padding: 16 }}
          panGestureEnabled={false}
          scrollViewProps={{
            keyboardShouldPersistTaps: 'handled',
            scrollEnabled: false,
            nestedScrollEnabled: true,
            keyboardDismissMode: 'none',
            contentContainerStyle: { flex: 1 },
          }}>
          <View style={{ flex: 1 }}>
            <Text size={9} style={styles.subMargin}>
              {modalHeading}
            </Text>
            <View style={[styles.marginBottom, { zIndex: 10 }]}>
              <AutocompleteDropdown
                controller={controller => {
                  searchTeamRef.current = controller;
                }}
                clearOnFocus={false}
                closeOnSubmit={false}
                onSelectItem={onSelectTeamMember}
                dataSet={memberOptions}
                shouldSetTextonSelect={false}
              />
            </View>
            <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY} style={styles.subMargin}>{`${_size(
              selectedMembers
            )} team members added`}</Text>
            <ScrollView
              contentContainerStyle={styles.selectedMembersStyle}
              style={styles.memberContainerStyle}
              scrollEnabled={false}
              keyboardShouldPersistTaps="handled">
              {_map(selectedMembers, (selectedMember, index) => (
                <IndividualMemberView
                  member={selectedMember}
                  key={_get(selectedMember, 'uid') + index}
                  removeSelectedMember={removeSelectedMember}
                />
              ))}
            </ScrollView>
            <View style={styles.userActionStyle}>
              <Button style={{ flex: 1 }} variant="medium" onPress={onAddTeamPress} loading={invitingMembers}>
                {saveButtonLabel}
              </Button>
              <Button style={{ flex: 1 }} variant="medium" bordered onPress={onCancel}>
                Cancel
              </Button>
            </View>
          </View>
        </Modalize>
      </Portal>
    </View>
  );
};

const styles = StyleSheet.create({
  feedbackContainer: {
    height: 'auto',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  feedbackInput: {
    height: 100,
  },
  marginBottom: {
    marginBottom: 16,
  },
  flexRowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconButtonContainer: {
    columnGap: 8,
  },
  iconBackgroundStyle: {
    width: 24,
    height: 24,
    padding: 6,
    borderRadius: 4,
  },
  subMargin: {
    marginBottom: 8,
  },
  selectedMembersStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  userActionStyle: {
    flexDirection: 'row',
    columnGap: 16,
    marginBottom: 16,
  },
  memberContainerStyle: {
    minHeight: 400,
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(InviteTeam);
