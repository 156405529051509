import { useEffect, useMemo, useRef, useState } from 'react';
import { View, StyleSheet, FlatList, Platform } from 'react-native';
import { connect } from 'react-redux';

import _take from 'lodash/take';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _values from 'lodash/values';
import _size from 'lodash/size';

import { Feather, AntDesign } from '@expo/vector-icons';
import { COLORS } from '../../constants/colors';
import QuickFilters from '../../components/QuickFilters';
import { SpaceCard } from '../../components/cards';
import Button from '../../components/Button';
import { getSearchResults } from '../../api/common';
import {
  getFilteredSpaces,
  getSelectedCityLatLng,
  getSpacesWithAppliedSort,
  getValidSearchSpaces,
} from '../../utils/common';
import Text from '../../components/Text';
import Skeleton from '../../components/skeleton';
import { SEARCH_TYPE, SPACE_TYPES, SPACE_TYPE_VALUES } from '../../constants/common';
import Separator from '../../components/Separator';
import SearchBarWithAutocomplete from '../../components/search';
import { ROUTES } from '../../constants/routes';
import { clearSpaces, setSpaces as setSpacesAction, setSpacesLoading } from '../../reducers/spaces';
import { getShowRates } from '../../selectors/organization';
import SortSpaces from '../../components/sortSpaces';

const SPACES_PER_PAGE = 10;

const Search = ({
  user,
  metadata,
  navigation,
  setSpaces,
  searchResults,
  loadingSpaces,
  setLoadingSpaces,
  organization,
  showRates,
  clearSpacesData,
}) => {
  const [searchCity, setSearchCity] = useState(_get(user, 'location.city'));

  const [selectedLocality, setSelectedLocality] = useState(() => {
    const city = _get(user, 'location.city');
    const cityLatLng = getSelectedCityLatLng(city, metadata);
    const lat = _get(cityLatLng, 'lat');
    const lng = _get(cityLatLng, 'long');
    return {
      city,
      coords: { lat, lng },
    };
  });
  const [spaceSubType, setSpaceSubType] = useState(SPACE_TYPE_VALUES.OFFICE_SPACES);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [selectedSort, setSelectedSort] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [seatSelectionPosition, setShowSeatSelectionPosition] = useState(null);
  const searchCityRef = useRef();

  const uid = _get(user, 'uid');

  const setUserCityDetails = () => {
    const city = _get(user, 'location.city');
    searchCityRef?.current?.setAddressText(city);
    const cityLatLng = getSelectedCityLatLng(city, metadata);
    const lat = _get(cityLatLng, 'lat');
    const lng = _get(cityLatLng, 'long');
    setSelectedLocality({
      city,
      coords: { lat, lng },
    });
    setSearchCity(city);
  };

  useEffect(() => {
    const city = _get(user, 'location.city');
    searchCityRef?.current?.setAddressText(city);
  }, [searchCityRef, user]);

  useEffect(() => {
    async function fetchSearchResults() {
      const { coords } = selectedLocality;
      const { lat, lng } = coords;
      setLoadingSpaces(true);
      setCurrentPage(0);
      const results = await getSearchResults(lat, lng, spaceSubType, uid, selectedLocality);
      const validSpaces = getValidSearchSpaces(_values(results), spaceSubType);
      setSpaces(validSpaces);
      setLoadingSpaces(false);
    }
    const { coords } = selectedLocality;
    const { lat, lng } = coords;
    if (!_isEmpty(coords) && lat && lng) fetchSearchResults();
  }, [uid, spaceSubType, selectedLocality, setSpaces, setLoadingSpaces]);

  const onLocationSelect = async details => {
    if (_get(details, 'searchType') === SEARCH_TYPE.GOFLOATERS) {
      navigation.navigate(ROUTES.SPACE_DETAILS.name, { spaceId: _get(details, 'spaceId') });
      return;
    }
    const locationLatLng = _get(details, 'geometry.location');

    // setSearchCityLatLng(locationLatLng);
    setSelectedLocality({
      ...details,
      coords: locationLatLng,
    });
    setSearchCity(_get(details, 'formatted_address'));
  };

  const resetSearch = async () => {
    if (searchCity !== _get(user, 'location.city')) {
      searchCityRef?.current?.setAddressText('');
      await clearSpacesData();
      setCurrentPage(0);
      setUserCityDetails();
    }
  };

  const onEndReached = () => {
    setCurrentPage(currentPage + 1);
  };

  const onSelectSort = item => {
    setSelectedSort(_get(item, 'value'));
  };

  const { filteredSpaces, count } = useMemo(() => {
    const filteredResults = getFilteredSpaces(searchResults, selectedFilter);
    const sortedSpace = getSpacesWithAppliedSort(filteredResults, selectedSort, spaceSubType);
    const paginatedSpaces = _take(sortedSpace, currentPage * SPACES_PER_PAGE);
    return { filteredSpaces: paginatedSpaces, count: _size(sortedSpace) };
  }, [currentPage, searchResults, selectedFilter, selectedSort, spaceSubType]);

  const data = useMemo(() => {
    if (loadingSpaces) return ['LOADING'];
    if (_size(filteredSpaces) > 0) return filteredSpaces;
    return ['EMPTY'];
  }, [filteredSpaces, loadingSpaces]);

  const renderRightButton = () => {
    if (searchCity !== _get(user, 'location.city')) {
      return <AntDesign name="closecircle" size={16} color={COLORS.TEXT_COLOR_SECONDARY} onPress={resetSearch} />;
    }
    return null;
  };

  const renderStickyView = () => (
    <View style={styles.stickyViewStyle}>
      <View style={styles.spaceSubTypeStyle}>
        {_map(SPACE_TYPES, ({ label, value }) => (
          <Button
            variant="small"
            key={value}
            style={[
              styles.spaceTypeSelectionButton,
              { backgroundColor: value === spaceSubType ? COLORS.PRIMARY_COLOR : COLORS.HALF_WHITE },
            ]}
            textProps={{ color: value === spaceSubType ? COLORS.WHITE : COLORS.BLACK, style: { letterSpacing: 0 } }}
            onPress={() => {
              setSpaceSubType(value);
              setSelectedFilter({});
            }}>
            {label}
          </Button>
        ))}
      </View>
      <View style={styles.searchAndSortContainer}>
        <View style={{ flex: 1 }}>
          <SearchBarWithAutocomplete
            renderLeftButton={() => <Feather name="search" size={20} color={COLORS.TEXT_COLOR_SECONDARY} />}
            renderRightButton={renderRightButton}
            searchCity={searchCity}
            onLocationSelect={onLocationSelect}
            searchResults={searchResults}
            metadata={metadata}
          />
        </View>
        <SortSpaces selectedSort={selectedSort} onSelectSort={onSelectSort} organization={organization} />
      </View>
      <QuickFilters
        metadata={metadata}
        spaceType={spaceSubType}
        selectedFilter={selectedFilter}
        onSelectFilter={setSelectedFilter}
        organization={organization}
        seatSelectionPosition={seatSelectionPosition}
        setShowSeatSelectionPosition={setShowSeatSelectionPosition}
      />
    </View>
  );

  const renderSpaceCountView = () => {
    if (!loadingSpaces && count > 0) {
      return (
        <View style={{ marginBottom: 10, marginHorizontal: 16 }}>
          <Text variant="bold" size={4} style={{ alignSelf: 'flex-start' }}>
            {count} space(s) available
          </Text>
        </View>
      );
    }
    return null;
  };

  const renderItem = ({ item }) => {
    if (item === 'STICKY_HEADER') return renderStickyView();
    if (item === 'LOADING')
      return (
        <View style={{ marginTop: 16, marginHorizontal: 16 }}>
          <Skeleton />
          <Separator color="transparent" />
          <Skeleton />
        </View>
      );
    if (item === 'EMPTY')
      return (
        <Text variant="bold" size={4} style={{ alignSelf: 'center' }}>
          No Spaces
        </Text>
      );

    return (
      <View style={{ marginHorizontal: 16 }}>
        <SpaceCard space={item} showRates={showRates} />
      </View>
    );
  };

  return (
    // <SafeAreaView style={styles.container} edges={['right', 'top', 'left']}>
    <View style={[styles.container, styles.containerPadding]}>
      {renderStickyView()}
      {renderSpaceCountView()}
      <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={(item, index) => (_get(item, 'spaceId') || item) + index}
        contentContainerStyle={styles.flatListContainerStyle}
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        onEndReached={onEndReached}
        onEndReachedThreshold={0.5}
      />
    </View>
    // </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
  },
  containerPadding: {
    // paddingHorizontal: 16,
  },
  flatListContainerStyle: {
    paddingBottom: 20,
  },
  baseMarginTop: {
    marginTop: 20,
  },
  subMarginTop: {
    marginTop: 10,
  },
  paddingVertical: {
    paddingVertical: 16,
  },
  spaceTypeSelectionButton: {
    flex: 1,
  },
  stickyViewStyle: {
    backgroundColor: COLORS.WHITE,
    paddingTop: Platform.OS === 'ios' ? 55 : 16,
    paddingHorizontal: 16,
    zIndex: 100000,
  },
  spaceSubTypeStyle: { flexDirection: 'row', columnGap: 8, paddingBottom: 16 },
  textInputContainer: {
    borderWidth: 1,
    borderColor: COLORS.PRIMARY_BORDER_COLOR,
    borderRadius: 8,
    alignItems: 'center',
    paddingHorizontal: 12,
  },
  textInput: { marginBottom: 0 },
  searchAndSortContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
  metadata: state.metadata.data,
  searchResults: state.spaces.spaces,
  loadingSpaces: state.spaces.loadingSpaces,
  organization: state.user.organization,
  showRates: getShowRates(state),
});

export default connect(mapStateToProps, {
  setSpaces: setSpacesAction,
  setLoadingSpaces: setSpacesLoading,
  clearSpacesData: clearSpaces,
})(Search);
