import { View, ActivityIndicator, StyleSheet } from 'react-native';
import { SPACING } from '../constants/common';

const Loader = ({ style }) => {
  const loaderStyle = [styles.containerPadding, styles.centerView, style];
  return (
    <View style={loaderStyle}>
      <ActivityIndicator />
    </View>
  );
};

const styles = StyleSheet.create({
  containerPadding: {
    paddingHorizontal: SPACING,
    paddingTop: SPACING,
  },
  centerView: {
    flex: 1,
    alignItems: 'center',
  },
});

export default Loader;
