import React, { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { ActivityIndicator, Dimensions, ImageBackground, ScrollView, StyleSheet, View } from 'react-native';
import _get from 'lodash/get';
import _size from 'lodash/size';

import { useToast } from 'react-native-toast-notifications';
import Text from '../../components/Text';
import { COLORS } from '../../constants/colors';
import { SPACING,MAXDEVICEWIDTH } from '../../constants/common';
import {
  addEventDetailsToThePayload,
  getMinSeats,
  getSpaceAvailabilityPayload,
  getSpaceAvailabilityPayloadForMeetingSpaces,
  isInstantlyBookableAwfis,
  isInstantlyBookableWeWork,
  isInstantlyBookableCowrks,
  isOfficeSpace,
} from '../../utils/common';
import Button from '../../components/Button';
import Header from '../../components/Header';
import { checkBookingAvailability, createBooking, getSpaceDetails, sendUserOtp } from '../../api/common';
import DeskBooking from './components/DeskBooking';
import MeetingRoomBooking from './components/MeetingRoomBooking';
import { ROUTES } from '../../constants/routes';
import Carousel from '../../components/carousel/CarouselFlatlist';
import Loader from '../../components/Loader';
import BookingConfirmation from '../../components/bookingConfirmation/BookingConfirmation';
import { auth } from '../../../firebaseConfig';
import VerifyBookingOTP from '../../components/verifyBookingOTP';

const { width } = Dimensions.get('window');
const IMAGE_WIDTH = (width > MAXDEVICEWIDTH ? MAXDEVICEWIDTH:width) - 2 * SPACING;

const SpaceBooking = ({ route, user, navigation, organization }) => {
  const { spaceId = '-N-CGjeH1xuqomuDPtPV', timeStamp, eventId } = route.params;
  const uid = _get(user, 'uid');
  const [loading, setLoading] = useState(true);
  const [spaceDetails, setSpaceDetails] = useState(null);
  const [booking, setBooking] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [noOfSeats, setNoOfSeats] = useState(getMinSeats(spaceDetails));
  const [showOtpView, setShowOtpView] = useState(false);
  const [otp, setOtp] = useState();
  const toast = useToast();

  const deskBookingRef = useRef(null);
  const meetingRoomBookingRef = useRef(null);

  useEffect(() => {
    async function fetchSpaceDetails() {
      setLoading(true);
      const response = await getSpaceDetails(spaceId, uid);
      setSpaceDetails(response);
      setNoOfSeats(getMinSeats(response));
      setLoading(false);
    }
    if (spaceId && uid && auth?.currentUser?.getIdToken()) fetchSpaceDetails();
  }, [spaceId, uid, auth?.currentUser]);

  const { originalName, photos, priceperday, facilitiesList, address, exclusiveOffer, gofloatersSpaceName } = useMemo(
    () => ({
      originalName: _get(spaceDetails, 'originalName', ''),
      photos: _get(spaceDetails, 'photos', []),
      priceperday: _get(spaceDetails, 'priceperday', ''),
      facilitiesList: _get(spaceDetails, 'facilitiesList', []),
      address: _get(spaceDetails, 'address', {}),
      exclusiveOffer: _get(spaceDetails, 'exclusiveOffer', ''),
      gofloatersSpaceName: _get(spaceDetails, 'gofloatersSpaceName', ''),
    }),
    [spaceDetails]
  );

  const onBookNowPress = async () => {
    if (!_get(spaceDetails, 'isSpaceApprovedForOrg') && !_get(user, 'isOrganizationAdmin')) {
      toast.show("Kindly contact your organization's admin or faclity manager to book this space");
      return;
    }
    if (_get(organization, 'config.validateOTPForBooking')) {
      if (isOfficeSpace(spaceDetails)) {
        const bookingPayload = deskBookingRef?.current.getBookingPayload();
        if (!bookingPayload) return;
      } else {
        const bookingPayload = meetingRoomBookingRef?.current.getBookingPayload();
        if (!bookingPayload) return;
      }
      try {
        setBooking(true);
        const response = await sendUserOtp(uid);
        setShowOtpView(true);
        setOtp(_get(response, 'data.otpHash'));
      } catch (error) {
        toast.show('Error sending otp. Please try again later.');
      } finally {
        setBooking(false);
      }
    } else {
      bookSpace();
    }
  };

  const bookSpace = async () => {
    setBooking(true);
    if (isOfficeSpace(spaceDetails)) {
      const bookingPayload = deskBookingRef?.current.getBookingPayload();
      if (bookingPayload) {
        const spaceAvailabilityPayload = getSpaceAvailabilityPayload(spaceId, bookingPayload);
        try {
          const response = await checkBookingAvailability(spaceAvailabilityPayload);
          if (response) {
            try {
              const bookingResponse = await createBooking(addEventDetailsToThePayload(bookingPayload, eventId));
              setBookingDetails(bookingResponse);
              // navigation.navigate(ROUTES.BOOKINGS);
            } catch (error) {
              toast.show(error?.response?.data?.error?.message ?? 'Error booking space. Please try again.');
            }
          } else {
            toast.show('Space not available for selected date and time');
          }
        } catch {
          toast.show('Space not available for selected date and time');
        } finally {
          setBooking(false);
        }
      }
      setBooking(false);
    } else {
      const bookingPayload = meetingRoomBookingRef?.current.getBookingPayload();
      // setBooking(false);
      if (bookingPayload) {
        try {
          let allowBooking = true;
          if (
            isInstantlyBookableAwfis(spaceDetails) ||
            isInstantlyBookableWeWork(spaceDetails) ||
            isInstantlyBookableCowrks(spaceDetails)
          ) {
            const spaceAvailabilityPayload = getSpaceAvailabilityPayloadForMeetingSpaces(spaceId, bookingPayload);
            allowBooking = await checkBookingAvailability(spaceAvailabilityPayload);
          }
          if (allowBooking) {
            try {
              const bookingResponse = await createBooking(addEventDetailsToThePayload(bookingPayload, eventId));
              // console.log({ bookingResponse });
              setBookingDetails(bookingResponse);
              // navigation.navigate(ROUTES.BOOKINGS);
            } catch (error) {
              toast.show(error?.response?.data?.error?.message ?? 'Error booking space. Please try again.');
            }
          } else {
            toast.show('Space not available for selected date and time');
          }
        } catch {
          toast.show('Space not available for selected date and time');
        } finally {
          setBooking(false);
        }
      }
      setBooking(false);
    }
  };

  if (loading)
    return (
      <View style={[styles.containerPadding, styles.centerView]}>
        <ActivityIndicator />
      </View>
    );

  const renderBookingScreen = () => (
    <View style={styles.container}>
      <Header title={[gofloatersSpaceName, ' - ', originalName]} />
      <ScrollView style={styles.containerPadding} contentContainerStyle={{ paddingBottom: 100 }}>
        <View style={styles.image}>
          <Carousel
            data={photos}
            paginationWidth={IMAGE_WIDTH}
            renderItem={({ item }) => (
              <ImageBackground style={styles.image} source={{ uri: item }} resizeMode="cover" />
            )}
            keyExtractor={item => item}
          />
        </View>

        {isOfficeSpace(spaceDetails) ? (
          <DeskBooking
            timeStamp={timeStamp}
            spaceDetails={spaceDetails}
            ref={deskBookingRef}
            user={user}
            organization={organization}
            disableEdit={!!eventId}
          />
        ) : (
          <MeetingRoomBooking
            timeStamp={timeStamp}
            spaceDetails={spaceDetails}
            ref={meetingRoomBookingRef}
            user={user}
            organization={organization}
          />
        )}
      </ScrollView>

      <View style={styles.buttonStyle}>
        <Button textProps={{ variant: 'bold', size: 3 }} onPress={onBookNowPress} loading={booking}>
          Book now
        </Button>
      </View>
      {showOtpView ? <VerifyBookingOTP onVerify={bookSpace} otp={otp} /> : null}
    </View>
  );

  const renderBookingConfirmationScreen = () => (
    <BookingConfirmation navigation={navigation} bookingDetails={bookingDetails} user={user} />
  );

  // console.log({ bookingDetails });

  return !bookingDetails ? renderBookingScreen() : renderBookingConfirmationScreen();
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
  },
  containerPadding: {
    paddingHorizontal: SPACING,
    paddingTop: SPACING,
  },
  image: {
    width: IMAGE_WIDTH,
    height: (1 / 1.78) * ((width > MAXDEVICEWIDTH? MAXDEVICEWIDTH : width) - SPACING * 3 - 4),
    resizeMode: 'cover',
    borderRadius: 16,
    overflow: 'hidden',
  },
  paginationDotStyle: {
    borderRadius: 8,
    maxWidth: 8,
    maxHeight: 8,
  },
  baseMarginTop: {
    marginTop: 20,
  },
  subMarginTop: {
    marginTop: 10,
  },
  margin30: {
    marginTop: 30,
  },
  dateTimeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  fullFlex: {
    flex: 1,
  },
  buttonStyle: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    padding: 16,
    backgroundColor: COLORS.WHITE,
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
  organization: state.user.organization,
});

export default connect(mapStateToProps)(SpaceBooking);
