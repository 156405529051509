import { View, StyleSheet, Pressable } from 'react-native';
import { Fontisto } from '@expo/vector-icons';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import _noop from 'lodash/noop';
import _map from 'lodash/map';

import { useNavigation } from '@react-navigation/native';
import Text from './Text';
import { COLORS } from '../constants/colors';

const Header = ({ title, onBackPress = _noop, rightBarButtons = [] }) => {
  // const router = useRouter();
  const navigation = useNavigation();

  const insets = useSafeAreaInsets();

  const onHeaderBackPress = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      onBackPress();
    }
  };

  return (
    <View style={[styles.headerContainer, { padding: 20, paddingTop: insets.top + 20 }]}>
      <View style={styles.backAndTitle}>
        <Pressable onPress={onHeaderBackPress}>
          <Fontisto name="angle-left" size={14} color={COLORS.TEXT_COLOR_PRIMARY} />
        </Pressable>
        <Text size={3} variant="bold">
          {title}
        </Text>
      </View>
      <View style={{ height: 24 }}>
        {_map(rightBarButtons, (button, index) => (
          <View key={index}>{button}</View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    padding: 20,
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
    alignItems: 'center',
  },
  backAndTitle: {
    flexDirection: 'row',
    columnGap: 18,
    alignItems: 'center',
    flex: 1,
  },
});

export default Header;
