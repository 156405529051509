import { View, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Text from './Text';
import { COLORS } from '../constants/colors';

const HeaderWithoutBack = ({ title }) => {
  const insets = useSafeAreaInsets();
  return (
    <View style={[styles.headerContainer, { padding: 20, paddingTop: insets.top + 20 }]}>
      <Text size={2} variant="bold">
        {title}
      </Text>
    </View>
  );
};
const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
    columnGap: 18,
    alignItems: 'center',
  },
});

export default HeaderWithoutBack;
