import { useState } from 'react';
import { View, StyleSheet, Image, Dimensions } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { CodeField, Cursor, useBlurOnFulfill, useClearByFocusCell } from 'react-native-confirmation-code-field';
import { IMAGES } from '../../assets/images';
import Text from '../../components/Text';
import { COLORS } from '../../constants/colors';
import Wave from '../../components/Wave';

const CELL_COUNT = 4;
const { width } = Dimensions.get('window');

const Otp = () => {
  const [value, setValue] = useState('');
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });

  return (
    <SafeAreaView style={styles.container}>
      <View style={[styles.container, styles.containerPadding]}>
        <Image source={IMAGES.GOFLOATERS_LOGO} style={styles.logoStyle} />
        <View style={styles.infoContainer}>
          <Text size={2} variant="medium">
            Verification code
          </Text>
          <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY} style={{ marginTop: 8 }}>
            We have sent the code verification to{' '}
          </Text>
          <View style={styles.phoneNumberText}>
            <Text size={3} variant="medium">
              +99******1233.
            </Text>
            <Text size={3} color={COLORS.BUTTON_COLORS.SECONDARY_COLOR}>
              {' '}
              Change phone number?
            </Text>
          </View>
          <CodeField
            ref={ref}
            {...props}
            // Use `caretHidden={false}` when users can't paste a text value, because context menu doesn't appear
            value={value}
            onChangeText={setValue}
            cellCount={CELL_COUNT}
            rootStyle={styles.codeFieldRoot}
            keyboardType="number-pad"
            textContentType="oneTimeCode"
            renderCell={({ index, symbol, isFocused }) => (
              <Text
                key={index}
                style={[styles.cell, isFocused && styles.focusCell]}
                onLayout={getCellOnLayoutHandler(index)}>
                {symbol || (isFocused ? <Cursor /> : null)}
              </Text>
            )}
          />
        </View>
      </View>
      <Wave />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
  },
  containerPadding: {
    paddingHorizontal: 48,
    paddingTop: 16,
  },
  logoStyle: {
    width: '100%',
    height: 80,
    marginTop: 64,
    marginBottom: 120,
  },
  phoneNumberText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  codeFieldRoot: { marginTop: 32 },
  cell: {
    width: 68,
    height: 56,
    lineHeight: 52,
    fontSize: 14,
    borderRadius: 12,
    backgroundColor: COLORS.HALF_WHITE,
    overflow: 'hidden',
    textAlign: 'center',
  },
  focusCell: {
    borderColor: '#000',
  },
});

export default Otp;
