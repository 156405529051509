import { useMemo, useState } from 'react';
import { ScrollView, StyleSheet, View, Pressable } from 'react-native';
import { connect, useDispatch } from 'react-redux';
import RNPickerSelect from 'react-native-picker-select';
import { useToast } from 'react-native-toast-notifications';
import { Feather } from '@expo/vector-icons';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';

import IconLabelCard from '../../components/IconLabelCard';
import Button from '../../components/Button';
import Header from '../../components/Header';
import TextInput from '../../components/TextInput';
import { COLORS } from '../../constants/colors';
import { PICKER_INPUT_STYLES, STATUS, USER_PROFILE_OPTIONS } from '../../constants/common';
import { checkReferralCodeValidity, getUserProfileDetails, validateUpdateProfileDetails } from '../../utils/common';
import { requestAccountDeletion, updateUserDetails } from '../../api/common';
import Text from '../../components/Text';
import DeletionConfirmation from '../../components/DeletionConfirmation';
import { logoutUserAction } from '../../commonActions';
import { signUserOut } from '../../utils/firebaseUtils';
import { ROUTES } from '../../constants/routes';

const DEFAULT_PROFESSION = { label: 'I’m a...', value: '' };

function EditProfile({ user, navigation }) {
  const dispatch = useDispatch();
  const toast = useToast();
  const userDetails = getUserProfileDetails(user);
  const [displayName, setDisplayName] = useState(_get(userDetails, 'displayName'));
  const [phoneNumber, setPhoneNumber] = useState(_get(userDetails, 'phoneNumber'));
  const [email, setEmail] = useState(_get(userDetails, 'email'));
  const [companyName, setCompanyName] = useState(_get(userDetails, 'companyName'));
  const [profession, setProfession] = useState(_get(userDetails, 'profession'));
  const [hideMyPresence, setHideMyPresence] = useState(_get(userDetails, 'hideMyPresence'));
  const [referral, setReferral] = useState('');
  const [showReferral, setShowReferral] = useState(false);
  const [savingProfile, setSavingProfile] = useState(false);
  const [requestAccountDeletionInProgress, setRequestAccountDeletionInProgress] = useState(false);

  const onRequestAccountDeletion = async () => {
    setRequestAccountDeletionInProgress(true);
    const response = 'sdafs';
    // const response = await requestAccountDeletion(user);
    // if (_isEmpty(response)) {
    //   toast.show('Error deleting account. Please try again.');
    // } else {
    //   toast.show('Your account deletion request has been submitted.');
    // }
    // setRequestAccountDeletionInProgress(false);
  };

  const onConfirmAccountDeletion = async () => {
    // setRequestAccountDeletionInProgress(true);
    // const response ="sdafs";
    const response = await requestAccountDeletion(user);
    if (_isEmpty(response)) {
      toast.show('Error deleting account. Please try again.');
    } else {
      setRequestAccountDeletionInProgress(false);
      await signUserOut();
      dispatch(logoutUserAction());
      toast.show('Your account has been deleted.');
    }
  };

  const onCancelAccountDeletion = async () => {
    setRequestAccountDeletionInProgress(false);
  };

  const onSavePress = async () => {
    setSavingProfile(true);
    const validationDetails = validateUpdateProfileDetails({
      displayName,
      phoneNumber,
      email,
      companyName,
      profession,
    });
    if (validationDetails.status === STATUS.FAILED) {
      toast.show(_get(validationDetails, 'msg'));
      setSavingProfile(false);
      return;
    }

    const payload = { displayName, phoneNumber, email, companyName, profession, hideMyPresence };
    if (_size(referral) > 0) {
      const { status, organizationId, msg } = await checkReferralCodeValidity(referral, email);
      if (status === STATUS.SUCCESS) {
        payload.signupReferralCode = referral;
        payload.organizationId = organizationId;
      } else if (status === STATUS.FAILED) {
        toast.show(msg);
        setSavingProfile(false);
        return;
      }
    }
    // console.log({ payload });
    const response = await updateUserDetails(payload, user);
    if (!_isEmpty(response)) {
      toast.show('Details updated successfully.');
      // take user back maybe?
    } else {
      toast.show('Error updating details. Please check the edits and try again.');
    }
    setSavingProfile(false);
  };
  const allowReferralInput = useMemo(() => !_get(user, 'organizationId'), [user]);
  // const allowReferralInput = true;
  const showReferralInput = () => setShowReferral(!showReferral);

  const onBackPress = () => {
    navigation.navigate(ROUTES.PROFILE.name);
  };

  return (
    <View style={[styles.container]}>
      <Header title="Edit Profile" onBackPress={onBackPress} />
      {/* <View>
        <Text numberOfLines={0}>{JSON.stringify(user, null, 4)}</Text>
      </View> */}
      <View style={[styles.container, styles.containerPadding]}>
        <ScrollView>
          <TextInput
            label=" Profile Name"
            value={displayName}
            onChangeText={setDisplayName}
            placeholder="Full name"
            containerStyle={styles.marginBottom}
          />
          <TextInput value={phoneNumber} onChangeText={setPhoneNumber} containerStyle={styles.marginBottom} />
          <TextInput
            label="Email"
            value={email}
            onChangeText={setEmail}
            placeholder="Email ID"
            containerStyle={[styles.marginBottom, { backgroundColor: COLORS.PRIMARY_BORDER_COLOR }]}
            disabled
          />

          <TextInput
            label="Company"
            value={companyName}
            onChangeText={setCompanyName}
            placeholder="Company"
            containerStyle={styles.marginBottom}
          />
          <View>
            <Text size={3} variant="semiBold" color={COLORS.TEXT_COLOR_SECONDARY} style={{ marginBottom: 4,textAlign: 'left' }}>
              Describe yourself
            </Text>
            <RNPickerSelect
              placeholder={DEFAULT_PROFESSION}
              style={PICKER_INPUT_STYLES}
              onValueChange={setProfession}
              value={profession}
              // Icon={() => <Entypo name="chevron-down" size={16} color={COLORS.TEXT_COLOR_SECONDARY} />}
              items={USER_PROFILE_OPTIONS}
            />
          </View>
          <View style={styles.marginBottom}>
            <Text size={3} variant="semiBold" color={COLORS.TEXT_COLOR_SECONDARY} style={{ marginBottom: 4,textAlign: 'left' }}>
              Hide my presence
            </Text>
            <Pressable onPress={() => setHideMyPresence(!hideMyPresence)}>
              <IconLabelCard
                iconFamily={Feather}
                iconProps={{
                  name: 'check',
                  size: 16,
                  color: hideMyPresence ? COLORS.WHITE : COLORS.TEXT_COLOR_SECONDARY,
                }}
                containerStyle={styles.subMarginTop}
                labelProps={{ size: 0 }}
                {...{ colors: hideMyPresence ? [COLORS.PRIMARY_COLOR, COLORS.PRIMARY_COLOR] : null }}
              />
            </Pressable>
          </View>
          {allowReferralInput && !showReferral ? (
            <Text
              color={COLORS.PRIMARY_COLOR}
              style={{ textDecorationLine: 'underline' }}
              variant="semiBold"
              size={3}
              onPress={showReferralInput}>
              I have a referral code
            </Text>
          ) : null}

          {allowReferralInput && showReferral ? (
            <TextInput
              label="Referral code"
              value={referral}
              onChangeText={setReferral}
              placeholder="Check with your admin if you dont have it."
              containerStyle={styles.marginBottom}
            />
          ) : null}
        </ScrollView>
        <Button loading={savingProfile} textProps={{ variant: 'bold', size: 3 }} onPress={onSavePress}>
          Save
        </Button>
        <Button
          loading={false}
          style={styles.accountDeletionButton}
          textProps={{ variant: 'bold', size: 3, color: COLORS.RED, style: styles.accountDeletionText }}
          onPress={onRequestAccountDeletion}>
          Delete Account
        </Button>
        <DeletionConfirmation
          onConfirmAccountDeletion={onConfirmAccountDeletion}
          onCancelAccountDeletion={onCancelAccountDeletion}
          requestAccountDeletionInProgress={requestAccountDeletionInProgress}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
  },
  containerPadding: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  baseMarginBottom: {
    marginBottom: 16,
  },
  marginBottom: {
    marginBottom: 20,
  },
  accountDeletionButton: {
    backgroundColor: COLORS.WHITE,
  },
  accountDeletionText: {
    color: COLORS.RED,
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(EditProfile);
