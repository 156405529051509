import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { MaterialIcons } from '@expo/vector-icons';
import { connect } from 'react-redux';
import { useToast } from 'react-native-toast-notifications';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import { COLORS } from '../../constants/colors';
import { STATUS, TOP_CITIES } from '../../constants/common';
import Button from '../../components/Button';
import Header from '../../components/Header';
import { AutocompleteDropdown } from '../../components/searchDropDown';
import { getOperationalCities, getUserCity } from '../../selectors/common';
import { getSearchableDropDownOptions, getUserLocation } from '../../utils/common';
import { updateUserCity } from '../../utils/firebaseUtils';
import { updateUserDetails } from '../../api/common';
import { ROUTES } from '../../constants/routes';

const EditCity = ({ cities, user, metadata, organization, navigation }) => {
  const [userCity, setUserCity] = useState({ id: getUserCity(user), title: getUserCity(user) });
  const [savingCity, setSavingCity] = useState(false);
  const citiesOptions = useMemo(() => getSearchableDropDownOptions(organization), [organization]);
  const toast = useToast();
  const searchRef = useRef(null);
  // useEffect(() => {
  //   setUserCity({ id: getUserCity(user), title: getUserCity(user) });
  // }, [user]);

  const onSaveCityPres = useCallback(async () => {
    setSavingCity(true);
    const response = await updateUserDetails({ location: getUserLocation(_get(userCity, 'id'), metadata) }, user);
    // show toast
    if (!_isEmpty(response)) {
      toast.show('City has been updated successfully.');
      // take user back maybe?
    } else {
      toast.show('Error updating city. Please try again.');
    }
    setSavingCity(false);
  }, [user, userCity]);

  const onCitySelect = selectedCity => {
    // console.log({ selectedCity });
    setUserCity(selectedCity);
  };

  const onBackPress = () => {
    navigation.navigate(ROUTES.PROFILE.name);
  };

  return (
    <View style={[styles.container]}>
      <Header title="Edit City" onBackPress={onBackPress} />
      <View style={[styles.container, styles.containerPadding]}>
        <View style={[styles.container]}>
          <View style={{ zIndex: 10 }}>
            <AutocompleteDropdown
              clearOnFocus={false}
              closeOnBlur
              closeOnSubmit={false}
              initialValue={userCity} // or just '2'
              onSelectItem={onCitySelect}
              dataSet={citiesOptions}
              controller={controller => {
                searchRef.current = controller;
              }}
            />
          </View>

          <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY} style={styles.currentCityStyle}>
            Current City: {_get(userCity, 'title')}
          </Text>
          <View style={styles.topCitiesHeading}>
            <Text color={COLORS.TEXT_COLOR_PRIMARY} variant="bold">
              Top Cities
            </Text>
            <MaterialIcons name="insert-chart-outlined" size={20} color={COLORS.TEXT_COLOR_PRIMARY} />
          </View>
          <View style={styles.topCitiesContainer}>
            {_map(TOP_CITIES, city => {
              const style = [styles.cityStyle];
              if (city === _get(userCity, 'id')) style.push({ textDecorationLine: 'underline' });
              const onClick = () => {
                setUserCity({ title: city, id: city });
                searchRef?.current?.setItem({ title: city, id: city });
              };
              return (
                <Text size={3} key={city} color={COLORS.TEXT_COLOR_SECONDARY} style={style} onPress={onClick}>
                  {city}
                </Text>
              );
            })}
          </View>
        </View>
        <Button
          textProps={{ variant: 'bold', size: 3 }}
          style={{ marginBottom: 16 }}
          onPress={onSaveCityPres}
          loading={savingCity}>
          Save
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
  },
  containerPadding: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  currentCityStyle: {
    marginVertical: 20,
    textAlign:'left'
  },
  topCitiesHeading: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 10,
    marginBottom: 12,
  },
  topCitiesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  cityStyle: {
    marginRight: 10,
    marginBottom: 10,
  },
});

const mapStateToProps = state => ({
  cities: getOperationalCities(state.metadata.data),
  user: state.user.user,
  metadata: state.metadata.data,
  organization: state.user.organization,
});

export default connect(mapStateToProps)(EditCity);
