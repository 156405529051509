import LottieView from 'lottie-react';
import { LOTTIE_FILES } from '../../assets/lottie';

const LottieAnimation = ({ style }) => (
  <LottieView
    autoPlay
    style={{
      width: '100%',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
    }}
    // Find more Lottie files at https://lottiefiles.com/featured
    animationData={LOTTIE_FILES.CONFETTI}
  />
);

export default LottieAnimation;
