import { View, Image, StyleSheet, Dimensions, Platform, Pressable } from 'react-native';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _head from 'lodash/head';
import _size from 'lodash/size';
import _round from 'lodash/round';
import { useLinkProps } from '@react-navigation/native';

import React, { useMemo } from 'react';
import { IMAGES } from '../../assets/images';
import { COLORS } from '../../constants/colors';
import { SPACING,MAXDEVICEWIDTH } from '../../constants/common';
import Text from '../Text';
import { getSpaceAvgRating, getSpaceDetails, getSpacePrice } from '../../utils/common';
import { ROUTES } from '../../constants/routes';

const { width } = Dimensions.get('window');

function SpaceCard({ space = {}, showRates = false }) {
  const { originalName, address, photos, distance, spaceId, spaceRating, gofloatersSpaceName, spaceDisplayName } =
    space;
  const { onPress, ...props } = useLinkProps({ to: { screen: ROUTES.SPACE_DETAILS.name, params: { spaceId } } });
  const Container = Platform.OS === 'web' ? View : Pressable;
  const onClick = Platform.OS === 'web' ? { onClick: onPress } : { onPress };
  const spacePricing = useMemo(() => getSpacePrice(space), []);
  return (
    <Container style={styles.container} {...props} {...onClick}>
      <Image style={styles.spaceImageStyle} source={{ uri: _head(photos) }} resizeMode="cover" />
      <View style={styles.spaceInfoContainer}>
        <Text variant="bold" size={1} style={{ alignSelf: 'flex-start',textAlign: 'left' }}>
          {gofloatersSpaceName} - {originalName}
        </Text>
        <Text variant="medium" size={4} color={COLORS.PRIMARY_COLOR} style={{ alignSelf: 'flex-start',textAlign: 'left' }} >
          {spaceDisplayName}
        </Text>
        <View style={styles.horizontalSpaceBetween}>
          <Text variant="medium" size={4} color={COLORS.TEXT_COLOR_SECONDARY}>
            {getSpaceDetails(address)}
          </Text>
          <Text size={4} color={COLORS.TEXT_COLOR_SECONDARY}>
            In {_round(distance, 1)} Kms
          </Text>
        </View>
        <View style={styles.horizontalSpaceBetween}>
          {/* <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
            <Text variant="bold" size={3}>
              ₹ {_get(space, 'priceperday')}
            </Text>
            <Text size={4} variant="medium">
              /day
            </Text>
          </View> */}
          <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
            {showRates
              ? _map(spacePricing, (pricing, index) => (
                  <React.Fragment key={index}>
                    <Text variant="bold" size={3}>
                      {pricing[0]}
                    </Text>
                    <Text size={4} variant="medium">
                      {pricing[1]}
                    </Text>

                    {index !== _size(spacePricing) - 1 ? (
                      <Text size={4} variant="medium">
                        &nbsp; or &nbsp;
                      </Text>
                    ) : null}
                  </React.Fragment>
                ))
              : null}
          </View>

          <Text variant="bold" size={4}>
            {`Rated ${getSpaceAvgRating(_get(space, 'feedback'))} /5`}
          </Text>
        </View>
      </View>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
    elevation: 8,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 4,
    shadowOpacity: 0.2,
    borderRadius: 12,
    marginHorizontal: 4,
    marginTop: 20,
    borderWidth: 1,
    borderColor: COLORS.HALF_WHITE,
  },
  spaceImageStyle: {
    width: '100%',
    height: (1 / 1.78) * ((width > MAXDEVICEWIDTH? MAXDEVICEWIDTH : width) - SPACING * 3 - 4),
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  spaceInfoContainer: {
    padding: 12,
    rowGap: 4,
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
  },
  actionClickstyle: {
    marginRight: 8,
    textDecorationLine: 'underline',
  },
  horizontalSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default SpaceCard;
