import React from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import _map from 'lodash/map';
import _size from 'lodash/size';
import { EvilIcons, FontAwesome, Octicons } from '@expo/vector-icons';

import { BOOKING_STAGE_STATUS } from '../constants/common';
import Text from './Text';
import { COLORS } from '../constants/colors';
import Separator from './Separator';
import { getBookingStages, getSeparatorColor } from '../utils/common';

const getFontIcon = (status, styles) => {
  if (status === BOOKING_STAGE_STATUS.COMPLETED)
    return (
      <View
        style={[{ width: 20, height: 20 }, styles.iconContainer, { backgroundColor: COLORS.STATUS_COLORS.SUCCESS }]}>
        <FontAwesome name="check" size={8} color={COLORS.WHITE} />
      </View>
    );

  if (status === BOOKING_STAGE_STATUS.IN_PROGRESS) {
    return (
      <View
        style={[{ width: 26, height: 26 }, styles.iconContainer, { backgroundColor: COLORS.STATUS_COLORS.SUCCESS }]}>
        <ActivityIndicator size={12} color={COLORS.WHITE} />
      </View>
    );
  }
  return (
    <View style={[{ width: 16, height: 16 }, styles.iconContainer, { backgroundColor: COLORS.PRIMARY_BORDER_COLOR }]}>
      <Octicons name="dot-fill" size={12} color={COLORS.WHITE} />
    </View>
  );
};

function BookingTimeline({ status }) {
  const stages = getBookingStages(status);
  return (
    <View>
      <View style={styles.titleContainer}>
        {_map(stages, (stage, index) => (
          <Text
            key={index}
            size={4}
            color={
              stage.status === BOOKING_STAGE_STATUS.IN_PROGRESS
                ? COLORS.TEXT_COLOR_PRIMARY
                : COLORS.TEXT_COLOR_SECONDARY
            }>
            {stage.title}
          </Text>
        ))}
      </View>
      <View style={styles.stageContainer}>
        {_map(stages, (stage, index) => {
          const separatorColor = getSeparatorColor(stage.status, index);
          return (
            <React.Fragment key={index}>
              {getFontIcon(stage.status, styles)}
              {index === _size(stages) - 1 ? null : (
                <Separator style={{ flex: 1 }} thickness={2} color={separatorColor} />
              )}
            </React.Fragment>
          );
        })}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  stageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    marginTop: 16,
  },

  iconContainer: {
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default BookingTimeline;
