import React, { useEffect, useState } from 'react';
import messaging from '@react-native-firebase/messaging';
import { Alert, Platform } from 'react-native';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { onAuthStateChanged } from 'firebase/auth';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _capitalize from 'lodash/capitalize';

import { child, get, ref } from 'firebase/database';
import SplashScreen from '../screens/splashScreen';
import { ROUTES } from '../constants/routes';
import Login from '../screens/login';
import NewLogin from '../screens/login/NewLogin';
import SignupDetails from '../screens/signupDetails';
import Otp from '../screens/otp';
import { setUser as setUserAction, setUserSetInfo as setUserSetInfoAction } from '../reducers/user';
import { setMetadata as setMetaDataAction } from '../reducers/metadata';
import Home from '../screens/home/Home';
import MainTabNavigator from './MainTabNavigator';
import SpaceDetails from '../screens/spaceDetails';
import SpaceBooking from '../screens/spaceBooking';
import EditCity from '../screens/editCity';
import faqs from '../screens/faq';
import EditProfile from '../screens/editProfile';
import Notifications from '../screens/notifications';
import { getUserInfo } from '../utils/common';
import { auth, database } from '../../firebaseConfig';
import MyOrganization from '../screens/myOrganisation';
import { getMetaData } from '../api/common';
import SignInWithEmail from '../screens/signinWithEmail';
import { getUserSetInfo } from '../utils/firebaseUtils';
import forgotPassword from '../screens/forgotPassword';
import BookingDetails from '../screens/bookingDetails';
import Profile from '../screens/profile';
import ViewImage from '../screens/viewImage';
import ViewVideo from '../screens/viewVideo';
import Announcements from '../screens/announcements';
import SOS from '../screens/sos';
import FavoriteCoworker from '../screens/favoriteCoworker';
import Events from '../screens/events';
import EventDetails from '../screens/eventDetails';
import SpaceCheckin from '../screens/spaceCheckin';
// import { addNotificationListeners, requestUserPermission } from '../utils/notifications';
// import Signup from '../screens/signup';
// import {ROUTES} from '../constants/routes';
// import Registration from '../screens/registration';
// import Otp from '../screens/opt/Otp';
// import SpaceDetails from '../screens/spaceDetails';
// import BookingConfirmation from '../screens/bookingConfirmation';
// import Login from '../screens/login';
// import ForgotPassword from '../screens/forgotPassword';
// import Header from '../components/Header';
// import MainTabNavigator from './MainTabNavigator';
// import SearchFilters from '../screens/searchFilters';
// import {setUser} from '../reducers/user';
// import {getUserInfo} from '../utils/common.utils';

const Stack = createNativeStackNavigator();

const NAVIGATORS = {
  SPLASH: 'SPLASH',
  //   ONBOARDING: 'ONBOARDING',
  AUTH: 'AUTH',
  MAIN_TAB: 'MAIN_TAB',
};

const linking = {
  prefixes: [
    /* your linking prefixes */
  ],
  config: {
    screens: {
      [ROUTES.SPACE_BOOKINGS.name]: ROUTES.SPACE_BOOKINGS.name,
      [ROUTES.SPACE_DETAILS.name]: ROUTES.SPACE_DETAILS.name,
      [ROUTES.SPACE_CHECKIN.name]: ROUTES.SPACE_CHECKIN.name,
      [ROUTES.OTP.name]: ROUTES.OTP.name,
      [ROUTES.MY_ORGANISATION.name]: ROUTES.MY_ORGANISATION.name,
      [ROUTES.BOOKING_DETAILS.name]: ROUTES.BOOKING_DETAILS.name,
      [ROUTES.EDIT_CITY.name]: ROUTES.EDIT_CITY.name,
      [ROUTES.FAQ.name]: ROUTES.FAQ.name,
      [ROUTES.PROFILE.name]: ROUTES.PROFILE.name,
      [ROUTES.EDIT_PROFILE.name]: ROUTES.EDIT_PROFILE.name,
      [ROUTES.NOTIFICATION.name]: ROUTES.NOTIFICATION.name,
      [ROUTES.ANNOUNCEMENTS.name]: ROUTES.ANNOUNCEMENTS.name,
      [ROUTES.MY_ORGANISATION.name]: ROUTES.MY_ORGANISATION.name,
      [ROUTES.FAVORITE_COWORKERS.name]: ROUTES.FAVORITE_COWORKERS.name,
      [ROUTES.EVENTS.name]: ROUTES.EVENTS.name,
      [ROUTES.EVENT_DETAILS.name]: ROUTES.EVENT_DETAILS.name,
      [ROUTES.TAB_NAVIGATOR.name]: {
        screens: {
          [ROUTES.HOME.name]: ROUTES.HOME.name,
          [ROUTES.TEAM_VIEW.name]: ROUTES.TEAM_VIEW.name,
          [ROUTES.SEARCH.name]: ROUTES.SEARCH.name,
          [ROUTES.BOOKINGS.name]: ROUTES.BOOKINGS.name,
          [ROUTES.ENGAGE.name]: ROUTES.ENGAGE.name,
        },
      },
    },
    /* configuration for matching screens with paths */
  },
};

const Navigator = props => {
  const { setUser, user, metadata, setMetadata } = props;
  const [initialScreen, setInitialScreen] = useState(NAVIGATORS.SPLASH);

  useEffect(() => {
    async function fetchMetadata() {
      const metadataResponse = await getMetaData();
      setMetadata(metadataResponse);
    }
    fetchMetadata();
  }, []);

  useEffect(() => {
    if (metadata) {
      if (!isEmpty(user) && _size(_get(user, 'displayName')) > 0) {
        setInitialScreen(NAVIGATORS.MAIN_TAB);
      } else {
        setInitialScreen(NAVIGATORS.AUTH);
      }
    }
  }, [user, metadata]);

  useEffect(() => {});

  function onAuthStateChangedListener(userDetails) {
    // empty object when signed out.
    const uid = _get(userDetails, 'uid');
    if (uid) {
      const usersRef = ref(database, `users/${uid}`);
      get(usersRef)
        .then(snapshot => {
          if (snapshot.exists()) {
            setUser(snapshot.val());
          } else {
            setUser({ uid });
          }
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      setUser({});
    }

    // setUser(user ? { uid: _get(userDetails, 'uid') } : {});
  }

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, onAuthStateChangedListener);
    return subscriber; // unsubscribe on unmount
  }, []);

  // console.log({ initialScreen });

  if (initialScreen === NAVIGATORS.SPLASH) {
    return <SplashScreen />;
  }

  return (
    <NavigationContainer
      linking={linking}
      documentTitle={{
        formatter: (options, route) => {
          // console.log({ options, route });
          return `WorqFlexi - ${_capitalize(route?.name)}`;
        },
      }}>
      <Stack.Navigator>
        {initialScreen === NAVIGATORS.AUTH ? (
          <>
            {/* <Stack.Screen options={{ headerShown: false }} name={ROUTES.LOGIN.name} component={Login} /> */}
            <Stack.Screen options={{ headerShown: false }} name={ROUTES.LOGIN.name} component={NewLogin} />
            <Stack.Screen options={{ headerShown: false }} name={ROUTES.SIGNUP.name} component={SignupDetails} />
            <Stack.Screen options={{ headerShown: false }} name={ROUTES.OTP.name} component={Otp} />
            <Stack.Screen
              options={{ headerShown: false }}
              name={ROUTES.FORGOT_PASSWORD.name}
              component={forgotPassword}
            />
            <Stack.Screen
              options={{ headerShown: false }}
              name={ROUTES.SIGNIN_WITH_EMAIL.name}
              component={SignInWithEmail}
            />
          </>
        ) : (
          <>
            <Stack.Screen
              options={{ headerShown: false }}
              name={ROUTES.TAB_NAVIGATOR.name}
              component={MainTabNavigator}
            />
            <Stack.Screen options={{ headerShown: false }} name={ROUTES.SPACE_DETAILS.name} component={SpaceDetails} />
            <Stack.Screen options={{ headerShown: false }} name={ROUTES.SPACE_BOOKINGS.name} component={SpaceBooking} />
            <Stack.Screen
              options={{ headerShown: false }}
              name={ROUTES.BOOKING_DETAILS.name}
              component={BookingDetails}
            />
            <Stack.Screen options={{ headerShown: false }} name={ROUTES.EDIT_CITY.name} component={EditCity} />
            <Stack.Screen options={{ headerShown: false }} name={ROUTES.FAQ.name} component={faqs} />
            <Stack.Screen
              options={{ headerShown: false, presentation: 'containedModal' }}
              name={ROUTES.VIEW_IMAGE.name}
              component={ViewImage}
            />
            <Stack.Screen
              options={{ headerShown: false, presentation: 'containedModal' }}
              name={ROUTES.VIEW_VIDEO.name}
              component={ViewVideo}
            />

            <Stack.Screen options={{ headerShown: false }} name={ROUTES.PROFILE.name} component={Profile} />
            <Stack.Screen options={{ headerShown: false }} name={ROUTES.EDIT_PROFILE.name} component={EditProfile} />
            <Stack.Screen options={{ headerShown: false }} name={ROUTES.NOTIFICATION.name} component={Notifications} />
            <Stack.Screen options={{ headerShown: false }} name={ROUTES.ANNOUNCEMENTS.name} component={Announcements} />
            <Stack.Screen options={{ headerShown: false }} name={ROUTES.SOS.name} component={SOS} />
            <Stack.Screen
              options={{ headerShown: false }}
              name={ROUTES.FAVORITE_COWORKERS.name}
              component={FavoriteCoworker}
            />
            <Stack.Screen options={{ headerShown: false }} name={ROUTES.EVENTS.name} component={Events} />
            <Stack.Screen options={{ headerShown: false }} name={ROUTES.EVENT_DETAILS.name} component={EventDetails} />
            <Stack.Screen options={{ headerShown: false }} name={ROUTES.SPACE_CHECKIN.name} component={SpaceCheckin} />
            <Stack.Screen
              options={{ headerShown: false }}
              name={ROUTES.MY_ORGANISATION.name}
              component={MyOrganization}
            />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

const mapStateToProps = state => ({
  firstOpen: state.user.firstOpen,
  user: state.user.user,
  metadata: state.metadata.data,
});
export const AppNavigator = connect(mapStateToProps, {
  setUser: setUserAction,
  setMetadata: setMetaDataAction,
  setuserSetInfo: setUserSetInfoAction,
})(Navigator);
