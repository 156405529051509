import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { updatePushNotificationToken } from '../firebaseUtils';
import { app } from '../../../firebaseConfig';

export const getNotifications = async (uid, navigateUser) => {
  if ('Notification' in window) {
    const permission = await Notification.requestPermission();
    const messaging = getMessaging(app);
    if (permission === 'granted') {
      try {
        const token = await getToken(messaging, {
          vapidKey: 'BOpiDrLTpasEqPxlGJ-oPbQrSRamrjoAN6rIS-cKWzXqya7YfWl1FIXpTeUV3Fi4ezX7Itqj5OqsmddCYZQsq5c',
        });
        // console.log({ tokenWeb: token });
        const { status, msg } = await updatePushNotificationToken(token, { uid });
        // console.log({ status, msg });

        onMessage(messaging, payload => {
          // console.log({ payload });
          // try {
          //   const notificationTitle = payload.notification.title;
          //   const notificationOptions = {
          //     body: payload.notification.body,
          //   };

          //   if (!('Notification' in window)) {
          //     console.log('This browser does not support system notifications.');
          //   } else if (Notification.permission === 'granted') {
          //     // If it's okay let's create a notification
          //     const notification = new Notification(notificationTitle, notificationOptions);
          //     notification.onclick = function (event) {
          //       event.preventDefault();
          //       window.open(payload.notification.click_action, '_blank');
          //       notification.close();
          //     };

          //   }
          // } catch {
          //   console.log('faield notification');
          // }
          // console.log({ payload });
          navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
            registration.showNotification(payload.notification.title, payload.notification);
          });
          // ...
        });
      } catch (error) {
        console.log({ notifweb: error });
      }
    }
  }
};
