import GOFLOATERS_LOGO from './goFloaters-logo.png';
import LOGO from './logo.png';
import WAVE from './wave.png';
import WAVE_VERTICAL from './waveVertical.png';
import ONBOARDING from './onboardingSlider.png';
import ONBOARDING_1 from './onboarding1.png';
import ONBOARDING_2 from './onboarding2.png';
import ONBOARDING_3 from './onboarding3.png';
import BOOKING_CONFIRMATION_BG from './booking-confirmation-lottie-bg.png';
import BOOKING_CONFIRMATION_YAY from './booking-confirmation-yay.png';
import BOOKING_CONFIRMATION_CHECK from './booking-confirmation-check.png';
import BOOKING_CONFIRMATION_CONFETTI from './booking-confirmation-confetti.png';
import HI_SPEED_WIFI from './wifi.png';
import AC from './ac.png';
import RESTROOM from './restroom.png';
import POWER_BACKUP from './power-back-up.png';
import WIFI_BACKUP from './wifi-backup.png';
import WHITEBOARD from './whiteboard.png';
import VIDEO_CONFERENCE from './video-conference.png';
import TV from './tv.png';
import TEA_COFFEE from './tea-coffee.png';
import SMOKING_AREA from './smoking-area.png';
import ROOM_WITH_SYSTEMS from './room-with-systems.png';
import PROJECTOR from './projector.png';
import PRINTER from './printer.png';
import PRINETR_ON_REQUEST from './printer-on-request.png';
import POWER_POINTS from './power-points.png';
import PET_FRIENDLY from './pet-friendly.png';
import PARKING from './parking.png';
import PANTRY from './pantry.png';
import PAID_PARKING from './paid-parking.png';
import OUTSIDE_FOOD_ALLOWED from './outise-food-allowed.png';
import OFFICE_STATIONARY from './office-stationary.png';
import NO_ONSITE_RESTROOM from './no-onsite-restrooms.png';
import METRO from './metro.png';
import MEETING_ROOM from './meeting-room.png';
import MASK_AVAILABLE from './masks-available.png';
import HAND_SANITIZER from './hand-sanitizer.png';
import FIREWALL_SECURITY from './firewall-security.png';
import DISTANCE_3FT from './distance-3ft.png';
import DISINFECTED_DAILY from './disinfected-daily.png';
import DISCOUNT from './discount.png';
import COPIER from './copier.png';
import AUDIO_EQUIPMENT from './audio-equipments.png';
import COIN_SHARE from './coin-share.png';
import LOGIN_BG from './login_bg.png';
import LOGIN_CURVE from './login-curve.png';
import OUTLOOK from './outlook.png';
import GOOGLE_CALENDAR from './google_calendar.png';
import DISCUSSION_BOOTH from './discussion-booth.png';
import FOOD_COURT from './food-court.png';
import LOUNGE_AREA from './lounge-area.png';
import RECREATION from './recreation.png';
import STATIONERY from './stationery.png';
import TELEPHONE_BOOTH from './telephone-booth.png';
import EVENT_ICON from './event-icon.png';
import FAV_COWORKER from './favorite-coworker.png';
import ENGAGE from './engage.png';
import ENGAGE_SELECTED from './engage-selected.png';

import SOS from './sos.png';

export const IMAGES = {
  GOFLOATERS_LOGO,
  WAVE,
  ONBOARDING,
  LOGO,
  WAVE_VERTICAL,
  ONBOARDING_1,
  ONBOARDING_2,
  ONBOARDING_3,
  BOOKING_CONFIRMATION_BG,
  BOOKING_CONFIRMATION_YAY,
  BOOKING_CONFIRMATION_CHECK,
  BOOKING_CONFIRMATION_CONFETTI,
  HI_SPEED_WIFI,
  AC,
  RESTROOM,
  POWER_BACKUP,
  WIFI_BACKUP,
  WHITEBOARD,
  VIDEO_CONFERENCE,
  TV,
  TEA_COFFEE,
  SMOKING_AREA,
  ROOM_WITH_SYSTEMS,
  PROJECTOR,
  PRINTER,
  PRINETR_ON_REQUEST,
  POWER_POINTS,
  PET_FRIENDLY,
  PARKING,
  PANTRY,
  PAID_PARKING,
  OUTSIDE_FOOD_ALLOWED,
  OFFICE_STATIONARY,
  NO_ONSITE_RESTROOM,
  METRO,
  MEETING_ROOM,
  MASK_AVAILABLE,
  HAND_SANITIZER,
  FIREWALL_SECURITY,
  DISTANCE_3FT,
  DISINFECTED_DAILY,
  DISCOUNT,
  COPIER,
  AUDIO_EQUIPMENT,
  COIN_SHARE,
  LOGIN_BG,
  LOGIN_CURVE,
  OUTLOOK,
  GOOGLE_CALENDAR,
  DISCUSSION_BOOTH,
  LOUNGE_AREA,
  FOOD_COURT,
  RECREATION,
  STATIONERY,
  TELEPHONE_BOOTH,
  SOS,
  EVENT_ICON,
  FAV_COWORKER,
  ENGAGE,
  ENGAGE_SELECTED,
};
