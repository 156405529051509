import { createSlice } from '@reduxjs/toolkit';
import set from 'lodash/set';

const INITIAL_STATE = {
  data: {},
};

const metadataSlice = createSlice({
  name: 'metadata',
  initialState: INITIAL_STATE,
  reducers: {
    setMetadata(state, { payload }) {
      set(state, 'data', payload);
    },
  },
});

export const { setMetadata } = metadataSlice.actions;
export default metadataSlice.reducer;
