import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, View, Dimensions, FlatList } from 'react-native';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { useToast } from 'react-native-toast-notifications';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _size from 'lodash/size';

import Text from '../Text';
import Button from '../Button';
import SurveyOption from './SurveyOptions';
import { saveSurveyResponse } from '../../api/common';
import { getSurveyResponse } from '../../utils/common';

const { width } = Dimensions.get('window');

const Survey = ({ survey, user }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [userResponses, setUserResponses] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [submittingSurvey, setSubmittingSurvey] = useState(false);
  const modalizeRef = useRef(null);
  const questionRef = useRef(null);
  const toast = useToast();
  useEffect(() => {
    if (modalOpen) onOpen();
  }, [modalOpen]);

  useEffect(() => {
    setModalOpen(true);
  }, []);

  const onOpen = () => {
    if (modalizeRef?.current) {
      modalizeRef.current?.open();
    }
  };

  const onCancel = () => {
    modalizeRef.current?.close();
  };

  const onOptionSelect = useCallback(
    (questionId, optionValue, optionId) => {
      setUserResponses({
        ...userResponses,
        [questionId]: {
          responseValue: optionValue,
          responseOption: optionId,
        },
      });
    },
    [userResponses]
  );

  const renderSurveyQuestion = useCallback(
    ({ item: { question, options, id } }) => (
      <View style={{ width, flexDirection: 'column', padding: 16, paddingHorizontal: 24, rowGap: 16 }}>
        <Text variant="bold" style={{ textAlign:'left'}}>{question}</Text>
        {_map(options, (option, index) => (
          <SurveyOption
            key={index}
            questionId={id}
            option={option}
            optionId={index + 1}
            selected={userResponses[id]?.responseValue === option.value}
            onOptionSelect={onOptionSelect}
          />
        ))}
      </View>
    ),
    [userResponses, onOptionSelect]
  );

  const onNextSubmitPress = async () => {
    const currenSurveytQuestion = surveyQuestions[currentQuestion];
    if (!userResponses[currenSurveytQuestion.id]) {
      toast.show('Please select an option before proceeding.');
      return;
    }
    if (currentQuestion === _size(surveyQuestions) - 1) {
      try {
        setSubmittingSurvey(true);
        await saveSurveyResponse(_get(user, 'uid'), _get(survey, 'surveyId'), getSurveyResponse(userResponses));
        toast.show('Thanks for taking the survey. Your opinion has been recorded.');
        onCancel();
      } catch {
        toast.show('Something went wrong. Please try again.');
      } finally {
        setSubmittingSurvey(false);
      }
      return;
    }

    questionRef.current.scrollToOffset({
      offset: (currentQuestion + 1) * width,
      animated: true,
    });
    setCurrentQuestion(currentQuestion + 1);
  };

  const surveyQuestions = useMemo(
    () => [
      {
        id: 1,
        question: _get(survey, 'question'),
        options: _map(_get(survey, 'answers'), answer => ({
          label: answer,
          value: answer,
        })),
      },
    ],
    [survey]
  );

  return (
    <Portal>
      <Modalize
        ref={modalizeRef}
        adjustToContentHeight
        withHandle={false}
        closeOnOverlayTap={false}
        modalStyle={{ paddingVertical: 16 }}>
        <View style={[styles.marginBottom, styles.paddingHorizontal]}>
          <Text size={0} variant="semiBold">
            WorqFlexi Survey
          </Text>
        </View>
        <FlatList
          ref={questionRef}
          scrollEnabled={false}
          data={surveyQuestions}
          renderItem={renderSurveyQuestion}
          keyExtractor={item => item.id}
          horizontal
          pagingEnabled
        />
        <Text numberOfLines={0} size={2} style={{ paddingHorizontal: 16, paddingBottom: 80, paddingTop: 64, textAlign:'left' }}>
          NOTE : This survey is 100% anonymous! We won't be peeking at who said what, so feel free to spill those honest
          thoughts. Your candor makes this survey rock!
        </Text>
        <Button
          textProps={{ variant: 'semiBold' }}
          variant="medium"
          style={[styles.marginBottom, { marginHorizontal: 16 }]}
          loading={submittingSurvey}
          onPress={onNextSubmitPress}>
          {currentQuestion === _size(surveyQuestions) - 1 ? 'Submit' : 'Next'}
        </Button>
      </Modalize>
    </Portal>
  );
};

const styles = StyleSheet.create({
  marginBottom: {
    marginBottom: 16,
  },
  paddingHorizontal: {
    paddingHorizontal: 16,
  },
  pointContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginHorizontal: 8,
    marginBottom: 8,
  },
  headingContainer: {
    marginVertical: 16,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 4,
  },
});

export default Survey;
