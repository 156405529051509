import React, { useState, useImperativeHandle, forwardRef, useMemo } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import moment from 'moment-timezone';
import { Feather } from '@expo/vector-icons';
import { useToast } from 'react-native-toast-notifications';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _last from 'lodash/last';
import _filter from 'lodash/filter';

import Text from '../../../../components/Text';
import DatePicker from '../../../../components/DatePicker';
import TimePicker from '../../../../components/TimePicker';
import IconLabelCard from '../../../../components/IconLabelCard';
import SeatSelector from '../../../../components/SeatSelector';
import { COLORS } from '../../../../constants/colors';
import {
  areAllBookingsValid,
  getInitialMeetingBooking,
  getMeetingRoomBookingPayload,
  getMeetingRoomCharges,
  getMinSeats,
  getNewMeetingBookingPlaceholder,
  isInstantlyBookableAwfis,
  isInstantlyBookableWeWork,
  isInstantlyBookableCowrks,
  roundToNearestXXMinutes,
} from '../../../../utils/common';
import { MAX_ALLOWED_BOOKINGS, NUMBERS, SPACE_BOOKING_TYPES, STATUS } from '../../../../constants/common';

const MeetingRoomBooking = forwardRef(({ timeStamp, spaceDetails, user, organization }, ref) => {
  const toast = useToast();
  const [bookingType, setBookingType] = useState(
    _get(getInitialMeetingBooking(timeStamp, spaceDetails), 'bookingType')
  );
  const [meetingBookings, setMeetingBookings] = useState([getInitialMeetingBooking(timeStamp, spaceDetails)]);

  useImperativeHandle(
    ref,
    () => ({
      getBookingPayload() {
        const validBookings = areAllBookingsValid(spaceDetails, meetingBookings, bookingType);
        // console.log({ validBookings });
        if (validBookings.status === STATUS.FAILED) {
          toast.show(validBookings.msg);
          return false;
        }
        return getMeetingRoomBookingPayload(spaceDetails, user, meetingBookings, bookingType);
      },
    }),
    [meetingBookings, user, spaceDetails]
  );

  const updateBookings = (index, key, value) => {
    const currentDayBookings = [...meetingBookings];
    setMeetingBookings(_set(currentDayBookings, `${index}.${key}`, value));
  };

  const updateSeatsNumber = index => seats => {
    if (seats >= getMinSeats(spaceDetails) && seats <= _get(spaceDetails, 'seatsAvailable', 15)) {
      updateBookings(index, 'noOfSeats', seats);
    }
  };
  const onSelectDate = index => (_, time) => {
    if (time > moment().startOf('day').valueOf()) {
      updateBookings(index, 'bookingDate', time);
    } else {
      toast.show('Please select valid date');
    }
  };

  const onSelectNextSlot = index => (_, time) => {
    if (isInstantlyBookableAwfis(spaceDetails) || isInstantlyBookableWeWork(spaceDetails) || isInstantlyBookableCowrks(spaceDetails) ) {
      const updatedTime = roundToNearestXXMinutes(time, isInstantlyBookableAwfis(spaceDetails)?60:30);
      updateBookings(index, 'startTime', updatedTime);
    } else {
      updateBookings(index, 'startTime', time);
    }
  };

  // const onSelectEndSlot = index => (_, time) => {
  //   updateBookings(index, 'endTime', time);
  // };
  const updateDuration = index => selectedDuration => {
    if (
      selectedDuration > 0 &&
      selectedDuration <= Number((_get(user, 'isOrganizationAdmin') ? 12: _get(organization, 'config.maxMeetingRoomDuration', 12)) || 12)
    )
      updateBookings(index, 'duration', selectedDuration);
  };

  const addBooking = () => {
    setMeetingBookings([...meetingBookings, getNewMeetingBookingPlaceholder(meetingBookings, spaceDetails)]);
  };

  const removeBooking = index => () => {
    setMeetingBookings(_filter(meetingBookings, (_, bookingIndex) => bookingIndex !== index));
  };

  const updateBookingType = type => {
    setBookingType(type);
    setMeetingBookings([getInitialMeetingBooking(timeStamp, spaceDetails)]);
  };

  const price = useMemo(
    () => getMeetingRoomCharges(spaceDetails, meetingBookings, bookingType),
    [bookingType, meetingBookings, spaceDetails]
  );

  return (
    <View>
      {(_get(organization, 'config.showRates') || _get(user, 'isOrganizationAdmin')) ? (
        <View style={[styles.baseMarginTop, styles.totalPrice]}>
          <Text size={3}>Credits needed</Text>
          <Text size={2} variant="bold">
            {/* {`₹749/hour or ₹5000/day`} */}
            {/* {`${`₹`}${price}${price ? '/day' : ''}`} */}
            {`₹ ${price}`}
          </Text>
        </View>
      ) : null}

      {isInstantlyBookableAwfis(spaceDetails) ? (
        <View>
          <Text size={4} variant="medium" numberOfLines={0} style={{ textAlign: 'left' }} >
            * Space can only be booked for start of the hour. Any other time selected will be rounded off to nearest
            start hour.
          </Text>
        </View>
      ) : null}
      {isInstantlyBookableWeWork(spaceDetails) ? (
        <View>
          <Text size={4} variant="medium" numberOfLines={0} style={{ alignSelf: 'left' }} >
            * Space can only be booked for start of the hour or 30 mins past. Any other time selected will be rounded off to nearest
            start hour or 30 mins past.
          </Text>
        </View>
      ) : null}
      {isInstantlyBookableCowrks(spaceDetails) ? (
        <View>
          <Text size={4} variant="medium" numberOfLines={0} style={{ alignSelf: 'flex-start' }} >
            * Space can only be booked for start of the hour or 30 mins past. Any other time selected will be rounded off to nearest
            start hour or 30 mins past.
          </Text>
        </View>
      ) : null}

      <View style={[styles.margin30, styles.dateTimeContainer]}>
        {_get(spaceDetails, 'hourPassAvailable') ? (
          <View style={styles.fullFlex}>
            <Text variant="bold" size={3} style={{ alignSelf: 'flex-start' }} >
              Hourly Booking
            </Text>
            <Pressable onPress={() => updateBookingType(SPACE_BOOKING_TYPES.HOUR)}>
              <IconLabelCard
                iconFamily={Feather}
                iconProps={{
                  name: 'check',
                  size: 16,
                  color: bookingType === 'Hour' ? COLORS.WHITE : COLORS.TEXT_COLOR_SECONDARY,
                }}
                containerStyle={styles.subMarginTop}
                labelProps={{ size: 0 }}
                {...{ colors: bookingType === 'Hour' ? [COLORS.PRIMARY_COLOR, COLORS.PRIMARY_COLOR] : null }}
              />
            </Pressable>
          </View>
        ) : null}
        {_get(spaceDetails, 'dayPassAvailable') ? (
          <View style={styles.fullFlex}>
            <Text variant="bold" size={3} style={{ alignSelf: 'flex-start' }} >
              Day Booking
            </Text>
            <Pressable onPress={() => updateBookingType(SPACE_BOOKING_TYPES.DAY)}>
              <IconLabelCard
                iconFamily={Feather}
                iconProps={{
                  name: 'check',
                  size: 16,
                  color: bookingType === 'Day' ? COLORS.WHITE : COLORS.TEXT_COLOR_SECONDARY,
                }}
                containerStyle={styles.subMarginTop}
                labelProps={{ size: 0 }}
                {...{ colors: bookingType === 'Day' ? [COLORS.PRIMARY_COLOR, COLORS.PRIMARY_COLOR] : null }}
              />
            </Pressable>
          </View>
        ) : null}
      </View>

      {_map(meetingBookings, ({ bookingDate, startTime, noOfSeats, duration }, index) => (
        <React.Fragment key={index}>
          {_size(meetingBookings) > 1 ? (
            <View style={[styles.bookingHeading, styles.margin30]}>
              <Text variant="bold" size={3} color={COLORS.TEXT_COLOR_SECONDARY}>
                {NUMBERS[index]} &nbsp; Booking
              </Text>
              <Text variant="bold" size={3} style={styles.cancelBooking} onPress={removeBooking(index)}>
                Cancel
              </Text>
            </View>
          ) : null}
          <View style={[styles.margin30, styles.dateTimeContainer]}>
            <View style={styles.fullFlex}>
              <Text variant="bold" size={3} style={{ alignSelf: 'flex-start' }} >
                Date
              </Text>
              <View style={styles.subMarginTop}>
                <DatePicker pickerMode="date" onSelect={onSelectDate(index)} date={new Date(Number(bookingDate))} />
              </View>
            </View>
          </View>

          <View style={[styles.margin30, styles.dateTimeContainer]}>
            <View style={styles.fullFlex}>
              <Text variant="bold" size={3} style={{ alignSelf: 'flex-start' }} >
                Next Slot
              </Text>
              <View style={styles.subMarginTop}>
                <TimePicker pickerMode="time" onSelect={onSelectNextSlot(index)} date={new Date(Number(startTime))} />
              </View>
            </View>
            {bookingType === 'Hour' ? (
              <View style={styles.fullFlex}>
                <Text variant="bold" size={3} style={{ alignSelf: 'flex-start' }} >
                  Duration
                </Text>
                <View style={styles.subMarginTop}>
                  <SeatSelector seats={duration} setSeats={updateDuration(index)} />
                </View>
                {/* <TimePicker pickerMode="time" onSelect={onSelectEndSlot(index)} date={new Date(Number(endTime))} /> */}
              </View>
            ) : null}
          </View>
          {/* <View style={[styles.margin30, styles.dateTimeContainer]}>
            <View style={styles.fullFlex}>
              <Text variant="bold" size={3}>
                Seats
              </Text>
              <View style={styles.subMarginTop}>
                <SeatSelector seats={noOfSeats} setSeats={updateSeatsNumber(index)} />
              </View>
            </View>
          </View> */}
        </React.Fragment>
      ))}
      {_size(meetingBookings) < MAX_ALLOWED_BOOKINGS && (!_get(organization, 'config.disableMultiDayBookings') || _get(user, 'isOrganizationAdmin')) ? (
        <Text
          size={3}
          variant="bold"
          color={COLORS.TEXT_COLOR_SECONDARY}
          style={styles.multipleDates}
          onPress={addBooking}>
          {_size(meetingBookings) < 2 ? 'Book multiple dates' : 'Add more'}
        </Text>
      ) : null}
    </View>

    // <>
    //   <View style={[styles.margin30, styles.dateTimeContainer]}>
    //     <View style={styles.fullFlex}>
    //       <Text variant="bold" size={3}>
    //         Today
    //       </Text>
    //       <View style={styles.subMarginTop}>
    //         <DatePicker pickerMode="date" onSelect={onSelectDate} date={new Date(Number(bookingDate))} />
    //       </View>
    //     </View>
    //   </View>

    //   <View style={[styles.margin30, styles.dateTimeContainer]}>
    //     <View style={styles.fullFlex}>
    //       <Text variant="bold" size={3}>
    //         Hourly Booking
    //       </Text>
    //       <Pressable onPress={() => setBookingType(SPACE_BOOKING_TYPES.HOUR)}>
    //         <IconLabelCard
    //           iconFamily={Feather}
    //           iconProps={{
    //             name: 'check',
    //             size: 16,
    //             color: bookingType === 'Hour' ? COLORS.WHITE : COLORS.TEXT_COLOR_SECONDARY,
    //           }}
    //           containerStyle={styles.subMarginTop}
    //           labelProps={{ size: 0 }}
    //           {...{ colors: bookingType === 'Hour' ? ['#2ED573', '#2ED573'] : null }}
    //         />
    //       </Pressable>
    //     </View>
    //     <View style={styles.fullFlex}>
    //       <Text variant="bold" size={3}>
    //         Day Booking
    //       </Text>
    //       <Pressable onPress={() => setBookingType(SPACE_BOOKING_TYPES.DAY)}>
    //         <IconLabelCard
    //           iconFamily={Feather}
    //           iconProps={{
    //             name: 'check',
    //             size: 16,
    //             color: bookingType === 'Day' ? COLORS.WHITE : COLORS.TEXT_COLOR_SECONDARY,
    //           }}
    //           containerStyle={styles.subMarginTop}
    //           labelProps={{ size: 0 }}
    //           {...{ colors: bookingType === 'Day' ? ['#2ED573', '#2ED573'] : null }}
    //         />
    //       </Pressable>
    //     </View>
    //   </View>

    //   {bookingType === 'Hour' ? (
    //     <View style={[styles.margin30, styles.dateTimeContainer]}>
    //       <View style={styles.fullFlex}>
    //         <Text variant="bold" size={3}>
    //           Next Slot
    //         </Text>
    //         <TimePicker pickerMode="time" onSelect={onSelectNextSlot} date={new Date(Number(startTime))} />
    //       </View>
    //       <View style={styles.fullFlex}>
    //         <Text variant="bold" size={3}>
    //           Booking till
    //         </Text>
    //         <TimePicker pickerMode="time" onSelect={onSelectEndSlot} date={new Date(Number(endTime))} />
    //       </View>
    //     </View>
    //   ) : null}
    //   <View style={[styles.margin30, styles.dateTimeContainer]}>
    //     <View style={styles.fullFlex}>
    //       <Text variant="bold" size={3}>
    //         Seats
    //       </Text>
    //       <View style={styles.subMarginTop}>
    //         <SeatSelector seats={noOfSeats} setSeats={updateSeatsNumber} />
    //       </View>
    //     </View>
    //   </View>
    // </>
  );
});

const styles = StyleSheet.create({
  margin30: {
    marginTop: 30,
  },
  dateTimeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  fullFlex: {
    flex: 1,
  },
  baseMarginTop: {
    marginTop: 20,
  },
  subMarginTop: {
    marginTop: 10,
  },
  totalPrice: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 12,
    backgroundColor: COLORS.HALF_WHITE,
    borderRadius: 4,
  },
  multipleDates: {
    textDecorationLine: 'underline',
    color: COLORS.TEXT_COLOR_SECONDARY,
    marginTop: 30,
    alignSelf: 'flex-start',
  },

  bookingHeading: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cancelBooking: {
    textDecorationLine: 'underline',
    color: COLORS.TEXT_COLOR_SECONDARY,
  },
});

export default MeetingRoomBooking;
