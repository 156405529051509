import { useCallback, useMemo, useRef } from 'react';
import { View, StyleSheet, Pressable, FlatList } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import _get from 'lodash/get';
import _concat from 'lodash/concat';

import { COLORS } from '../../constants/colors';
import Text from '../Text';
import { SPACE_SORT_OPTIONS, SPACE_SORT_OPTIONS_PRICING } from '../../constants/common';
import Separator from '../Separator';

const SortSpaces = ({ selectedSort, onSelectSort, organization }) => {
  const sortSheetRef = useRef(null);

  const onOpen = useCallback(() => {
    if (sortSheetRef?.current) {
      sortSheetRef.current?.open();
    }
  }, [sortSheetRef.current]);

  const onCancel = useCallback(() => {
    sortSheetRef.current?.close();
  }, [sortSheetRef.current]);

  const onSelectingSortOption = useCallback(
    item => {
      onSelectSort(item);
      onCancel();
    },
    [onSelectSort, onCancel]
  );

  const onClear = () => {
    onSelectSort(null);
    onCancel();
  };

  const renderSearchSortItem = ({ item }) => (
    <Pressable style={styles.sortSearchItem} onPress={() => onSelectingSortOption(item)}>
      <Text
        size={3}
        variant="semiBold"
        color={selectedSort === _get(item, 'value') ? COLORS.PRIMARY_COLOR : COLORS.TEXT_COLOR_PRIMARY}>
        {_get(item, 'title')}
      </Text>
      <Text size={3} color={selectedSort === _get(item, 'value') ? COLORS.PRIMARY_COLOR : COLORS.TEXT_COLOR_PRIMARY}>
        {_get(item, 'subtitle')}
      </Text>
    </Pressable>
  );

  const sortOptions = useMemo(
    () =>
      _get(organization, 'config.showRates')
        ? _concat(SPACE_SORT_OPTIONS, SPACE_SORT_OPTIONS_PRICING)
        : SPACE_SORT_OPTIONS,
    [organization]
  );

  const renderSeparator = () => <Separator />;

  const renderSortSelectionView = () => (
    <>
      <View style={styles.sortHeading}>
        <Text variant="semiBold" size={1}>
          Sort
        </Text>
        <Text variant="semiBold" size={3} color={COLORS.RED} onPress={onClear}>
          Clear
        </Text>
      </View>
      <FlatList
        data={sortOptions}
        renderItem={renderSearchSortItem}
        keyExtractor={item => _get(item, 'value')}
        ItemSeparatorComponent={renderSeparator}
        style={styles.bottomMargin}
      />
    </>
  );

  const additionalStyling = useMemo(
    () => (selectedSort ? { borderColor: COLORS.PRIMARY_COLOR, borderWidth: 2 } : {}),
    [selectedSort]
  );
  return (
    <>
      <Pressable style={[styles.container, additionalStyling]} onPress={onOpen}>
        <FontAwesome name="sort-amount-asc" size={16} color="black" />
      </Pressable>
      <Portal>
        <Modalize ref={sortSheetRef} adjustToContentHeight withHandle={false} modalStyle={{ padding: 16 }}>
          {renderSortSelectionView()}
        </Modalize>
      </Portal>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 45,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: COLORS.PRIMARY_BORDER_COLOR,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  sortHeading: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 16,
  },
  sortSearchItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bottomMargin: {
    marginBottom: 16,
  },
});

export default SortSpaces;
