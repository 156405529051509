import React from 'react';
import { Image, View, StyleSheet, Dimensions } from 'react-native';

import { IMAGES } from '../../assets/images';

const { height, width } = Dimensions.get('window');

function SplashScreen() {
  return (
    <View style={styles.container}>
      <Image source={IMAGES.GOFLOATERS_LOGO} style={styles.logoStyle} resizeMode="contain" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoStyle: {
    width: '100%',
    height: 80,
    flex: 1,
  },
});

export default SplashScreen;
