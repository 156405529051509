import { useEffect, useCallback, useMemo, useState } from 'react';
import { View, StyleSheet, FlatList, Dimensions } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useFocusEffect } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';

import { connect } from 'react-redux';
import { query, onValue, ref, equalTo, orderByChild } from 'firebase/database';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _values from 'lodash/values';
import _concat from 'lodash/concat';
import _isEmpty from 'lodash/isEmpty';

import {
  setFavorites as setFavoritesAction,
  setReloadFavorites as setReloadFavoritesAction,
} from '../../reducers/user';
import { getUserFavorites } from '../../api/common';
import { SpaceCardwithActions, SpaceCardSmall } from '../../components/cards';
import Text from '../../components/Text';
import { COLORS } from '../../constants/colors';
import { getUserFavoritesData, showWhatsNewModal } from '../../selectors/common';
import { database } from '../../../firebaseConfig';
import { getBookingsByType } from '../../utils/common';
import Skeleton from '../../components/skeleton';
import Separator from '../../components/Separator';
import { BOOKING_TYPE_VALUES } from '../../constants/common';
import { ROUTES } from '../../constants/routes';
import NoReferral from '../../components/NoReferral';
import EmptyHome from '../../components/EmptyHome';
import WhatsNew from '../../components/WhatsNew';
// import { logEvent } from 'firebase/analytics';

const { width } = Dimensions.get('window');

function Home({ favorites, setFavorites, user, navigation, reloadFavorites, setReloadFavorites, showWhatsNew }) {
  const [activebookings, setActiveBookings] = useState([]);
  const [loadingFavorites, setLoadingFavorites] = useState(true);
  const [bookingsLoading, setBookingsLoading] = useState(true);
  const uid = _get(user, 'uid');
  const organizationId = _get(user, 'organizationId');
  useEffect(() => {
    let unsubscribe = () => {};
    if (uid) {
      // logEvent(anaytics, 'page_view');
      const bookingsRef = query(ref(database, 'bookings'), ...[orderByChild('custId'), equalTo(`${uid}`)]);
      unsubscribe = onValue(bookingsRef, snapshot => {
        // console.log({ snapshot: snapshot.exists() });
        setBookingsLoading(false);
        if (snapshot.exists()) {
          const bookings = snapshot.val();
          setActiveBookings(getBookingsByType(_values(bookings), BOOKING_TYPE_VALUES.ACTIVE));
        }
      });
    }
    return unsubscribe;
  }, [uid]);

  const fetchUserfavorites = useCallback(async () => {
    try {
      setLoadingFavorites(true);
      const userFavorites = await getUserFavorites(uid);
      setFavorites(userFavorites);
      setLoadingFavorites(false);
    } catch {
      setLoadingFavorites(false);
    }
  }, [uid]);

  useEffect(() => {
    if (uid) fetchUserfavorites();
  }, [uid]);

  useEffect(() => {
    fetchUserfavorites();
  }, [organizationId]);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (reloadFavorites) {
          await fetchUserfavorites();
          setReloadFavorites(false);
        }
      })();
    }, [reloadFavorites])
  );

  const onProfilePress = useCallback(() => {
    navigation.navigate(ROUTES.PROFILE.name);
  }, [navigation]);

  const onBookingCardPress = bookingkey => {
    navigation.navigate(ROUTES.BOOKING_DETAILS.name, { bookingkey });
  };

  const renderActiveBooking = ({ item }) => {
    let style = { width: width - 40 };
    if (_size(activebookings) > 1) style = { width: width * 0.8 };
    return <SpaceCardSmall space={item} style={style} showGoFLoatersName={false} onPress={onBookingCardPress} />;
  };

  const activeBookingsView = useMemo(() => {
    if (_size(activebookings) > 0) {
      return (
        <View style={styles.activeBookingsContainer}>
          <Text size={2} variant="bold" style={{ marginBottom: 12,textAlign:'left' }}>
            Active Bookings
          </Text>
          <FlatList
            data={activebookings}
            renderItem={renderActiveBooking}
            keyExtractor={item => _get(item, 'bookingid')}
            horizontal
            ItemSeparatorComponent={() => <Separator type="vertical" thickness={16} gap={0} color={COLORS.WHITE} />}
            showsHorizontalScrollIndicator={false}
          />
        </View>
      );
    }
    return null;
  }, [activebookings]);

  const renderFavorites = ({ item }) => {
    if (item === 'LOADING')
      return (
        <View style={{ marginTop: 16, marginHorizontal: 16 }}>
          <Skeleton />
          <Separator color="transparent" />
          <Skeleton />
        </View>
      );

    return (
      <View style={{ marginHorizontal: 16 }}>
        <SpaceCardwithActions space={item} navigation={navigation} />
      </View>
    );
  };

  const homeHeader = () => (
    <>
      <View style={styles.profileRowStyle}>
        <Text size={9} variant="bold" style={styles.profielNameStyle}>
          Hello, {_get(user, 'displayName')}!
        </Text>
        <FontAwesome name="user" size={20} color="black" style={styles.profielIconStyle} onPress={onProfilePress} />
      </View>
      {activeBookingsView}
      {loadingFavorites || !_isEmpty(favorites) ? (
        <Text size={2} variant="bold" style={{ marginBottom: 12, marginHorizontal: 16, alignSelf: 'flex-start' }}>
          Favorites {_isEmpty(favorites) ? '' : `(${favorites.length})`}
        </Text>
      ) : null}
    </>
  );

  const getListEmptyComponent = useCallback(() => {
    if (!loadingFavorites && !bookingsLoading && _isEmpty(activebookings)) {
      return <EmptyHome navigation={navigation} />;
    }
    if (bookingsLoading) return null;
    return (
      <Text variant="bold" size={4} style={{ alignSelf: 'center' }}>
        No Favorites
      </Text>
    );
  }, [loadingFavorites, bookingsLoading, activebookings]);

  const favoritesView = useMemo(
    () => (
      <FlatList
        data={_concat([], loadingFavorites ? 'LOADING' : _size(favorites) > 0 ? favorites : [])}
        // data={_concat([], 'LOADING')}
        renderItem={renderFavorites}
        contentContainerStyle={{ paddingBottom: 20 }}
        keyExtractor={(item, index) => index}
        ListHeaderComponent={homeHeader}
        ItemSeparatorComponent={() => <View style={{ height: 24 }} />}
        ListEmptyComponent={getListEmptyComponent}
        showsVerticalScrollIndicator={false}
      />
    ),
    [favorites, loadingFavorites, getListEmptyComponent]
  );

  return (
    <SafeAreaView style={styles.container} edges={['right', 'top', 'left']}>
      {favoritesView}
      {/* <EmptyHome navigation={navigation} /> */}
      <NoReferral navigation={navigation} />
      {showWhatsNew ? <WhatsNew showModal={showWhatsNew} /> : null}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // paddingHorizontal: 16,
    paddingTop: 16,
    backgroundColor: COLORS.WHITE,
  },
  activeBookingsContainer: {
    marginBottom: 24,
    marginHorizontal: 16,
  },
  favoritesContainer: {},
  profileRowStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 16,
  },
  profielIconStyle: {
    marginHorizontal: 16,
  },
  profielNameStyle: {
    alignSelf: 'flex-start',
    marginHorizontal: 16,
  },
});

const mapStateToProps = state =>
  // console.log({ state });
  ({
    favorites: getUserFavoritesData(state.user.favorites),
    user: state.user.user,
    reloadFavorites: state.user.reloadFavorites,
    showWhatsNew: showWhatsNewModal(state.user.whatsNewVisisted),
  });
export default connect(mapStateToProps, {
  setFavorites: setFavoritesAction,
  setReloadFavorites: setReloadFavoritesAction,
})(Home);
