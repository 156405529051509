import { useCallback, useMemo } from 'react';
import { View, StyleSheet, Pressable, Linking, Platform, Share } from 'react-native';
import { MaterialIcons, Feather, AntDesign } from '@expo/vector-icons';
import _get from 'lodash/get';
import _head from 'lodash/head';
import { useToast } from 'react-native-toast-notifications';
import * as Clipboard from 'expo-clipboard';
import { useNavigation } from '@react-navigation/native';

import Text from './Text';
import IconLabelCard from './IconLabelCard';
import { getGoogleMapsLinkForSpaceDetails, getSpaceDetailstoShare, isSpacefavorite } from '../utils/common';
import { addOrRemoveSpaceToFavorites } from '../utils/firebaseUtils';
import VideoPlayer from './VideoPlayer';
import { ROUTES } from '../constants/routes';

const SpaceQuickActions = ({ space, user, setReloadFavorites }) => {
  const navigation = useNavigation();
  const toast = useToast();
  const onClickOpenMaps = useCallback(() => {
    Linking.openURL(getGoogleMapsLinkForSpaceDetails(space));
  }, [space]);

  const onFavoriteClick = useCallback(async () => {
    const response = await addOrRemoveSpaceToFavorites(_get(space, 'spaceId'), user);
    setReloadFavorites(true);
    toast.show(_get(response, 'msg'));
  }, [space, user]);

  const onSharePress = useCallback(async () => {
    const message = getSpaceDetailstoShare(space);
    if (Platform.OS === 'web') {
      if (navigator?.share) {
        await navigator.share({
          title: message,
          text: message,
        });
        return;
      }

      await Clipboard.setStringAsync(message);
      toast.show('Message copied.');
      return;
    }

    try {
      const result = await Share.share({
        message,
        title: message,
      });
      // console.log({ result });
    } catch (error) {
      console.log({ error });
    }
  }, [space]);

  const onLayoutClick = useCallback(() => {
    navigation.navigate(ROUTES.VIEW_IMAGE.name, { imageUrl: _get(space, 'layoutImage') });
  }, [navigation, space]);

  const layoutImage = useMemo(
    () => (
      <Pressable style={styles.iconLabelStyle} onPress={onLayoutClick}>
        <IconLabelCard
          label="View Layout"
          iconFamily={AntDesign}
          iconProps={{ name: 'layout', size: 16 }}
          containerStyle={styles.iconLabelStyle}
        />
      </Pressable>
    ),
    [onLayoutClick]
  );

  return (
    <View style={styles.baseMarginTop}>
      <Text size={3} variant="bold" style={{ alignSelf: 'flex-start' }} >
        Quick Actions
      </Text>
      <View style={styles.subMarginTop}>
        <View style={styles.iconLabelContainer}>
          <Pressable style={styles.iconLabelStyle} onPress={onFavoriteClick}>
            <IconLabelCard
              label={isSpacefavorite(user, space) ? 'Remove Favorite' : 'Add Favorite'}
              iconFamily={MaterialIcons}
              iconProps={{ name: 'favorite-border', size: 16 }}
              containerStyle={styles.iconLabelStyle}
            />
          </Pressable>
          <Pressable style={styles.iconLabelStyle} onPress={onClickOpenMaps}>
            <IconLabelCard
              label="Open in Maps"
              iconFamily={Feather}
              iconProps={{ name: 'send', size: 16 }}
              containerStyle={styles.iconLabelStyle}
            />
          </Pressable>
        </View>
      </View>
      <View style={styles.subMarginTop}>
        <View style={styles.iconLabelContainer}>
          <Pressable style={styles.iconLabelStyle} onPress={onSharePress}>
            <IconLabelCard
              label="Share Url"
              iconFamily={Feather}
              iconProps={{ name: 'share', size: 16 }}
              containerStyle={styles.iconLabelStyle}
            />
          </Pressable>
          {_head(_get(space, 'videos')) ? (
            <VideoPlayer source={_head(_get(space, 'videos'))} navigation={navigation} />
          ) : _get(space, 'layoutImage') ? (
            layoutImage
          ) : null}
        </View>
      </View>
      {_head(_get(space, 'videos')) && _get(space, 'layoutImage') ? (
        <View style={styles.subMarginTop}>
          <View style={styles.iconLabelContainer}>{layoutImage}</View>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  subMarginTop: {
    marginTop: 10,
  },
  baseMarginTop: {
    marginTop: 20,
  },
  iconLabelContainer: {
    flexDirection: 'row',
  },
  iconLabelStyle: {
    flex: 1,
  },
});

export default SpaceQuickActions;
