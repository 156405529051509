import React, { useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import _get from 'lodash/get';

import { COLORS } from '../../constants/colors';
import Text from '../Text';

const IndividualMemberView = ({ member, removeSelectedMember }) => {
  const onRemoveItemClick = useCallback(() => {
    removeSelectedMember(_get(member, 'uid'));
  }, [member, removeSelectedMember]);
  return (
    <View style={styles.container}>
      <Text size={3} style={{ marginRight: 4 }}>
        {_get(member, 'customerName') || _get(member, 'displayName')}
      </Text>
      <AntDesign name="close" size={16} color="black" style={{ marginTop: 1 }} onPress={onRemoveItemClick} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 8,
    backgroundColor: COLORS.PROFILE_CARD_BACKGROUND,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 8,
    marginBottom: 8,
  },
});

export default IndividualMemberView;
