/* eslint-disable arrow-body-style */
import { View, StyleSheet } from 'react-native';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import { Feather } from '@expo/vector-icons';
import { Popable } from 'react-native-popable';

import { COLORS } from '../../../../constants/colors';
import Text from '../../../../components/Text';
import InviteTeam from '../../../../components/inviteTeam';

const AdminActions = ({ booking, index, addOthersDetailsForBookings, user }) => {
  const getSelectedTeamMembers = (selectedTeamMembers, _, onCancel) => {
    const selectedUsersIds = _map(selectedTeamMembers, member => _get(member, 'uid'));
    addOthersDetailsForBookings(selectedUsersIds);
    onCancel();
  };
  if (index === 0)
    return (
      <InviteTeam
        user={user}
        onSavePress={getSelectedTeamMembers}
        saveButtonLabel="Done"
        clearOnCancel={false}
        modalHeading="Select Team Members"
        customViewStyle={{ alignItems: 'flex-start' }}
        customView={
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text size={3} variant="bold" color={COLORS.TEXT_COLOR_SECONDARY} style={styles.bookForOthersText}>
              Book for others{' '}
              {_size(_get(booking, 'custIds')) > 0 ? `(${_size(_get(booking, 'custIds'))} selected)` : ''}
            </Text>
            <Popable content="As an admin you can make booking for others. The bookings will be done in the names of the team members you select.">
              <Feather name="info" size={16} color={COLORS.TEXT_COLOR_SECONDARY} style={{ marginTop: 8 }} />
            </Popable>
          </View>
        }
      />
    );

  return null;
};

const styles = StyleSheet.create({
  bookForOthersText: {
    marginTop: 8,
    textDecorationLine: 'underline',
    color: COLORS.TEXT_COLOR_SECONDARY,
  },
});

export default AdminActions;
