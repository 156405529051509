import { getAuth, signInWithPopup, OAuthProvider } from 'firebase/auth';
import { appleProvider, auth, googleProvider } from '../../../firebaseConfig';

export const onApplePress = async () => {
  try {
    const result = await signInWithPopup(auth, appleProvider);
    const { user } = result;

    // Apple credential
    const credential = OAuthProvider.credentialFromResult(result);
    const { accessToken } = credential;
    const { idToken } = credential;
    // console.log({ credential, user, accessToken, idToken, result });
    // return { idToken: '', nonce: '' };
    return user;
  } catch (error) {
    console.log({ error });
  }

  // const result = await signInWithPopup(auth, appleProvider);

  //   .then(result => {
  //     // The signed-in user info.
  //     const { user } = result;

  //     // Apple credential
  //     const credential = OAuthProvider.credentialFromResult(result);
  //     console.log({ credential, user });
  //     const { accessToken } = credential;
  //     const { idToken } = credential;
  //     console.log({ user, accessToken, idToken });
  //     // IdP data available using getAdditionalUserInfo(result)
  //     // ...
  //   })
  //   .catch(error => {
  //     // Handle Errors here.
  //     const errorCode = error.code;
  //     const errorMessage = error.message;
  //     // The email of the user's account used.
  //     const { email } = error.customData;
  //     // The credential that was used.
  //     const credential = OAuthProvider.credentialFromError(error);
  //     console.log({ errorCode, errorMessage, email, credential });
  //     // ...
  //   });
  return { idToken: '', nonce: '' };
};
