import moment from 'moment-timezone';
import { useState } from 'react';
import { TimePickerModal } from 'react-native-paper-dates';
import TimeItem from './TimeItem';

const DatePicker = ({ pickerMode, onSelect, date, disableEdit = false }) => {
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const showDateModal = () => {
    if (disableEdit) return;
    setDatePickerVisibility(true);
  };

  const showTimeModal = () => {
    if (disableEdit) return;
    setDatePickerVisibility(true);
  };

  const hideDateModal = () => {
    setDatePickerVisibility(false);
  };

  const onSelectDateTime = time => {
    setDatePickerVisibility(false);
    onSelect(pickerMode, moment(`${time.hours}:${time.minutes}`, 'hh:mm').valueOf());
  };
  return (
    <>
      <TimeItem
        onClick={pickerMode === 'time' ? showTimeModal : showDateModal}
        icon="calendar"
        label={moment(date).format(pickerMode === 'time' ? 'LT' : 'D MMM')}
      />
      {isDatePickerVisible ? (
        <TimePickerModal
          visible={isDatePickerVisible}
          onDismiss={hideDateModal}
          onConfirm={onSelectDateTime}
          use24HourClock={false}
          hours={moment(date).format('HH')}
          minutes={moment(date).format('mm')}
        />
      ) : null}
    </>
  );
};
export default DatePicker;
