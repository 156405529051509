import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { useToast } from 'react-native-toast-notifications';

import Text from '../Text';
import Button from '../Button';
import TextInput from '../TextInput';
import { isValidOTPForBooking } from '../../utils/common';

const VerifyBookingOTP = ({ otp, onVerify }) => {
  const [enteredOtp, setEnteredOtp] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const modalizeRef = useRef(null);
  const toast = useToast();

  useEffect(() => {
    setModalOpen(true);
  }, []);

  useEffect(() => {
    if (modalOpen) onOpen();
  }, [modalOpen]);

  const onOpen = () => {
    if (modalizeRef?.current) {
      modalizeRef.current?.open();
    }
  };

  const onCancel = () => {
    modalizeRef.current?.close();
  };

  const onSubmitOtp = async () => {
    try {
      if (isValidOTPForBooking(otp, enteredOtp)) {
        setSubmitting(true);
        await onVerify();
        setEnteredOtp('');
        setSubmitting(false);
        onCancel();
      } else {
        toast.show('Please enter a valid OTP.');
      }
    } catch {
      toast.show('Something went wrong. Please try again');
      onCancel();
    }
  };

  return (
    <Modalize
      ref={modalizeRef}
      adjustToContentHeight
      withHandle={false}
      closeOnOverlayTap={false}
      modalStyle={{ padding: 16 }}>
      <View style={styles.marginBottom}>
        <Text variant="bold" size={3} style={styles.marginBottom}>
          Please enter the OTP sent to your email
        </Text>
        <TextInput
          value={enteredOtp}
          onChangeText={setEnteredOtp}
          containerStyle={styles.marginBottom}
          // maxLength={6}
          keyboardType="number-pad"
          textContentType="oneTimeCode"
          autoComplete="sms-otp"
          style={{ textAlign: 'center', fontWeight: 'bold' }}
        />
      </View>
      <Button variant="medium" style={{ marginBottom: 8, marginTop: 32 }} onPress={onSubmitOtp} loading={submitting}>
        Submit
      </Button>
    </Modalize>
  );
};

const styles = StyleSheet.create({
  marginBottom: {
    marginBottom: 16,
  },
  pointContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginHorizontal: 8,
    marginBottom: 8,
  },
  headingContainer: {
    marginVertical: 16,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 4,
  },
});

export default VerifyBookingOTP;
