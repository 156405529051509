import { useCallback, useRef, useState } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { useToast } from 'react-native-toast-notifications';
import { LinearGradient } from 'expo-linear-gradient';
import Rating from './Rating';

import IconLabelCard from '../IconLabelCard';
import { COLORS } from '../../constants/colors';
import Text from '../Text';
import Button from '../Button';
import TextInput from '../TextInput';
import { addUserBookingFeedback } from '../../api/common';

const RateExperience = ({ renderView, bookingkey, spaceName, customerName, spaceId }) => {
  const toast = useToast();
  const rateExperienceSheetRef = useRef(null);
  const [rating, setRating] = useState(1);
  const [feedbackComments, setFeedbackComments] = useState('');
  const [savingRating, setSavingRating] = useState(false);
  const onOpen = () => {
    if (rateExperienceSheetRef?.current) {
      rateExperienceSheetRef.current?.open();
    }
  };

  const onCancel = () => {
    rateExperienceSheetRef.current?.close();
    setRating(0);
    setFeedbackComments('');
  };

  const onSaveRatingPress = useCallback(async () => {
    setSavingRating(true);
    try {
      const response = await addUserBookingFeedback({
        rating,
        commentForSpaces: feedbackComments,
        bookingId: bookingkey,
        spaceName,
        spaceId,
        customerName,
      });
      if (response) toast.show('Thank you for providing your feedback');
      else toast.show('Error saving rating. Please try again');
    } catch {
      toast.show('Error saving rating. Please try again');
    }
    setSavingRating(false);
    onCancel();
  }, [rating, feedbackComments]);

  const renderVisibleView = () => (
    // <Pressable onPress={onOpen}>
    //   <IconLabelCard
    //     iconBackgroundStyle={styles.iconBackgroundStyle}
    //     label="Rate experience"
    //     iconFamily={MaterialIcons}
    //     iconProps={{ name: 'feedback' }}
    //     containerStyle={styles.iconLabelStyle}
    //     labelProps={{ size: 3, color: COLORS.TEXT_COLOR_SECONDARY }}
    //   />
    // </Pressable>
    <Pressable style={[styles.iconButtonContainer, styles.flexRowCenter]} onPress={onOpen}>
      <LinearGradient
        // Button Linear Gradient
        colors={['rgba(255, 199, 167, 0.2)', 'rgba(255, 213, 121, 0.2)']}
        style={styles.iconBackgroundStyle}>
        <MaterialIcons name="feedback" size={12} color={COLORS.PRIMARY_COLOR} />
      </LinearGradient>
      <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY}>
        Rate experience
      </Text>
    </Pressable>
  );
  return (
    <View style={styles.container}>
      {renderView ? renderView() : renderVisibleView()}
      <Portal>
        <Modalize
          ref={rateExperienceSheetRef}
          adjustToContentHeight
          withHandle={false}
          closeOnOverlayTap={false}
          modalStyle={{ padding: 16 }}>
          <Text size={9} variant="bold" style={styles.marginBottom}>
            Tell us how we did
          </Text>
          <View style={styles.marginBottom}>
            <Text style={{ marginBottom: 4 }} size={3} color={COLORS.TEXT_COLOR_SECONDARY} variant="semiBold">
              Rating
            </Text>

            <Rating total={5} rating={rating} onSelectRating={selectedRating => setRating(selectedRating)} />
          </View>
          <TextInput
            label="Feedback"
            multiline
            value={feedbackComments}
            onChangeText={setFeedbackComments}
            placeholder="Comments"
            containerStyle={[styles.feedbackContainer, styles.marginBottom]}
            style={styles.feedbackInput}
          />

          <Button variant="small" style={{ marginBottom: 8 }} onPress={onSaveRatingPress} loading={savingRating}>
            Save
          </Button>
          <Text
            numberOfLines={0}
            style={[styles.marginBottom, { textAlign: 'center', paddingHorizontal: 8 }]}
            onPress={onCancel}
            size={3}
            variant="bold"
            color={COLORS.PRIMARY_COLOR}>
            Cancel
          </Text>
        </Modalize>
      </Portal>
    </View>
  );
};

const styles = StyleSheet.create({
  feedbackContainer: {
    height: 'auto',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  feedbackInput: {
    height: 100,
  },
  marginBottom: {
    marginBottom: 16,
  },
  flexRowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconButtonContainer: {
    columnGap: 8,
  },
  iconBackgroundStyle: {
    width: 24,
    height: 24,
    padding: 6,
    borderRadius: 4,
  },
});

export default RateExperience;
