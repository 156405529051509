import { useState } from 'react';
import { View, Dimensions, StyleSheet, Image } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useToast } from 'react-native-toast-notifications';
import { IMAGES } from '../../assets/images';
import Button from '../../components/Button';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import Wave from '../../components/Wave';

import { COLORS } from '../../constants/colors';
import { STATUS } from '../../constants/common';
import { sendResetPasswordMail } from '../../utils/firebaseUtils';

const { width } = Dimensions.get('window');

const ForgotPassword = ({ showEnterEmailView }) => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [sendingResetLink, setSendingResetLink] = useState(false);
  const toast = useToast();

  const onSendPress = async () => {
    if (!email) {
      toast.show('Please enter your email to send link.');
      return;
    }
    setSendingResetLink(true);
    const { status, msg } = await sendResetPasswordMail(email);
    if (status === STATUS.SUCCESS) setEmailSent(true);
    toast.show(msg);
    setSendingResetLink(false);
  };

  return (
    <View style={styles.containerPadding}>
      <Image source={IMAGES.GOFLOATERS_LOGO} style={styles.logoStyle} />
      {!emailSent ? (
        <>
          <TextInput
            value={email}
            placeholder="Email"
            containerStyle={styles.baseMarginBottom}
            onChangeText={setEmail}
          />
          <Button
            textProps={{ variant: 'bold', size: 3 }}
            style={styles.buttonStyle}
            onPress={onSendPress}
            loading={sendingResetLink}>
            Send Reset Email
          </Button>
        </>
      ) : (
        <>
          <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY} style={{ marginTop: 8 }}>
            We have sent the password reset link to{' '}
            <Text size={3} variant="medium">
              {email || 'sainathbanuru@gmail.com'}.
            </Text>
            Click on Login after resetting the password.
          </Text>

          <Text
            size={3}
            color={COLORS.BUTTON_COLORS.SECONDARY_COLOR}
            style={styles.textLink}
            onPress={showEnterEmailView}>
            Login
          </Text>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
  },
  containerPadding: {
    paddingHorizontal: 48,
    paddingTop: 16,
  },
  logoStyle: {
    width: '100%',
    height: 80,
    marginTop: 64,
    marginBottom: 120,
  },
  buttonStyle: {
    marginHorizontal: 32,
    marginTop: 32,
  },
  textLink: {
    alignSelf: 'center',
    padding: 8,
  },
});

export default ForgotPassword;
