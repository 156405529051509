import { useCallback, useEffect, useState } from 'react';
import { View, StyleSheet, Image, Dimensions } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useToast } from 'react-native-toast-notifications';
import { connect, useDispatch } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';

import _get from 'lodash/get';
import { IMAGES } from '../../assets/images';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import { COLORS } from '../../constants/colors';
import Wave from '../../components/Wave';
import Button from '../../components/Button';
import SignupDetails from '../signupDetails/SignupDetails';
import VerifyEmail from '../verifyEmail';
import ForgotPassword from '../forgotPassword';
import {
  isUserExistinDB,
  signUserInWithEmailPassword,
  createUserWithEmailPassword,
  signUserOut,
  isEmailVerified,
  areSignupDetailsProvided,
  updateUserDetails,
  checkAndLogUserIn,
} from '../../utils/firebaseUtils';
import { SIGNIN_VIEWS, STATUS } from '../../constants/common';
import { createUser } from '../../api/common';
import { getCreateUserPayload, getUserLocation } from '../../utils/common';
import { setUser } from '../../reducers/user';
import { logoutUserAction } from '../../commonActions';

const { width } = Dimensions.get('window');

const SignInWithEmail = ({ view = SIGNIN_VIEWS.SIGN_IN, user, metadata, setUser }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [currentView, setCurrentView] = useState(view);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userSigningIn, setUserSigningIn] = useState(false);
  const [editable, setEditable] = useState(true);

  useEffect(() => {
    clearUserData();
  }, []);

  const clearUserData = async () => {
    await signUserOut();
    dispatch(logoutUserAction());
  };

  const onSignInPress = useCallback(async () => {
    setUserSigningIn(true);
    setEditable(false);
    const { status, msg, view: loginView, data } = await checkAndLogUserIn({ email, password });
    if (status === STATUS.SUCCESS) {
      if (loginView) {
        setEditable(true);

        setCurrentView(loginView);
      }
    } else {
      toast.show(msg);
    }
    setUserSigningIn(false);
  }, [email, password]);

  const onSignupPress = async ({
    displayName,
    phoneNumber,
    email,
    companyName,
    profession,
    referral,
    organizationId,
    userCity,
  }) => {
    const response = await createUser(
      getCreateUserPayload(user, {
        displayName,
        phoneNumber,
        companyName,
        signupReferralCode: referral,
        profession,
        userCity,
        location: getUserLocation(userCity, metadata),
        email,
        organizationId,
      })
    );
    console.log({ response });

    if (!_isEmpty(response)) {
      setUser(_get(response, 'data'));
      toast.show('User signup successful.');
    } else {
      toast.show('Error updating user details. please try again');
    }
  };

  const updateUserViewToSignupDetails = async () => {
    const isUserEmailVerified = await isEmailVerified();
    if (isUserEmailVerified) {
      setCurrentView('SIGNUP_DETAILS');
    } else {
      toast.show('Email not verified. Please click the link sent to your email');
    }
  };
  const showEnterEmailView = () => {
    setCurrentView(SIGNIN_VIEWS.SIGN_IN);
  };

  const showForgotPassword = () => {
    clearUserData();
    setCurrentView(SIGNIN_VIEWS.FORGOT_PASSWORD);
  };

  const signInView = () => (
    <View style={styles.containerPadding}>
      <Image source={IMAGES.GOFLOATERS_LOGO} style={styles.logoStyle} />
      <TextInput
        value={email}
        autoCapitalize="none"
        placeholder="Email"
        containerStyle={styles.baseMarginBottom}
        onChangeText={setEmail}
        editable={!userSigningIn}
      />
      <TextInput
        value={password}
        placeholder="Password"
        containerStyle={styles.baseMarginBottom}
        onChangeText={setPassword}
        secureTextEntry
        autoCapitalize="none"
        editable={!userSigningIn}
      />
      <Button
        textProps={{ variant: 'bold', size: 3 }}
        loading={userSigningIn}
        style={styles.buttonStyle}
        onPress={onSignInPress}>
        Sign In
      </Button>
      <Text
        size={3}
        color={COLORS.BUTTON_COLORS.SECONDARY_COLOR}
        style={styles.textLink}
        onPress={showForgotPassword}
        showEnterEmailView={showEnterEmailView}>
        Forgot Password?
      </Text>
    </View>
  );

  const signUpDetails = () => <SignupDetails email={email} onSignupPress={onSignupPress} />;

  const verifyEmailView = () => (
    <VerifyEmail email={email} onVerifyPress={updateUserViewToSignupDetails} onChangeEmail={showEnterEmailView} />
  );

  const forgotPasswordView = () => <ForgotPassword showEnterEmailView={showEnterEmailView} />;

  const currentSignupView = () => {
    if (currentView === SIGNIN_VIEWS.SIGN_IN) {
      return signInView();
    }
    if (currentView === SIGNIN_VIEWS.VERIFY_EMAIL) {
      return verifyEmailView();
    }
    if (currentView === SIGNIN_VIEWS.SIGNUP_DETAILS) {
      return signUpDetails();
    }
    if (currentView === SIGNIN_VIEWS.FORGOT_PASSWORD) {
      return forgotPasswordView();
    }
    return null;
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={{ flex: 1 }}>{currentSignupView()}</View>
      {currentView !== 'SIGNUP_DETAILS' ? <Wave /> : null}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
  },
  containerPadding: {
    paddingHorizontal: 48,
    paddingTop: 16,
  },
  logoStyle: {
    width: '100%',
    height: 80,
    marginTop: 64,
    marginBottom: 120,
  },
  baseMarginBottom: {
    marginBottom: 32,
  },
  buttonStyle: {
    marginHorizontal: 32,
  },
  textLink: {
    alignSelf: 'center',
    padding: 8,
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
  metadata: state.metadata.data,
});

export default connect(mapStateToProps, { setUser })(SignInWithEmail);
