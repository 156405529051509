import moment from 'moment-timezone';
import { View, StyleSheet, Image, Pressable } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import _noop from 'lodash/noop';
import { IMAGES } from '../../assets/images';
import { COLORS } from '../../constants/colors';
import { BOOKING_STATUS_LABEL_COLOR } from '../../constants/common';
import { getSpaceDetails } from '../../utils/common';
import Text from '../Text';
import React from 'react';

function SpaceCardSmallWithSelection({ space, style, showGoFLoatersName = true, onPress = _noop, selected }) {
  const {
    spaceaddress,
    startTime,
    status,
    gofloatersSpaceName,
    originalName,
    spaceDisplayName,
    spacePhoto,
    bookingkey,
  } = space;

  const onCardClick = () => {
    onPress(bookingkey);
  };

  return (
    <Pressable style={[styles.container, style]} onPress={onCardClick}>
      <Image source={spacePhoto ? { uri: spacePhoto } : IMAGES.ONBOARDING} style={styles.imageStyle} />
      <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 8, flex: 1 }}>
        <View style={styles.contentStyle}>
          <Text variant="bold" size={3}>
            {`${showGoFLoatersName ? `${gofloatersSpaceName} - ` : ''}${originalName}`}
          </Text>
          <Text variant="medium" size={4} color={COLORS.PRIMARY_COLOR}>
            {spaceDisplayName}
          </Text>
          <Text variant="bold" size={4} color={BOOKING_STATUS_LABEL_COLOR[status]}>
            {status}
          </Text>
          <Text variant="medium" size={4} color={COLORS.TEXT_COLOR_SECONDARY}>
            {getSpaceDetails(spaceaddress)}
          </Text>
          <Text variant="bold" size={3} color={COLORS.PRIMARY_COLOR}>
            {moment(startTime).calendar()}
          </Text>
        </View>
        {selected ? (
          <FontAwesome name="check-square-o" size={18} color={COLORS.PRIMARY_COLOR} style={styles.checkboxStyle} />
        ) : (
          <MaterialIcons name="check-box-outline-blank" size={18} color={COLORS.BLACK} style={styles.checkboxStyle} />
        )}
      </View>
    </Pressable>
  );
}
const styles = StyleSheet.create({
  container: {
    padding: 16,
    flexDirection: 'row',
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.19,
    shadowRadius: 5.62,
    elevation: 8,
    borderRadius: 8,
    marginHorizontal: 4,
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
    margin: 16,
  },
  imageStyle: {
    width: 96,
    height: 96,
    borderRadius: 12,
  },
  contentStyle: {
    flexDirection: 'column',
    flex: 1,
    // justifyContent: 'space-between',
    rowGap: 4,
    marginLeft: 16,
  },
  checkboxStyle: {
    marginTop: 16,
  },
});

export default React.memo(SpaceCardSmallWithSelection);
