import React from 'react';
import { Text as RNText, StyleSheet, View } from 'react-native';
import { COLORS } from '../constants/colors';

const styles = StyleSheet.create({
  level0: {
    fontSize: 24,
    lineHeight: 30,
  },
  level9: {
    fontSize: 22,
    letterSpacing: -0.44,
  },
  normal: {
    fontSize: 24,
  },
  level1: {
    fontSize: 18,
    lineHeight: 22.68,
  },
  level2: {
    fontSize: 16,
    lineHeight: 20.16,
  },
  level3: {
    fontSize: 14,
    lineHeight: 17.64,
  },
  level4: {
    fontSize: 12,
    lineHeight: 15.12,
  },
  bold: {
    fontFamily: 'PlusJakartaSans-Bold',
  },
  semiBold: {
    fontFamily: 'PlusJakartaSans-SemiBold',
  },
  medium: {
    fontFamily: 'PlusJakartaSans-Medium',
  },
  regular: {
    fontFamily: 'PlusJakartaSans-Regular',
  },
  default: {
    color: COLORS.TEXT_COLOR_PRIMARY,
    margin: 0,
    padding: 0,
  },
});

function Text({ children, size = 1, variant = 'regular', style, color = COLORS.TEXT_COLOR_PRIMARY, ...rest }) {
  return (
    <RNText
      style={[styles[`level${size}`], styles[variant], styles.default, { color }, style]}
      numberOfLines={2}
      {...rest}>
      {children}
    </RNText>
  );
}

export default Text;
