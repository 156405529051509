export const ROUTES = {
  SPLASH: {
    name: 'splash',
    label: 'Splash',
  },
  SIGNUP: {
    name: 'signup',
    label: 'Signup',
  },
  LOGIN: {
    name: 'login',
    label: 'Login',
  },
  OTP: {
    name: 'otp',
    label: 'Otp',
  },
  FORGOT_PASSWORD: {
    name: 'forgotPassword',
    label: 'Forgot Password',
  },
  SIGNIN_WITH_EMAIL: {
    name: 'signinWithEmail',
    label: 'Sign In with Email',
  },

  TAB_NAVIGATOR: {
    name: 'tabNavigator',
    label: 'tabNavigator',
  },
  HOME: {
    name: 'home',
    label: 'Home',
  },
  SEARCH: {
    name: 'search',
    label: 'Search',
  },
  BOOKINGS: {
    name: 'bookings',
    label: 'Bookings',
  },
  BOOKINGS_CONFIRMATION: {
    name: 'bookingsConfirmation',
    label: 'Bookings Confirmation',
  },
  BOOKING_DETAILS: {
    name: 'bookingDetails',
    label: 'Bookings Details',
  },
  PROFILE: {
    name: 'profile',
    label: 'Profile',
  },
  TEAM_VIEW: {
    name: 'teamView',
    label: 'Team',
  },
  SPACE_DETAILS: {
    name: 'spaceDetails',
    label: 'Space Details',
  },
  SPACE_BOOKINGS: {
    name: 'spaceBookings',
    label: 'Space Bookings',
  },
  EDIT_CITY: {
    name: 'editCity',
    label: 'Edit City',
  },
  FAQ: {
    name: 'faqs',
    label: 'Frequently Asked Questions',
  },
  EDIT_PROFILE: {
    name: 'editProfile',
    label: 'Edit Profile',
  },
  NOTIFICATION: {
    name: 'notifications',
    label: 'Notifications',
  },
  ANNOUNCEMENTS: {
    name: 'announcement',
    label: 'Announcements',
  },
  MY_ORGANISATION: {
    name: 'myOrganisation',
    label: 'My Organisation',
  },
  VIEW_IMAGE: {
    name: 'viewImage',
    label: 'View Image',
  },
  VIEW_VIDEO: {
    name: 'viewVideo',
    label: 'View Video',
  },
  SOS: {
    name: 'sos',
    label: 'SOS',
  },
  ENGAGE: {
    name: 'engage',
    label: 'Engage',
  },
  EVENTS: {
    name: 'events',
    label: 'Events',
  },
  EVENT_DETAILS: {
    name: 'eventDetails',
    label: 'Event Details',
  },
  FAVORITE_COWORKERS: {
    name: 'favoriteCoworkers',
    label: 'Favorite Coworkers',
  },
  SPACE_CHECKIN: {
    name: 'space-checkin',
    label: 'Space Checkin',
  },
  // SEARCH: {
  //   name: 'search',
  //   label: 'Search',
  // },
  // DISCOVER: {
  //   name: 'discover',
  //   label: 'Discover',
  // },
  // BOOKINGS: {
  //   name: 'Bookings',
  //   label: 'Bookings',
  // },
  // BOOKINGS_LIST: {
  //   name: 'bookingsList',
  //   label: 'Bookings List',
  // },
  // BOOKINGS_DETAILS: {
  //   name: 'bookingsDetails',
  //   label: 'Bookings Details',
  // },
  // BOOKINGS_CONFIRMATION: {
  //   name: 'bookingsConfirmation',
  //   label: 'Bookings Confirmation',
  // },
  // ACCOUNT: {
  //   name: 'account',
  //   label: 'Account',
  // },
  // SPACE_DETAILS: {
  //   name: 'spaceDetails',
  //   label: 'Space Details',
  // },
  // SEARCH_FILTERS: {
  //   name: 'searchFilters',
  //   label: 'Search Filters',
  // },
};
