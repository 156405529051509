import _get from 'lodash/get';
import _concat from 'lodash/concat';
import _filter from 'lodash/filter';
import { APP_VERSION, SHOW_WHATS_NEW } from '../constants/common';

export const getOperationalCities = metadata => {
  const operationalCities = _get(metadata, 'operationalCities.India');
  return _filter(operationalCities, city => _get(city, 'availableForCustomer'));
};

export const getMicroMarkets = metadata => {
  const microMarkets = _get(metadata, 'microMarkets.India');
  return microMarkets;
};

export const getUserFavoritesData = favoritesSpaces => {
  const userFavoriteSpaces = _get(favoritesSpaces, 'userFavoriteSpaces', []);
  const teamFavoriteSpaces = _get(favoritesSpaces, 'teamFavoriteSpaces', []);
  return _concat(userFavoriteSpaces, teamFavoriteSpaces);
};

export const getUserCity = user => _get(user, 'location.city');

export const showWhatsNewModal = (whatsNewVisisted = {}) => {
  if (whatsNewVisisted[APP_VERSION] && SHOW_WHATS_NEW) return false;
  return true;
};
