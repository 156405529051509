import { combineReducers } from '@reduxjs/toolkit';

import user from './user';
import spaces from './spaces';
import metadata from './metadata';

const rootReducer = combineReducers({
  user,
  spaces,
  metadata,
});

export default rootReducer;
