import React, { useCallback, useEffect, useState } from 'react';
import { View, StyleSheet, ActivityIndicator, SectionList } from 'react-native';
import { connect } from 'react-redux';
import { Entypo } from '@expo/vector-icons';
import { useToast } from 'react-native-toast-notifications';
import { useFocusEffect } from '@react-navigation/native';
import _get from 'lodash/get';

import Header from '../../components/Header';
import { COLORS } from '../../constants/colors';
import { SPACING } from '../../constants/common';
import { createEvent, getEvents } from '../../api/common';
import { auth } from '../../../firebaseConfig';
import Separator from '../../components/Separator';
import Text from '../../components/Text';
import { getEventsByCity } from '../../utils/common';
import { SpaceEventCard } from '../../components/cards';
import CreateEvent from '../../components/createEvent';

const Events = ({ user, navigation }) => {
  const [gettingEvents, setGettingEvents] = useState(true);
  const [events, setEvents] = useState([]);
  const toast = useToast();
  const uid = _get(user, 'uid');
  const { currentUser = null } = auth;

  const fetchEvents = useCallback(async () => {
    setGettingEvents(true);
    try {
      const eventsResponse = await getEvents(uid);
      const eventsByCity = getEventsByCity(eventsResponse);
      setEvents(eventsByCity);
    } catch (error) {
      toast.show('Error getting favorite coworkers. Please try again.');
    } finally {
      setGettingEvents(false);
    }
  }, [toast, uid]);

  useEffect(() => {
    if (uid && currentUser?.getIdToken()) fetchEvents();
  }, [uid, currentUser, fetchEvents]);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (uid && currentUser?.getIdToken()) fetchEvents();
      })();
    }, [currentUser, uid, fetchEvents])
  );

  const onCreateEvent = async payload => {
    try {
      await createEvent(payload);
      fetchEvents();
    } catch {
      throw new Error();
    }
  };

  const addCreateEventButton = () => (
    <CreateEvent
      user={user}
      onCreateEvent={onCreateEvent}
      customView={<Entypo name="plus" size={24} color={COLORS.PRIMARY_COLOR} />}
    />
  );

  const renderItem = useCallback(
    ({ item: event }) => (
      <SpaceEventCard event={event} navigation={navigation} user={user} refreshEvents={fetchEvents} />
    ),
    [user, navigation, fetchEvents]
  );

  const renderSectionHeader = useCallback(
    ({ section: { title } }) => (
      <Text variant="semiBold" size={2}>
        {title}
      </Text>
    ),
    []
  );

  const separator = useCallback(() => <Separator color={COLORS.PRIMARY_BACKGROUND} gap={8} />, []);
  const ListEmptyComponent = useCallback(
    () => (
      <Text variant="bold" size={4} style={{ alignSelf: 'center' }}>
        No Events
      </Text>
    ),
    []
  );

  return (
    <>
      <Header title="Events" rightBarButtons={[addCreateEventButton()]} />
      {gettingEvents ? (
        <View style={[styles.container, styles.containerPadding, styles.centerView]}>
          <ActivityIndicator />
        </View>
      ) : (
        <View style={[styles.container, styles.containerPadding]}>
          <SectionList
            sections={events}
            keyExtractor={(item, index) => item + index}
            renderItem={renderItem}
            renderSectionHeader={renderSectionHeader}
            SectionSeparatorComponent={separator}
            ItemSeparatorComponent={separator}
            showsVerticalScrollIndicator={false}
            ListEmptyComponent={ListEmptyComponent}
          />
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
  },
  containerPadding: {
    paddingHorizontal: SPACING,
  },
  centerView: {
    flex: 1,
    alignItems: 'center',
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(Events);
