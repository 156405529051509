import { useMemo, useState, useCallback } from 'react';
import { View, StyleSheet, TouchableOpacity, FlatList, ScrollView } from 'react-native';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { Fontisto } from '@expo/vector-icons';
import { COLORS } from '../../constants/colors';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Text from '../../components/Text';
import { ROUTES } from '../../constants/routes';

const QUESTION_TYPES = [
  { label: 'General', value: 'general' },
  { label: 'Pricing', value: 'pricing' },
  { label: 'Booking', value: 'booking' },
  { label: 'Payment', value: 'payment' },
];

function FAQs({ metadata, navigation }) {
  const [questionType, setQuestionType] = useState('general');
  const lists = useMemo(() => metadata.faqs, [metadata]);
  const questionKeys = useMemo(
    () => ({
      booking: _get(lists, 'booking'),
      general: _get(lists, 'general'),
      pricing: _get(lists, 'pricing'),
      payment: _get(lists, 'payments'),
    }),
    [lists]
  );

  const renderQuestionTypes = useMemo(
    () => (
      <ScrollView horizontal contentContainerStyle={styles.questionTypesContainer}>
        {_map(QUESTION_TYPES, type => {
          const buttonStyle = [styles.questionTypeStyle];
          const textStyle = [{ color: COLORS.WHITE }];
          if (_get(type, 'value') !== questionType) {
            buttonStyle.push(styles.inactiveStyle);
            textStyle.push({ color: COLORS.TEXT_COLOR_SECONDARY });
          }
          return (
            <Button
              onPress={() => setQuestionType(_get(type, 'value'))}
              style={buttonStyle}
              variant="small"
              textProps={{
                size: 3,
                style: textStyle,
              }}
              key={_get(type, 'value')}>
              {_get(type, 'label')}
            </Button>
          );
        })}
      </ScrollView>
    ),
    [questionType]
  );

  const [activeIndex, setActiveIndex] = useState(-1);

  const renderHeader = ({ item, index }) => (
    <TouchableOpacity
      onPress={() => {
        if (activeIndex !== index) {
          setActiveIndex(index);
        } else {
          setActiveIndex(-1);
        }
      }}
      style={[{ flexDirection: 'row', width: '100%' }]}>
      <Text size="1" variant="bold" numberOfLines={10} style={{ flex: 1, flexWrap: 'wrap' }}>
        {item.name}
      </Text>
      <View style={{ alignSelf: 'center', paddingLeft: 10 }}>
        {activeIndex === index ? (
          <Fontisto name="angle-down" size={15} color={COLORS.TEXT_COLOR_PRIMARY} />
        ) : (
          <Fontisto name="angle-right" size={15} color={COLORS.TEXT_COLOR_PRIMARY} />
        )}
      </View>
    </TouchableOpacity>
  );

  const renderContent = ({ item }) => (
    <View style={{ paddingVertical: 10, paddingRight: 25 }}>
      <Text size="2" variant="regular" numberOfLines={20} style={{ textAlign: 'left' }}>
        {item.description}
      </Text>
    </View>
  );

  const renderFAQItem = useCallback(
    (item, index) => (
      <View style={styles.container2}>
        <View style={[styles.container3]}>
          {renderHeader(item, index)}
          {activeIndex === item.index && renderContent(item)}
        </View>
      </View>
    ),
    [activeIndex]
  );

  const onBackPress = () => {
    navigation.navigate(ROUTES.PROFILE.name);
  };

  return (
    <View style={styles.container}>
      <Header title="Frequently Asked Questions" onBackPress={onBackPress} />
      <FlatList
        data={questionKeys[questionType]}
        renderItem={renderFAQItem}
        keyExtractor={item => item.name}
        stickyHeaderIndices={[0]}
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={renderQuestionTypes}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
  },
  questionTypeStyle: {
    paddingHorizontal: 12,
  },
  inactiveStyle: {
    backgroundColor: COLORS.BUTTON_COLORS.TERTIARY_COLOR,
    color: COLORS.TEXT_COLOR_SECONDARY,
  },
  questionTypesContainer: {
    flexDirection: 'row',
    columnGap: 8,
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
    marginHorizontal: 20,
    paddingBottom: 10,
  },
  container2: {
    flex: 1,
    flexWrap: 'wrap',
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
    marginHorizontal: 20,
    paddingBottom: 15,
  },
  container3: {
    backgroundColor: COLORS.BUTTON_COLORS.TERTIARY_COLOR,
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderRadius: 10,
    flex: 1,
    elevation: 8,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 4,
    shadowOpacity: 0.2,
  },
});

const mapStateToProps = state => ({
  metadata: state.metadata.data,
});

export default connect(mapStateToProps, {})(FAQs);
