import { View, Platform, StyleSheet } from 'react-native';
import { useLinkProps } from '@react-navigation/native';
import Button from './Button';
import Text from './Text';
import { COLORS } from '../constants/colors';

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.PRIMARY_COLOR,
    flexDirection: 'row',
    alignItems: 'center',
  },
  small: {
    paddingVertical: 8,
    borderRadius: 8,
  },
  medium: {
    paddingVertical: 10,
    borderRadius: 8,
  },
  large: {
    paddingVertical: 16,
    borderRadius: 8,
  },
  bordered: {
    borderWidth: 1,
    borderColor: '#2798B5',
    backgroundColor: 'transparent',
    borderRadius: 8,
  },
  borderedText: {
    color: '#2798B5',
  },

  textStyle: {
    color: 'white',
  },
  iconContainerStyle: {
    marginHorizontal: 16,
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  iconButtonStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const LinkButton = ({
  children,
  to,
  variant = 'large',
  action,
  textProps,
  style,
  iconButtonStyle = styles.iconButtonStyle,
  leftIcon,
  bordered,
  ...rest
}) => {
  const { onPress, ...props } = useLinkProps({ to, action });
  if (Platform.OS === 'web') {
    // It's important to use a `View` or `Text` on web instead of `TouchableX`
    // Otherwise React Native for Web omits the `onClick` prop that's passed
    // You'll also need to pass `onPress` as `onClick` to the `View`
    // You can add hover effects using `onMouseEnter` and `onMouseLeave`
    const containerStyle = [styles.container, styles[variant], style];
    if (!leftIcon) containerStyle.push(styles.justifyContentCenter);
    const textStyle = [styles.textStyle];
    if (bordered) {
      containerStyle.push(styles.bordered);
      textStyle.push(styles.borderedText);
    }
    return (
      <View style={containerStyle} onClick={onPress} {...props} {...rest}>
        <View style={iconButtonStyle}>
          {leftIcon ? <View style={styles.iconContainerStyle}>{leftIcon}</View> : null}
          <Text style={[textStyle, textProps?.style ?? {}]} {...textProps}>
            {children}
          </Text>
        </View>
      </View>
    );
  }
  return (
    <Button
      onPress={onPress}
      {...{ variant, action, textProps, style, leftIcon, bordered }}
      iconButtonStyle={iconButtonStyle}
      {...props}
      {...rest}>
      {children}
    </Button>
  );
};

export default LinkButton;
