import React, { useCallback, useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, StatusBar, View, Platform } from 'react-native';
import axios from 'axios';
import _size from 'lodash/size';
import _concat from 'lodash/concat';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';

import SearchBarWithAutocomplete from './SearchBarWithAutocomplete';
import { useDebounce } from './useDebounce';
import { getMicroMarketsFromSearch, getSearchresultsInCity } from '../../utils/common';
import { SEARCH_TYPE } from '../../constants/common';
import { getMicroMarkets } from '../../selectors/common';

const GOOGLE_PACES_API_BASE_URL = 'https://maps.googleapis.com/maps/api/place';

/**
 * Prediction's type returned from Google Places Autocomplete API
 * https://developers.google.com/places/web-service/autocomplete#place_autocomplete_results
 */
// export type PredictionType = {
//   description: string
//   place_id: string
//   reference: string
//   matched_substrings: any[]
//   tructured_formatting: Object
//   terms: Object[]
//   types: string[]
// }

const KEY = 'AIzaSyCFuNNxPNwQ6M-zxTlElwEEdCsxJ7LMC9I';
const App = ({ renderLeftButton, renderRightButton, searchCity, onLocationSelect, searchResults, metadata }) => {
  const [search, setSearch] = useState({ term: searchCity, fetchPredictions: false });
  const [showPredictions, setShowPredictions] = useState(false);
  const [predictions, setPredictions] = useState([]);

  const { container, body } = styles;

  useEffect(() => {
    setSearch({ term: searchCity, fetchPredictions: false });
  }, [searchCity]);

  /**
   * Grab predictions on entering text
   *    by sending reqyest to Google Places API.
   * API details: https://developers.google.com/maps/documentation/places/web-service/autocomplete
   */
  const onChangeText = async (term, info) => {
    if (term.trim() === '') {
      setShowPredictions(false);
      return;
    }
    // if (!search.fetchPredictions) return;

    const apiUrl = `${GOOGLE_PACES_API_BASE_URL}/autocomplete/json?components=country:IN&key=${KEY}&input=${term}`;
    try {
      const result = await axios.request({
        method: 'post',
        url: apiUrl,
      });
      if (result) {
        const {
          data: { predictions },
        } = result;
        const results = _concat(
          getMicroMarketsFromSearch(getMicroMarkets(info), term),
          getSearchresultsInCity(searchResults, term),
          predictions
        );

        if (_size(results) > 0) {
          setPredictions(results);
          setShowPredictions(true);
        } else {
          setShowPredictions(false);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeTextWeb = (term, info) => {
    const displaySuggestions = function (results, status) {
      const predictionResults = _concat(
        getMicroMarketsFromSearch(getMicroMarkets(info), term),
        getSearchresultsInCity(searchResults, term),
        results
      );
      // console.log({
      //   results,
      //   status,
      //   getMicroMarkets: getMicroMarketsFromSearch(getMicroMarkets(info), term),
      //   micro: getMicroMarkets(info),
      // });

      if (_size(results) > 0) {
        setPredictions(predictionResults);
        setShowPredictions(true);
      } else {
        setShowPredictions(false);
      }
    };

    const service = new window.google.maps.places.AutocompleteService();

    service.getPlacePredictions({ input: term, componentRestrictions: { country: 'in' } }, displaySuggestions);
  };

  const getResults = (term, info) => {
    // console.log({ term });
    if (Platform.OS === 'web') {
      onChangeTextWeb(term, info);
    } else {
      onChangeText(term, info);
    }
  };
  // useDebounce(getResults, 500, [search.term]);

  const debouncedFunc = useCallback(_debounce(getResults, 500), []);

  /**
   * Grab lattitude and longitude on prediction tapped
   *    by sending another reqyest using the place id.
   * You can check what kind of information you can get at:
   *    https://developers.google.com/maps/documentation/places/web-service/details#PlaceDetailsRequests
   */

  const onPredictionTappedWeb = item => {
    const { place_id: placeId, description, searchType } = item;
    // console.log({ item });
    if (searchType === SEARCH_TYPE.GOFLOATERS || searchType === SEARCH_TYPE.MICRO_MARKET) {
      setShowPredictions(false);
      onLocationSelect(item);
      return;
    }
    const map = new window.google.maps.Map(document.createElement('div'));
    const service = new window.google.maps.places.PlacesService(map);

    service.getDetails({ placeId }, location => {
      // console.log({ location: _get(location, 'geometry.location') });
      onLocationSelect({
        ...location,
        geometry: {
          location: {
            lat: _get(location, 'geometry.location').lat(),
            lng: _get(location, 'geometry.location').lng(),
          },
        },
        searchType: SEARCH_TYPE.GOOGLE,
        city: _get(location, 'vicinity') || '',
        address: _get(location, 'vicinity') || '',
      });
      // console.log({ description });
      setShowPredictions(false);
      setSearch({ term: description, fetchPredictions: false });
    });
  };
  const onPredictionTapped = async item => {
    const { place_id: placeId, description, searchType } = item;
    if (searchType === SEARCH_TYPE.GOFLOATERS || searchType === SEARCH_TYPE.MICRO_MARKET) {
      setShowPredictions(false);
      onLocationSelect(item);
      return;
    }
    const apiUrl = `${GOOGLE_PACES_API_BASE_URL}/details/json?key=${KEY}&place_id=${placeId}`;
    try {
      const result = await axios.request({
        method: 'post',
        url: apiUrl,
      });
      if (result) {
        const {
          data: { result: location },
        } = result;
        setShowPredictions(false);
        // console.log({ location });
        onLocationSelect({
          ...location,
          searchType: SEARCH_TYPE.GOOGLE,
          city: _get(location, 'vicinity') || '',
          address: _get(location, 'vicinity') || '',
        });
        setSearch({ term: description, fetchPredictions: false });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const predictionTapped = useCallback(item => {
    if (Platform.OS === 'web') onPredictionTappedWeb(item);
    else onPredictionTapped(item);
  }, []);

  return (
    <SearchBarWithAutocomplete
      value={search.term}
      onChangeText={text => {
        setSearch({ term: text, fetchPredictions: true });
        debouncedFunc(text, metadata);
      }}
      showPredictions={showPredictions}
      predictions={predictions}
      onPredictionTapped={predictionTapped}
      renderLeftButton={renderLeftButton}
      renderRightButton={renderRightButton}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {},
});

export default App;
