import { StyleSheet, Pressable } from 'react-native';
import { Feather } from '@expo/vector-icons';
import IconLabelCard from './IconLabelCard';
import { ROUTES } from '../constants/routes';

const VideoPlayer = ({ label = 'Virtual Tour', source, navigation }) => {
  const onVideoPlay = () => {
    navigation.navigate(ROUTES.VIEW_VIDEO.name, { videoUrl: source });
  };

  return (
    <Pressable style={styles.iconLabelStyle} onPress={onVideoPlay}>
      <IconLabelCard
        label={label}
        iconFamily={Feather}
        iconProps={{ name: 'video', size: 16 }}
        containerStyle={styles.iconLabelStyle}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  iconLabelStyle: {
    flex: 1,
  },
  video: {
    height: 0,
    width: 0,
  },
});

export default VideoPlayer;
