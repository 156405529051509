import { View, StyleSheet, Pressable } from 'react-native';
import _map from 'lodash/map';
import _noop from 'lodash/noop';
import { AntDesign } from '@expo/vector-icons';
import { COLORS } from '../../constants/colors';

const Ratings = ({ total, rating = 1, onSelectRating = _noop }) => (
  <View style={styles.container}>
    {_map(Array(total), (_, index) => (
      <Pressable onPress={() => onSelectRating(index + 1 >= 1 ? index + 1 : rating)} key={index}>
        <AntDesign
          name={index < rating ? 'star' : 'staro'}
          size={24}
          color={index < rating ? COLORS.STAR_RATING : COLORS.BLACK}
        />
      </Pressable>
    ))}
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    columnGap: 8,
  },
});

export default Ratings;
