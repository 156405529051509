import { useState } from 'react';
import { View, StyleSheet, Image, Dimensions } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { CodeField, Cursor, useBlurOnFulfill, useClearByFocusCell } from 'react-native-confirmation-code-field';
import { IMAGES } from '../../assets/images';
import Text from '../../components/Text';
import { COLORS } from '../../constants/colors';
import Wave from '../../components/Wave';
import Button from '../../components/Button';

const CELL_COUNT = 4;
const { width } = Dimensions.get('window');

const VerifyEmail = ({ email, onVerifyPress, onChangeEmail }) => {
  const [verifyingEmail, setVerfyingEmail] = useState(false);

  const onVerify = async () => {
    setVerfyingEmail(true);
    await onVerifyPress();
    setVerfyingEmail(false);
  };

  return (
    <View style={[styles.containerPadding]}>
      <Image source={IMAGES.GOFLOATERS_LOGO} style={styles.logoStyle} />
      <View>
        <Text size={2} variant="medium">
          Verification Link
        </Text>
        <Text size={3} numberOfLines={0} color={COLORS.TEXT_COLOR_SECONDARY} style={{ marginTop: 8, textAlign:'left' }}>
          We have sent a verification link to your email. Please click that link and come back here to click the button below.
        </Text>
        <View style={styles.phoneNumberText}>
          <Text size={3} variant="medium" >
            {email}
          </Text>
        </View>
      </View>
      <Button
        textProps={{ variant: 'bold', size: 3 }}
        style={styles.buttonStyle}
        onPress={onVerify}
        loading={verifyingEmail}>
        Email Verified
      </Button>
      <Text
        size={3}
        color={COLORS.BUTTON_COLORS.SECONDARY_COLOR}
        style={styles.changeEmailStyle}
        onPress={onChangeEmail}>
        Change email?
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
  },
  containerPadding: {
    paddingHorizontal: 48,
    paddingTop: 16,
  },
  logoStyle: {
    width: '100%',
    height: 84,
    marginTop: 64,
    marginBottom: 120,
  },
  phoneNumberText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonStyle: {
    marginTop: 32,
  },
  changeEmailStyle: {
    alignSelf: 'center',
    padding: 8,
  },
});

export default VerifyEmail;
