import { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, Platform, ScrollView, Pressable } from 'react-native';
import MapView, { Callout, Marker } from 'react-native-maps';
import { Fontisto } from '@expo/vector-icons';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';

import { getLatLngFromString, getWhereMyOrganizationIsToday } from '../../utils/common';
import Text from '../Text';

const Map = ({ members, initial, showMemberNames, onMarkerPress = _noop }) => {
  const [markers, setMarkers] = useState([]);
  const mapRef = useRef(null);

  useEffect(() => {
    setMarkers(getWhereMyOrganizationIsToday(members));
  }, [members]);

  useEffect(() => {
    const markerCoords = _map(markers, marker => getLatLngFromString(_get(marker, 'spacecoords')));
    if (!_isEmpty(markerCoords)) {
      setTimeout(() => {
        mapRef?.current?.fitToCoordinates(
          _map(markers, marker => getLatLngFromString(_get(marker, 'spacecoords'))),
          {
            edgePadding: {
              top: 120,
              right: 120,
              bottom: 120,
              left: 120,
            },
          }
        );
      }, 100);
    } else {
      mapRef.current.fitToCoordinates(
        [
          { latitude: 33.2778, longitude: 75.3412 },
          { latitude: 8.0844, longitude: 77.5495 },
        ],
        {
          edgePadding: {
            top: 20,
            right: 20,
            bottom: Platform.OS === 'web' ? 20 : 120,
            left: 20,
          },
        }
      );
    }
  }, [markers]);

  const onPress = id => () => {
    onMarkerPress(_find(markers, { id }));
  };

  // console.log({ markers });

  return (
    <MapView
      provider="google"
      style={styles.map}
      minZoomLevel={0}
      zoomControlEnabled={false}
      ref={mapRef}
      onPress={e => {
        e.stopPropagation();
        // console.log('press', { e });
      }}
      options={{
        disableDefaultUI: true,
      }}
      googleMapsApiKey="AIzaSyBFzQyh0__KlIMKsmGqMIsnsolOeXTYoUs"
      initialRegion={{
        latitude: 22.42,
        longitude: 75.53,
        latitudeDelta: 0,
        longitudeDelta: 0,
      }}>
      {_map(markers, (marker, index) => (
        <Marker
          coordinate={getLatLngFromString(_get(marker, 'spacecoords'))}
          onPress={onPress(_get(marker, 'id'))}
          key={index}>
          <View style={{ position: 'relative', justifyContent: 'center', alignItems: 'center' }}>
            {/* <FontAwesome5 name="map-marker" size={24} color="#E04A32" /> */}
            <Fontisto name="map-marker" size={35} color="#E04A32" />
            <View style={{ position: 'absolute', top: 4 }}>
              <Text size={2} variant="bold" color="white">
                {_get(marker, 'count')}
              </Text>
            </View>
          </View>
          {/* <Callout style={{ width: 300 }}>
            <View style={{ paddingtop: 24 }}>
              <View style={{ flexDirection: 'row', alignItems: 'flex-start', flex: 1 }}>
                <Text size={3} variant="bold" numberOfLines={0} style={{ minWidth: 100 }}>
                  Space Name:
                </Text>
                <View style={{ flex: 1, alignItems: 'flex-start', flexWrap: 'wrap' }}>
                  <Text size={3} numberOfLines={0} style={{ flexWrap: 'wrap' }}>
                    {_get(marker, 'originalName')}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row', alignItems: 'flex-start', flex: 1 }}>
                <Text size={3} variant="bold" numberOfLines={0} style={{ minWidth: 100 }}>
                  Locality:
                </Text>
                <View style={{ flex: 1, alignItems: 'flex-start', flexWrap: 'wrap' }}>
                  <Text size={3} numberOfLines={0}>
                    {_get(marker, 'spaceLocality')}
                  </Text>
                </View>
              </View>
              {showMemberNames ? (
                <View style={{ flexDirection: 'row', alignItems: 'flex-start', flex: 1 }}>
                  <Text size={3} variant="bold" numberOfLines={0} style={{ minWidth: 100 }}>
                    Members:
                  </Text>
                  <View style={{ flex: 1, alignItems: 'flex-start', flexWrap: 'wrap' }}>
                    <Text size={3} numberOfLines={0} style={{}}>
                      {_get(marker, 'members')}
                    </Text>
                  </View>
                </View>
              ) : null}
            </View>
          </Callout> */}
        </Marker>
      ))}
    </MapView>
  );
};

const styles = StyleSheet.create({
  map: {
    width: '100%',
    height: '100%',
  },
});

export default Map;
