import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  View,
  StyleSheet,
  ScrollView,
  ImageBackground,
  Dimensions,
  ActivityIndicator,
  Pressable,
  FlatList,
} from 'react-native';
import { connect } from 'react-redux';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useToast } from 'react-native-toast-notifications';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
  Easing,
  useDerivedValue,
  withSpring,
} from 'react-native-reanimated';

import _find from 'lodash/find';
import _values from 'lodash/values';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _chunk from 'lodash/chunk';
import _slice from 'lodash/slice';
import _size from 'lodash/size';
import _filter from 'lodash/filter';

import moment from 'moment-timezone';
import { IMAGES } from '../../assets/images';
import IconLabelCard from '../../components/IconLabelCard';
import Text from '../../components/Text';
import { COLORS } from '../../constants/colors';
import { AMENITIES_VS_IMAGE, SPACING, STATUS, WEEKDAYS, WEEKEND,MAXDEVICEWIDTH } from '../../constants/common';
import Button from '../../components/Button';
import Header from '../../components/Header';
import LinkButton from '../../components/LinkButton';
import { ROUTES } from '../../constants/routes';
import { approveDisapproveSpace, getSpaceDetails } from '../../api/common';
import {
  getFullAddress,
  getLandmark,
  getOrgApproveDisapprovePayload,
  getSpaceAvgRating,
  getSpacePrice,
  isSpacefavorite,
} from '../../utils/common';
import SpaceQuickActions from '../../components/SpaceQuickActions';
import { addOrRemoveSpaceToFavorites } from '../../utils/firebaseUtils';
import { setReloadFavorites as setReloadFavoritesAction } from '../../reducers/user';
import Carousel from '../../components/carousel/CarouselFlatlist';
import Separator from '../../components/Separator';
import { getShowRates } from '../../selectors/organization';
import { CollapsableContainer } from '../../components/CollapsibleContainer';
import { auth } from '../../../firebaseConfig';

const { width } = Dimensions.get('window');
const IMAGE_WIDTH = (width > MAXDEVICEWIDTH ? MAXDEVICEWIDTH:width) - 2 * SPACING;
// console.log("IMAGE_WIDTH",IMAGE_WIDTH);
// const IMAGE_WIDTH = width - 2 * SPACING;

function SpaceDetails({ route, user, setReloadFavorites, spaces, showRates, navigation }) {
  // console.log({ spaces });
  const [showAllAmenties, setShowAllAmenties] = useState(false);
  const [loading, setLoading] = useState(true);
  const [spaceDetails, setSpaceDetails] = useState(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const toast = useToast();
  const sharedValue = useSharedValue(0);
  const [amenitiesHeight, setAmenitiesHeight] = useState(0);
  const [approveDisapproveInProgress, setApproveDisapproveInProgress] = useState(false);

  const open = useSharedValue(false);
  const progress = useDerivedValue(() => (open.value ? withSpring(1) : withTiming(0)));
  const height = useSharedValue(0);
  const [expanded, setExpanded] = useState(false);

  const style = useAnimatedStyle(
    () => ({
      height: interpolate(progress.value, [0, 1], [0, height.value]),
      opacity: progress.value === 0 ? 0 : 1,
    }),
    [progress, height]
  );

  const amenitiesSectionHeight = useAnimatedStyle(() => ({
    height: interpolate(sharedValue.value, [0, 1], [0, amenitiesHeight]),
  }));

  const onViewableItemsChanged = useCallback(({ viewableItems, changed }) => {
    // console.log({ viewableItems, changed });
    if (changed && changed.length > 0) {
      // setVisibleItemIndex(changed[0].index);
      setCurrentSlideIndex(changed[0].index);
    }
  });
  const viewabilityConfig = useRef({ viewAreaCoveragePercentThreshold: 50 }).current;
  const viewabilityConfigCallbackPairs = useRef([{ viewabilityConfig, onViewableItemsChanged }]);
  const reviewsPositon = useRef(0);
  const scrollViewRef = useRef(null);

  const spacePricing = useMemo(() => getSpacePrice(spaceDetails), [spaceDetails]);
  const { spaceId } = route.params;
  const uid = _get(user, 'uid');
  const isOrganizationAdmin = _get(user, 'isOrganizationAdmin');
  useEffect(() => {
    async function fetchSpaceDetails() {
      setLoading(true);
      const response = await getSpaceDetails(spaceId, uid);
      setSpaceDetails(response);
      setLoading(false);
    }
    const space = _find(spaces, spaceInfo => _get(spaceInfo, 'spaceId') === spaceId);
    // console.log({ space });
    if (space) {
      setSpaceDetails(space);
      setLoading(false);
    } else if (uid && auth?.currentUser?.getIdToken()) fetchSpaceDetails();
  }, [uid, auth?.currentUser, spaceId]);

  const toggleShowAllAmneties = () => {
    open.value = !open.value;
    // setExpanded(!expanded);
    setShowAllAmenties(!showAllAmenties);
  };

  const {
    originalName,
    photos,
    priceperday,
    facilitiesList,
    address,
    exclusiveOffer,
    gofloatersSpaceName,
    spaceRating,
    isSpaceApproved,
  } = useMemo(
    () => ({
      originalName: _get(spaceDetails, 'originalName', ''),
      photos: _get(spaceDetails, 'photos', []),
      priceperday: _get(spaceDetails, 'priceperday', ''),
      facilitiesList: _get(spaceDetails, 'facilitiesList', []),
      address: _get(spaceDetails, 'address', {}),
      exclusiveOffer: _get(spaceDetails, 'exclusiveOffer', ''),
      spaceRating: getSpaceAvgRating(_get(spaceDetails, 'feedback')),
      gofloatersSpaceName: _get(spaceDetails, 'gofloatersSpaceName', ''),
      isSpaceApproved: _get(spaceDetails, 'isSpaceApprovedForOrg', false),
    }),
    [spaceDetails]
  );

  const getRemarks = useCallback(() => {
    if (exclusiveOffer) {
      return (
        <View style={styles.baseMarginTop}>
          <Text size={3} variant="bold" style={{ textAlign: 'left' }} >
            Remarks
          </Text>
          <View style={styles.subMarginTop}>
            <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY} style={{ textAlign: 'left' }} >
              {exclusiveOffer}
            </Text>
          </View>
        </View>
      );
    }
    return null;
  }, [exclusiveOffer]);

  const spaceTimings = useMemo(
    () => (
      <View style={styles.baseMarginTop}>
        <Text size={3} variant="bold" style={{ textAlign: 'left' }}  >
          Timings
        </Text>
        <View style={styles.subMarginTop}>
          <Text size={4} variant="semiBold" style={{ textAlign: 'left' }}  >
            Weekdays
          </Text>
          <View style={styles.subMarginTop}>
            {_map(WEEKDAYS, ({ label, value }) => (
              <View style={styles.timingContainer} key={value}>
                <Text size={4} variant="medium" color={COLORS.TEXT_COLOR_SECONDARY} style={styles.fullFlex}>
                  {label}
                </Text>
                <Text size={4} style={styles.fullFlex} color={COLORS.TEXT_COLOR_SECONDARY}>
                  {_get(spaceDetails, `operationTiming.days.${value}.from`)}
                </Text>
                <Text size={4} style={styles.fullFlex} color={COLORS.TEXT_COLOR_SECONDARY}>
                  {_get(spaceDetails, `operationTiming.days.${value}.to`)}
                </Text>
              </View>
            ))}
          </View>
          {_size(_filter(WEEKEND, ({ value }) => !_get(spaceDetails, `operationTiming.days.${value}.holiday`))) > 0 ? (
            <>
              <Text size={4} variant="semiBold" style={[styles.subMarginTop,{ alignSelf: 'flex-start' }]}>
                Weekend
              </Text>
              <View style={styles.subMarginTop}>
                {_map(WEEKEND, ({ label, value }) => {
                  if (!_get(spaceDetails, `operationTiming.days.${value}.holiday`)) {
                    return (
                      <View style={styles.timingContainer} key={value}>
                        <Text size={4} variant="medium" style={styles.fullFlex} color={COLORS.TEXT_COLOR_SECONDARY}>
                          {label}
                        </Text>
                        <Text size={4} style={styles.fullFlex} color={COLORS.TEXT_COLOR_SECONDARY}>
                          {_get(spaceDetails, `operationTiming.days.${value}.from`)}
                        </Text>
                        <Text size={4} style={styles.fullFlex} color={COLORS.TEXT_COLOR_SECONDARY}>
                          {_get(spaceDetails, `operationTiming.days.${value}.to`)}
                        </Text>
                      </View>
                    );
                  }
                  return null;
                })}
              </View>
            </>
          ) : null}
        </View>
      </View>
    ),
    [spaceDetails]
  );

  const spaceReviews = useMemo(() => {
    const reviews = _values(_get(spaceDetails, 'feedback'));
    // console.log({ reviews });
    return (
      <View
        style={styles.baseMarginTop}
        onLayout={event => {
          reviewsPositon.current = _get(event, 'nativeEvent.layout.y');
        }}>
        <Text size={3} variant="bold" style={{ alignSelf: 'flex-start' }} >
          Reviews
        </Text>
        <Text size={4} variant="semiBold" color={COLORS.TEXT_COLOR_SECONDARY} style={{ marginTop: 4,alignSelf: 'flex-start' }}>
          {`${_size(reviews)} reviews`}
        </Text>
        <FlatList
          horizontal
          data={reviews}
          showsHorizontalScrollIndicator={false}
          renderItem={({ item }) => (
            // <Text>Review</Text>
            <View
              style={{
                maxWidth: (4 / 5) * width,
                borderWidth: 1,
                borderColor: COLORS.TEXT_COLOR_SECONDARY,
                flex: 1,
                padding: 12,
                flexDirection: 'row',
                marginRight: 12,
              }}>
              <View>
                <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 4, marginBottom: 2 }}>
                  <Text variant="bold" size={3}>
                    {_get(item, 'rating')}/5
                  </Text>
                  <Text size={4}>Very Good</Text>
                </View>
                <Text size={4}>{_get(item, 'customerName')}</Text>
                <Text size={4} color={COLORS.TEXT_COLOR_SECONDARY}>
                  {moment(_get(item, 'feedbackDate')).format('MMM D, YYYY')}
                </Text>
              </View>

              {_get(item, 'comment') ? (
                <>
                  <Separator type="vertical" />
                  <View style={{ justifyContent: 'center', flexShrink: 1 }}>
                    <Text size={4} numberOfLines={0} style={{ flex: 3 }}>
                      {_get(item, 'comment') || 'Good working atmosphere, all amenities working well'}
                    </Text>
                  </View>
                </>
              ) : null}
            </View>
          )}
          keyExtractor={(item, index) => index}
        />
      </View>
    );
  }, [spaceDetails]);

  const onFavoritesPress = async () => {
    const { status, msg } = await addOrRemoveSpaceToFavorites(spaceId, user);
    setReloadFavorites(true);
    toast.show(msg);
  };

  const onApproveDisapproveClick = async () => {
    try {
      const orgId = _get(user, 'organizationId');
      setApproveDisapproveInProgress(true);
      const spaceApprovedResponse = await approveDisapproveSpace(
        spaceId,
        orgId,
        getOrgApproveDisapprovePayload(spaceDetails, user)
      );
      const { message } = _get(spaceApprovedResponse, 'data');
      toast.show(message);
      setLoading(true);
      const response = await getSpaceDetails(spaceId, uid);
      setSpaceDetails(response);
      setLoading(false);
    } catch (error) {
      toast.show('Error updating space. Please try again later');
    }
    setApproveDisapproveInProgress(false);
  };

  const onRatingPress = () =>
    scrollViewRef?.current?.scrollTo({ x: 0, y: reviewsPositon?.current ?? 1000, animated: true });

  const facilitiesRow = (facilityPair, index) => (
    <View style={styles.subMarginTop} key={index}>
      <View style={styles.iconLabelContainer}>
        {_map(facilityPair, facility => (
          <IconLabelCard
            key={facility}
            label={facility}
            image={AMENITIES_VS_IMAGE[facility]}
            iconFamily={MaterialCommunityIcons}
            iconProps={{ name: 'van-utility', size: 16 }}
            containerStyle={styles.iconLabelStyle}
            textStyle={{ flex: 1 }}
          />
        ))}
      </View>
    </View>
  );

  if (loading)
    return (
      <View style={[styles.containerPadding, styles.centerView]}>
        <ActivityIndicator />
      </View>
    );
  // console.log({ photos, currentSlideIndex, amenitiesHeight });
  return (
    <View style={styles.container}>
      <Header title={[gofloatersSpaceName, ' - ', originalName]} />
      <ScrollView
        style={styles.containerPadding}
        contentContainerStyle={styles.containerContentBottom}
        ref={scrollViewRef}>
        <View style={[styles.image, styles.relative]}>
          <Carousel
            data={photos}
            paginationWidth={IMAGE_WIDTH}
            renderItem={({ item }) => (
              <ImageBackground style={styles.image} source={{ uri: item }} resizeMode="cover" />
            )}
            keyExtractor={item => item}
          />
          <Pressable style={styles.favoriteContainer} onPress={onFavoritesPress}>
            <MaterialCommunityIcons
              name={isSpacefavorite(user, spaceDetails) ? 'cards-heart' : 'cards-heart-outline'}
              size={16}
              color={COLORS.PRIMARY_COLOR}
            />
          </Pressable>
        </View>
        <View style={[styles.baseMarginTop, styles.ratingRow]}>
          <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
            {(showRates ||isOrganizationAdmin)
              ? _map(spacePricing, (pricing, index) => (
                  <React.Fragment key={index}>
                    <Text variant="bold" size={2}>
                      {pricing[0]}
                    </Text>
                    <Text size={3} variant="medium">
                      {pricing[1]}
                    </Text>

                    {index !== _size(spacePricing) - 1 ? (
                      <Text size={3} variant="medium">
                        &nbsp; or &nbsp;
                      </Text>
                    ) : null}
                  </React.Fragment>
                ))
              : null}
          </View>
          {spaceRating ? (
            <Text variant="bold" size={4} onPress={onRatingPress}>
              {`Rated ${spaceRating} /5`}
            </Text>
          ) : null}
        </View>
        <View style={styles.baseMarginTop}>
          <Text size={3} variant="bold" style={{ alignSelf: 'flex-start' }} >
            Amenities
          </Text>
          {_map(_chunk(_slice(facilitiesList, 0, 4), 2), (facilityPair, index) => facilitiesRow(facilityPair, index))}
          {/* <Animated.View style={[{ overflow: 'hidden' }, style]}>
            <View
              // onLayout={event => {
              //   console.log('setting height', event.nativeEvent.layout.height);
              //   setAmenitiesHeight(event.nativeEvent.layout.height);

              // }}
              onLayout={({
                nativeEvent: {
                  layout: { height: h },
                },
              }) => (height.value = h)}>
              {_map(_chunk(_slice(facilitiesList, 4, _size(facilitiesList)), 2), (facilityPair, index) =>
                facilitiesRow(facilityPair, index)
              )}
            </View>
          </Animated.View> */}
          <CollapsableContainer expanded={showAllAmenties}>
            {_map(_chunk(_slice(facilitiesList, 4, _size(facilitiesList)), 2), (facilityPair, index) =>
              facilitiesRow(facilityPair, index)
            )}
          </CollapsableContainer>
          <Text onPress={toggleShowAllAmneties} style={[styles.baseMarginTop, styles.showAllAmentiesStyle]} size={3}>
            Show {showAllAmenties ? 'fewer' : 'all'} amenities
          </Text>
        </View>

        <View style={styles.baseMarginTop}>
          <Text size={3} variant="bold" style={{ alignSelf: 'flex-start' }} >
            Capacity
          </Text>
          <View style={styles.subMarginTop}>
            <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY} style={{ alignSelf: 'flex-start' }} >
              {_get(spaceDetails, 'seatsAvailable')} seats available.
            </Text>
          </View>
        </View>

        <View style={styles.baseMarginTop}>
          <Text size={3} variant="bold" style={{ alignSelf: 'flex-start' }} >
            Location
          </Text>
          <View style={styles.subMarginTop}>
            <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY} style={{ textAlign: 'left' }} >
              {getFullAddress(address)}
            </Text>
          </View>
          <View style={styles.subMarginTop}>
            <View style={styles.textInlineStyle}>
              <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY} variant="semiBold" style={{ textAlign: 'left' }} >
                Landmark:{' '}
              </Text>
              <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY} style={{ alignSelf: 'flex-start' }} >
                {getLandmark(address)}
              </Text>
            </View>
          </View>
        </View>
        {getRemarks()}
        {spaceTimings}
        {spaceReviews}
        <SpaceQuickActions space={spaceDetails} user={user} setReloadFavorites={setReloadFavorites} />
      </ScrollView>

      <View style={styles.buttonStyle}>
        {isOrganizationAdmin ? (
          <Button
            bordered
            onPress={onApproveDisapproveClick}
            loading={approveDisapproveInProgress}
            style={{ flex: 1 }}
            textProps={{ variant: 'bold', size: 3 }}>
            {isSpaceApproved ? 'Disapprove' : 'Approve'}
          </Button>
        ) : null}
        <Button
          style={{ flex: 1 }}
          onPress={() => {
            navigation.navigate(ROUTES.SPACE_BOOKINGS.name, { spaceId });
          }}
          textProps={{ variant: 'bold', size: 3 }}>
          Book now
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
  },
  containerPadding: {
    paddingHorizontal: SPACING,
    paddingTop: SPACING,
  },
  containerContentBottom: {
    paddingBottom: 100,
  },
  centerView: {
    flex: 1,
    alignItems: 'center',
  },
  image: {
    width: IMAGE_WIDTH,
    height: (1 / 1.78) * IMAGE_WIDTH,
    resizeMode: 'cover',
    borderRadius: 16,
    overflow: 'hidden',
  },
  paginationDotStyle: {
    borderRadius: 8,
    maxWidth: 8,
    maxHeight: 8,
  },
  iconLabelContainer: {
    flexDirection: 'row',
  },
  iconLabelStyle: {
    flex: 1,
  },
  baseMarginTop: {
    marginTop: 20,
  },
  subMarginTop: {
    marginTop: 10,
  },
  showAllAmentiesStyle: {
    textDecorationLine: 'underline',
    color: COLORS.TEXT_COLOR_SECONDARY,
    alignSelf: 'flex-start',
  },
  textInlineStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonStyle: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    padding: 16,
    backgroundColor: COLORS.WHITE,
    flexDirection: 'row',
    columnGap: 8,
  },
  ratingRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  relative: {
    position: 'relative',
  },
  favoriteContainer: {
    width: 24,
    height: 24,
    borderRadius: 4,
    backgroundColor: COLORS.PROFILE_CARD_BACKGROUND,
    position: 'absolute',
    top: 16,
    right: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  address: {
    width: 0,
    flexGrow: 1,
    flex: 1,
  },
  timingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 16,
  },
  fullFlex: {
    flex: 1,
    textAlign:'left'
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
  spaces: state.spaces.spaces,
  showRates: getShowRates(state),
});

export default connect(mapStateToProps, { setReloadFavorites: setReloadFavoritesAction })(SpaceDetails);
