import { View, StyleSheet } from 'react-native';
import moment from 'moment';
import { COLORS } from '../constants/colors';
import { getNotificationImageStyle } from '../utils/notifications';
import Text from './Text';

const NOTIFICATION_ICON_SIZE = 48;

const NotificationItem = ({ notification }) => {
  const { notificationText, sentDate, notificationType } = notification;
  const { backgroundColor, icon } = getNotificationImageStyle(notificationType, notificationText);
  return (
    <View style={styles.container}>
      <View style={[styles.notificationImageContainer, { backgroundColor }]}>{icon}</View>
      <View style={styles.infoContainer}>
        <Text size={4} color={COLORS.TEXT_COLOR_SECONDARY} style={{ textAlign: 'left' }} >
          {moment(sentDate).fromNow()}
        </Text>
        <View>
          <Text size={3} color={COLORS.TEXT_COLOR_PRIMARY} numberOfLines={0}  style={{ textAlign: 'left' }}>
            {notificationText}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  notificationImageContainer: {
    width: NOTIFICATION_ICON_SIZE,
    height: NOTIFICATION_ICON_SIZE,
    borderRadius: NOTIFICATION_ICON_SIZE / 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoContainer: {
    paddingHorizontal: 16,
    flexWrap: 'wrap',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
  },
});

export default NotificationItem;
