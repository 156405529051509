import { View, StyleSheet, Pressable } from 'react-native';
import _get from 'lodash/get';
import Text from '../Text';
import { COLORS } from '../../constants/colors';

const RADIO_SIZE = 20;
const RADIO_SIZE_INNER = 12;

const SurveyOption = ({ questionId, option, selected, onOptionSelect, optionId }) => {
  const onPress = () => {
    const { value } = option;
    onOptionSelect(questionId, value, optionId);
  };
  return (
    <Pressable style={styles.container} onPress={onPress}>
      <View style={styles.radioOuterBorder}>{selected ? <View style={styles.radioInnerContainer} /> : null}</View>
      <Text variant="medium">{_get(option, 'label')}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
  },
  radioOuterBorder: {
    width: RADIO_SIZE,
    height: RADIO_SIZE,
    borderRadius: RADIO_SIZE / 2,
    borderWidth: 1,
    borderColor: COLORS.PRIMARY_COLOR,
    padding: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  radioInnerContainer: {
    width: RADIO_SIZE_INNER,
    height: RADIO_SIZE_INNER,
    borderRadius: RADIO_SIZE_INNER / 2,
    backgroundColor: COLORS.PRIMARY_COLOR,
  },
});

export default SurveyOption;
