import { useEffect, useMemo, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { SafeAreaView } from 'react-native-safe-area-context';

import Header from '../../components/Header';
import MapView from '../../components/maps/Mapview';
// import MapView from 'react-native-maps';

import Text from '../../components/Text';
import { COLORS } from '../../constants/colors';
import {
  getOrganizationId,
  getOrgCredits,
  getShowMemberNames,
  getShowOrgLevelCredits,
  getShowWhereIsOrg,
} from '../../selectors/organization';
import { getWhereOrgIs } from '../../api/common';
import { MAP_DATES, MAP_DATES_OPTIONS } from '../../constants/common';
import { getTimestampForOrgView } from '../../utils/common';

const MyOrganization = ({ showOrgLevelCredits, showWhereIsOrg, orgCredits, showMemberNames, organizationId, user }) => {
  const [orgMembers, setOrgMembers] = useState([]);
  const [selectedDay, setSelectedDay] = useState(MAP_DATES.TODAY);
  // useEffect(() => {
  //   async function fetchWhereIsOrg() {
  //     const response = await getWhereOrgIs(organizationId, getTimestampForOrgView(selectedDay));
  //     // const response = await getWhereOrgIs('chargebee', getTimestampForOrgView(selectedDay));
  //     // const response = await getWhereOrgIs('chargebee', 1688047123000);
  //     console.log({ response });
  //     setOrgMembers(_get(response, 'data', []));
  //   }
  //   if (showWhereIsOrg) fetchWhereIsOrg();
  // }, [showWhereIsOrg, organizationId, selectedDay]);

  const creditsview = useMemo(
    () => (
      <View style={[styles.creditsViewContainer, styles.shadowAndElevation]}>
        {/* <View style={[styles.headingContainer, styles.fullFlex]}>
          <Text size={2} color={COLORS.WHITE}>
            Organization Credits
          </Text>
        </View> */}
        <View style={[{ height: 48 }, styles.headingContainer]}>
          <Text size={2} color={COLORS.WHITE}>
            Organization Credits
          </Text>
        </View>
        <View style={[styles.credits]}>
          {showOrgLevelCredits ? (
            <Text size={0} variant="bold">
              {orgCredits}
            </Text>
          ) : (
            <Text size={3} variant="medium" style={{ textAlign: 'center' }}>
              {`Your organization's credits will be used for your bookings`}
            </Text>
          )}
        </View>
      </View>
    ),
    [showOrgLevelCredits, orgCredits]
  );

  // const orgMapView = useMemo(() => {
  //   if (showWhereIsOrg) {
  //     return (
  //       <View style={[styles.mapView]}>
  //         <View style={[{ height: 48 }, styles.headingContainer]}>
  //           <Text size={2} color={COLORS.WHITE}>
  //             Where your team is today
  //           </Text>
  //         </View>
  //         <View style={styles.daySelection}>
  //           {_map(MAP_DATES_OPTIONS, ({ label, value }) => (
  //             <Text
  //               key={value}
  //               variant={value === selectedDay ? 'bold' : 'regular'}
  //               style={{
  //                 paddingBottom: 4,
  //                 paddingHorizontal: 4,
  //                 marginBottom: 4,
  //                 textDecorationLine: value === selectedDay ? 'underline' : 'none',
  //                 color: value === selectedDay ? COLORS.PRIMARY_COLOR : COLORS.TEXT_COLOR_PRIMARY,
  //               }}
  //               onPress={() => {
  //                 setSelectedDay(value);
  //               }}>
  //               {label}
  //             </Text>
  //           ))}
  //         </View>
  //         <View style={{ width: '100%', height: '100%' }}>
  //           <MapView
  //             members={orgMembers}
  //             initial={{
  //               // latitude: _get(user, 'location.coords.lat'),
  //               // longitude: _get(user, 'location.coords.long'),
  //               latitude: 20.5937,
  //               longitude: 78.9629,
  //             }}
  //             showMemberNames={showMemberNames}
  //           />
  //         </View>
  //       </View>
  //     );
  //   }
  //   return null;
  // }, [showWhereIsOrg, selectedDay, orgMembers, user]);

  // console.log({ orgMembers });

  return (
    <SafeAreaView style={styles.container} edges={['right', 'bottom', 'left']}>
      <Header title="My Organisation" />
      <View style={[styles.container, styles.containerPadding]}>
        <View style={[]}>{creditsview}</View>
        {/* <View style={[styles.shadowAndElevation, styles.mapViewContainer]}>{orgMapView}</View> */}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
  },
  containerPadding: {
    padding: 16,
  },
  shadowAndElevation: {
    elevation: 8,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 4,
    shadowOpacity: 0.2,
    borderRadius: 12,
  },
  creditsViewContainer: {
    borderRadius: 8,
    marginBottom: 16,
    backgroundColor: 'white',
    overflow: 'hidden',
    // borderWidth: 1,
    // borderColor: COLORS.HALF_WHITE,
  },
  headingContainer: {
    backgroundColor: COLORS.PRIMARY_COLOR,
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  fullFlex: {
    flex: 1,
  },
  credits: {
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  mapViewContainer: { marginBottom: 100, flex: 1 },
  mapView: {
    flex: 1,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  daySelection: {
    flexDirection: 'row',
    columnGap: 8,
    paddingBottom: 16,
    padding: 16,
  },
});

const mapStateToProps = state => ({
  organizationId: getOrganizationId(state),
  showOrgLevelCredits: getShowOrgLevelCredits(state),
  showWhereIsOrg: getShowWhereIsOrg(state),
  orgCredits: getOrgCredits(state),
  showMemberNames: getShowMemberNames(state),
  metadata: state.metadata.data,
  user: state.user.user,
});

export default connect(mapStateToProps)(MyOrganization);
