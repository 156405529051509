import React, { useCallback } from 'react';
import { View, StyleSheet, FlatList, Dimensions, Pressable, Image } from 'react-native';
import _get from 'lodash/get';

import { connect } from 'react-redux';
import _map from 'lodash/map';
import { useToast } from 'react-native-toast-notifications';
import { COLORS } from '../../constants/colors';
import { TOOLS_CONFIG } from '../../constants/tools';
import Text from '../../components/Text';
import { getToolsConfig } from '../../utils/tools';
import { MAXDEVICEWIDTH } from '../../constants/common';

const { width: deviceWidth } = Dimensions.get('window');
let width = deviceWidth;
if (width > MAXDEVICEWIDTH) width = MAXDEVICEWIDTH;
const TOOL_ITEM_SIZE = width * 0.4;

const Tools = ({ navigation, organization }) => {
  const orgAllowedTools = getToolsConfig(organization);
  const toast = useToast();
  const onItemPress = useCallback(item => {
    // If the allowed tools have the tool that has been clicked then navigate
    if (_map(orgAllowedTools, 'configCheck').includes(item.configCheck)) {
      navigation.navigate(item.navigation);
    } else {
      toast.show('This feature has not been enabled by your organization admin.');
    }
  }, []);

  const renderItem = useCallback(
    ({ item }) => (
      <View style={styles.toolItemContainer}>
        <Pressable style={styles.toolItem} onPress={() => onItemPress(item)}>
          <View style={{ height: 48, marginBottom: 8 }}>
            <Image style={styles.iconImage} source={_get(item, 'image')} />
          </View>
          <Text size={1} variant="semiBold">
            {_get(item, 'label')}
          </Text>
        </Pressable>
      </View>
    ),
    []
  );
  return (
    <View style={styles.container}>
      <FlatList
        data={TOOLS_CONFIG}
        renderItem={renderItem}
        keyExtractor={(item, index) => `${_get(item, 'name')}${index}`}
        numColumns={2}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: COLORS.WHITE,
  },
  toolItemContainer: {
    width: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolItem: {
    width: TOOL_ITEM_SIZE,
    height: TOOL_ITEM_SIZE,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: COLORS.CATEGORY_BORDER,
    backgroundColor: COLORS.CATEGORY_BG,
    textAlign: 'center',
    padding: 8,
    paddingTop: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconImage: {
    width: 48,
    height: 48,
    resizeMode: 'contain',
  },
});

const mapStateToProps = state => ({
  organization: state.user.organization,
});

export default connect(mapStateToProps)(Tools);
