import { Ionicons, Feather, Octicons, MaterialIcons, SimpleLineIcons, Entypo } from '@expo/vector-icons';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';

import { COLORS } from '../constants/colors';
import { APP_VERSION, PROFILE_ICON_SIZE as ICON_SIZE } from '../constants/common';
import { onChatWithUsClick } from './salesIq';
import { ROUTES } from '../constants/routes';
// import { signUserOut } from './auth';

export const getProfileOptions = ({ user, appVersion = APP_VERSION, organization }) => {
  const userOptions = [
    {
      title: `Edit City: ${_get(user, 'location.city')}`,
      iconName: 'location-outline',
      iconFamily: Ionicons,
      iconSize: ICON_SIZE,
      color: COLORS.BLACK,
      navigation: 'editCity',
    },
    {
      title: 'Edit Profile',
      iconName: 'edit-3',
      iconFamily: Feather,
      iconSize: ICON_SIZE,
      color: COLORS.BLACK,
      navigation: 'editProfile',
    },
    {
      title: 'Notifications',
      iconName: 'bell',
      iconFamily: Octicons,
      iconSize: ICON_SIZE,
      color: COLORS.BLACK,
      navigation: 'notifications',
    },
    {
      title: 'Announcements',
      iconName: 'megaphone',
      iconFamily: Entypo,
      iconSize: ICON_SIZE,
      color: COLORS.BLACK,
      navigation: ROUTES.ANNOUNCEMENTS.name,
    },
  ];
  const defaultOptions = [
    {
      title: 'SOS',
      iconName: 'alert',
      iconFamily: Octicons,
      iconSize: ICON_SIZE,
      color: COLORS.BLACK,
      navigation: ROUTES.SOS.name,
      configCheck: 'config.canUseSOSFeature',
    },
    {
      title: 'Chat with us',
      iconName: 'chatbubble-ellipses-outline',
      iconFamily: Ionicons,
      iconSize: ICON_SIZE,
      color: COLORS.BLACK,
      onPress: onChatWithUsClick,
    },
    // {
    //   title: 'Call us',
    //   iconName: 'phone-call',
    //   iconFamily: Feather,
    //   iconSize: ICON_SIZE,
    //   color: COLORS.BLACK,
    //   navigation: '',
    // },
    // {
    //   title: 'FAQ',
    //   iconName: 'info',
    //   iconFamily: MaterialIcons,
    //   iconSize: ICON_SIZE,
    //   color: COLORS.BLACK,
    //   navigation: 'faqs',
    // },
    {
      title: `Version: ${appVersion}`,
      iconName: 'globe',
      iconFamily: SimpleLineIcons,
      color: COLORS.BLACK,
      iconSize: ICON_SIZE,
      navigation: '',
    },
    {
      title: 'Log out',
      iconName: 'log-out',
      iconFamily: Feather,
      iconSize: ICON_SIZE,
      color: COLORS.RED,
      navigation: '',
    },
  ];
  return [
    {
      section: '',
      options: userOptions,
    },
    {
      section: 'Help & Support',
      options: _filter(defaultOptions, option => {
        if (_get(option, 'configCheck')) {
          // console.log("configCheck",option,!_isEmpty(_get(organization, _get(option, 'configCheck'))));
          // return !_isEmpty(_get(organization, _get(option, 'configCheck')));
          return _get(organization, _get(option, 'configCheck'));
        }
        return true;
      }),
    },
  ];
};

// x = {
//   collapseKey: 'com.gofloaters.rn.app',
//   data: { navProps: '{"bookingkey":"-NdfTqJb91vC7I_7cOoJ"}', screen: 'bookingDetails', status: 'Confirmed' },
//   from: '187167465849',
//   messageId: '0:1694851211010936%b4105b3eb4105b3e',
//   notification: {
//     android: { clickAction: 'FCM_PLUGIN_ACTIVITY', color: '#7030a0', smallIcon: 'fcm_push_icon' },
//     body: 'test',
//     title: 'GoFloaters',
//   },
//   sentTime: 1694851210964,
//   ttl: 2419200,
// };
// y = {
//   remoteMessage: {
//     collapseKey: 'com.gofloaters.rn.app',
//     data: { navProps: '{"spaceId":"-N-CGjeH1xuqomuDPtPV"}', screen: 'spaceDetails' },
//     from: '187167465849',
//     messageId: '0:1694851188444921%b4105b3eb4105b3e',
//     notification: { android: [Object], body: '1234', title: 'test' },
//     sentTime: 1694851188343,
//     ttl: 2419200,
//   },
// };
