import { ROUTES } from './routes';
import { IMAGES } from '../assets/images';

export const TOOLS_CONFIG = [
  {
    // icon: <FontAwesome name="search" size={ICON_SIZE} color={COLORS.PRIMARY_COLOR} />,
    image: IMAGES.EVENT_ICON,
    label: 'Events',
    configCheck: 'config.enableEvents',
    navigation: ROUTES.EVENTS.name,
  },
  {
    image: IMAGES.FAV_COWORKER,
    label: 'Favorite Coworker',
    configCheck: 'config.enableFavoriteCoworker',
    navigation: ROUTES.FAVORITE_COWORKERS.name,
  },
];
