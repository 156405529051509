import { Dimensions, Image, StyleSheet, View } from 'react-native';
import { IMAGES } from '../assets/images';
import { SPACING,MAXDEVICEWIDTH } from '../constants/common';

const { width } = Dimensions.get('window');
const IMAGE_WIDTH = width > MAXDEVICEWIDTH ? MAXDEVICEWIDTH:width;

function Wave({ containerStyle }) {
  return (
    <View style={[styles.container, containerStyle]}>
      <Image source={IMAGES.WAVE} style={[styles.container, styles.imageStyle]} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width:IMAGE_WIDTH,
    height: 300,
    position: 'absolute',
    bottom: 0,
    overflow: 'hidden',
    zIndex: -999,
  },
  imageStyle: {
    transform: [{ scale: 2 }],
  },
});

export default Wave;
