import React, { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { ActivityIndicator, Dimensions, ImageBackground, ScrollView, StyleSheet, View } from 'react-native';
import _get from 'lodash/get';

import { useToast } from 'react-native-toast-notifications';
import { COLORS } from '../../constants/colors';
import { SPACING } from '../../constants/common';
import {
  getCheckinPayload,
  getCurrentDate,
  getCurrentTime,
  getSpaceAvailabilityPayload,
  getSpaceAvailabilityPayloadForMeetingSpaces,
  isInstantlyBookableAwfis,
  isInstantlyBookableWeWork,
  isInstantlyBookableCowrks,
  isOfficeSpace,
} from '../../utils/common';
import Button from '../../components/Button';
import Header from '../../components/Header';
import { checkBookingAvailability, createBooking, getSpaceDetails } from '../../api/common';
import Carousel from '../../components/carousel/CarouselFlatlist';
import BookingConfirmation from '../../components/bookingConfirmation/BookingConfirmation';
import { auth } from '../../../firebaseConfig';
import Text from '../../components/Text';

const { width } = Dimensions.get('window');
const IMAGE_WIDTH = width - 2 * SPACING;

const SpaceCheckin = ({ route, user, navigation, organization }) => {
  const { spaceId = '-N-CGjeH1xuqomuDPtPV' } = route.params;
  const uid = _get(user, 'uid');
  const [loading, setLoading] = useState(true);
  const [spaceDetails, setSpaceDetails] = useState(null);
  const [booking, setBooking] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const toast = useToast();

  const deskBookingRef = useRef(null);
  const meetingRoomBookingRef = useRef(null);
  const currentDate = getCurrentDate();
  const currentTime = getCurrentTime();

  useEffect(() => {
    async function fetchSpaceDetails() {
      setLoading(true);
      const response = await getSpaceDetails(spaceId, uid);
      setSpaceDetails(response);
      setLoading(false);
    }
    if (spaceId && uid && auth?.currentUser?.getIdToken()) fetchSpaceDetails();
  }, [spaceId, uid, auth?.currentUser]);

  const { originalName, photos, gofloatersSpaceName } = useMemo(
    () => ({
      originalName: _get(spaceDetails, 'originalName', ''),
      photos: _get(spaceDetails, 'photos', []),
      gofloatersSpaceName: _get(spaceDetails, 'gofloatersSpaceName', ''),
    }),
    [spaceDetails]
  );

  const onBookNowPress = async () => {
    setBooking(true);
    if (isOfficeSpace(spaceDetails)) {
      const bookingPayload = getCheckinPayload(spaceDetails, user, currentDate, currentTime);
      if (bookingPayload) {
        const spaceAvailabilityPayload = getSpaceAvailabilityPayload(spaceId, bookingPayload);
        try {
          const response = await checkBookingAvailability(spaceAvailabilityPayload);
          if (response) {
            try {
              const bookingResponse = await createBooking(bookingPayload);
              setBookingDetails(bookingResponse);
              // navigation.navigate(ROUTES.BOOKINGS);
            } catch (error) {
              toast.show(error?.response?.data?.error?.message ?? 'Error booking space. Please try again.');
            }
          } else {
            toast.show('Space not available for selected date and time');
          }
        } catch {
          toast.show('Space not available for selected date and time');
        } finally {
          setBooking(false);
        }
      }
      setBooking(false);
    } else {
      const bookingPayload = meetingRoomBookingRef?.current.getBookingPayload();
      // setBooking(false);
      if (bookingPayload) {
        try {
          let allowBooking = true;
          if (isInstantlyBookableAwfis(spaceDetails) || isInstantlyBookableWeWork(spaceDetails) || isInstantlyBookableCowrks(spaceDetails)) {
            const spaceAvailabilityPayload = getSpaceAvailabilityPayloadForMeetingSpaces(spaceId, bookingPayload);
            allowBooking = await checkBookingAvailability(spaceAvailabilityPayload);
          }
          if (allowBooking) {
            try {
              const bookingResponse = await createBooking(bookingPayload);
              // console.log({ bookingResponse });
              setBookingDetails(bookingResponse);
              // navigation.navigate(ROUTES.BOOKINGS);
            } catch {
              toast.show('Error booking space. Please try again.');
            }
          } else {
            toast.show('Space not available for selected date and time');
          }
        } catch {
          toast.show('Space not available for selected date and time');
        } finally {
          setBooking(false);
        }
      }
      setBooking(false);
    }
  };

  if (loading)
    return (
      <View style={[styles.containerPadding, styles.centerView]}>
        <ActivityIndicator />
      </View>
    );

  const renderCheckingScreen = () => (
    <View style={styles.container}>
      <Header title={[gofloatersSpaceName, ' - ', originalName]} />
      <ScrollView style={styles.containerPadding} contentContainerStyle={{ paddingBottom: 100 }}>
        <View style={styles.image}>
          <Carousel
            data={photos}
            paginationWidth={IMAGE_WIDTH}
            renderItem={({ item }) => (
              <ImageBackground style={styles.image} source={{ uri: item }} resizeMode="cover" />
            )}
            keyExtractor={item => item}
          />
        </View>
        <View style={styles.detailContainer}>
          <Text size={9} variant="bold">
            Space Name
          </Text>
          <Text variant="medium">{originalName}</Text>
        </View>
        <View style={styles.detailContainer}>
          <Text size={9} variant="bold">
            Date
          </Text>
          <Text variant="medium">{currentDate}</Text>
        </View>
        <View style={styles.detailContainer}>
          <Text size={9} variant="bold">
            Time
          </Text>
          <Text variant="medium">{currentTime}</Text>
        </View>
        <View style={styles.buttonStyle}>
          <Button textProps={{ variant: 'bold', size: 2 }} onPress={onBookNowPress} loading={booking}>
            Check In
          </Button>
        </View>
      </ScrollView>
    </View>
  );

  const renderBookingConfirmationScreen = () => (
    <BookingConfirmation navigation={navigation} bookingDetails={bookingDetails} user={user} />
  );

  return !bookingDetails ? renderCheckingScreen() : renderBookingConfirmationScreen();
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
  },
  containerPadding: {
    paddingHorizontal: SPACING,
    paddingTop: SPACING,
  },
  image: {
    width: width - 2 * SPACING,
    height: (1 / 1.78) * (width - 2 * SPACING),
    resizeMode: 'cover',
    borderRadius: 16,
    overflow: 'hidden',
  },
  paginationDotStyle: {
    borderRadius: 8,
    maxWidth: 8,
    maxHeight: 8,
  },
  baseMarginTop: {
    marginTop: 20,
  },
  subMarginTop: {
    marginTop: 10,
  },
  margin30: {
    marginTop: 30,
  },
  dateTimeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  fullFlex: {
    flex: 1,
  },
  buttonStyle: {
    width: '100%',
    backgroundColor: COLORS.WHITE,
    marginTop: 8,
  },
  detailContainer: {
    paddingVertical: 16,
    width: '100%',
    backgroundColor: COLORS.HALF_WHITE,
    borderRadius: 8,
    alignItems: 'center',
    marginTop: 8,
    rowGap: 4,
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
  organization: state.user.organization,
});

export default connect(mapStateToProps)(SpaceCheckin);
