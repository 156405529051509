import { StyleSheet } from 'react-native';
import { Fontisto } from '@expo/vector-icons';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import ImageViewer from '../../components/imageViewer';
import { COLORS } from '../../constants/colors';
import Header from '../../components/Header';

// const imageUrl =
//   'https://images.pexels.com/photos/994605/pexels-photo-994605.jpeg?auto=compress&cs=tinysrgb&w=2726&h=2047&dpr=1';

const ViewImage = ({ route, navigation }) => {
  const { imageUrl } = route.params;
  const insets = useSafeAreaInsets();

  const onRequestClose = () => {
    navigation.goBack();
  };
  return (
    <>
      <ImageViewer imageUrl={imageUrl} onRequestClose={onRequestClose} />
      <Fontisto
        name="angle-left"
        size={16}
        color={COLORS.WHITE}
        onPress={onRequestClose}
        style={[styles.backIcon, { top: insets.top + 20 }]}
      />
    </>
  );
};

const styles = StyleSheet.create({
  backIcon: {
    position: 'absolute',
    top: 20,
    left: 20,
  },
});

export default ViewImage;
