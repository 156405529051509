import React from 'react';
import { View, Pressable, StyleSheet } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { AntDesign } from '@expo/vector-icons';
import Text from './Text';

const SeatSelector = ({ seats, setSeats, labelStyle, disableEdit = false }) => {
  const increaseSeats = () => {
    setSeats(seats + 1);
  };

  const decreaseSeats = () => {
    setSeats(seats - 1);
  };
  return (
    <View>
      <View style={styles.seatContainer}>
        {!disableEdit ? (
          <Pressable onPress={decreaseSeats}>
            <LinearGradient
              // Button Linear Gradient
              colors={['rgba(255, 199, 167, 0.2)', 'rgba(255, 213, 121, 0.2)']}
              style={styles.iconBackgroundStyle}>
              <AntDesign name="minus" size={16} color="#2193B0" />
            </LinearGradient>
          </Pressable>
        ) : null}
        <View style={[styles.textStyle, labelStyle]}>
          <Text>{seats}</Text>
        </View>

        {!disableEdit ? (
          <Pressable onPress={increaseSeats}>
            <LinearGradient
              // Button Linear Gradient
              colors={['rgba(255, 199, 167, 0.2)', 'rgba(255, 213, 121, 0.2)']}
              style={styles.iconBackgroundStyle}>
              <AntDesign name="plus" size={16} color="#2193B0" />
            </LinearGradient>
          </Pressable>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  marginTop: {
    marginTop: 20,
  },
  seatContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonStyle: {
    width: 38,
    height: 38,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  textStyle: {
    width: 32,
    marginHorizontal: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconBackgroundStyle: {
    borderRadius: 5,
    width: 38,
    height: 38,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default SeatSelector;
