import React, { memo, useMemo } from 'react';
import { StyleSheet, View, Keyboard } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

export const Dropdown = memo(
  ({
    position,
    direction,
    inputHeight,
    dataSet,
    suggestionsListMaxHeight,
    renderItem,
    ListEmptyComponent,
    ...props
  }) => {
    const ItemSeparatorComponent = useMemo(
      () => () => <View style={{ height: 1, width: '100%', backgroundColor: '#ddd' }} />,
      []
    );

    return (
      <View
        style={{
          ...styles.listContainer,
          position,
          ...(position === 'relative'
            ? { marginTop: 5 }
            : {
                [direction === 'down' ? 'top' : 'bottom']: inputHeight + 5,
              }),
          ...props.suggestionsListContainerStyle,
        }}>
        <FlatList
          keyboardDismissMode="on-drag"
          keyboardShouldPersistTaps="handled"
          nestedScrollEnabled
          data={dataSet}
          style={{ maxHeight: suggestionsListMaxHeight }}
          renderItem={renderItem}
          keyExtractor={item => item.id}
          ListEmptyComponent={ListEmptyComponent}
          ItemSeparatorComponent={ItemSeparatorComponent}
          {...props.flatListProps}
        />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {},
  listContainer: {
    backgroundColor: '#fff',
    width: '100%',
    zIndex: 9,
    borderRadius: 5,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 4,
    shadowOpacity: 0.2,
    overflow: 'scroll',
    elevation: 20,
    borderWidth: 1,
    borderColor: '#eee',
  },
});
