import { useEffect, useRef } from 'react';
import { StyleSheet, View,Platform,Linking } from 'react-native';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import Text from './Text';
import { ROUTES } from '../constants/routes';
import Button from './Button';
import { COLORS } from '../constants/colors';
import { getBlockAppUsage } from '../selectors/organization';
import { getBlockWebUsage } from '../selectors/organization';

const NoReferral = ({ navigation, user,blockAppUsage,blockWebUsage }) => {
  const modalizeRef = useRef(null);
  const orgId = _get(user, 'organizationId');

  useEffect(() => {
    if (!orgId && modalizeRef.current) {
      onOpen();
    }
  }, [modalizeRef.current, orgId]);

  useEffect(() => {
    console.log("blockAppUsage",blockAppUsage);
    if (blockAppUsage && Platform.OS !== 'web'  && modalizeRef.current) {
      onOpen();
    }
  }, [modalizeRef.current, blockAppUsage]);

  useEffect(() => {
    console.log("blockWebUsage",blockWebUsage);
    if (blockWebUsage && Platform.OS == 'web' &&  modalizeRef.current) {
      onOpen();
    }
  }, [modalizeRef.current, blockWebUsage]);

  const onOpen = () => {
    if (modalizeRef?.current) {
      modalizeRef.current?.open();
    }
  };

  const onCancel = () => {
    modalizeRef.current?.close();
  };

  const navigateToEditProfile = () => {
    onCancel();
    navigation.navigate(ROUTES.EDIT_PROFILE.name);
  };
  const openWebApplication = () => {
    Linking.openURL("http://worqflexiapp.gofloaters.com");
  };

  return (
    <Portal>
      <Modalize
        ref={modalizeRef}
        modalHeight={300}
        withHandle={false}
        closeOnOverlayTap={false}
        modalStyle={{ padding: 16 }}>
        {!orgId ?(
          <view>
          <View style={styles.marginBottom}>
          <Text size={9} variant="bold" style={styles.marginBottom}>
            Account not part of an organization
          </Text>
          <Text numberOfLines={0} style={styles.marginBottom}>
            This could be because you might have forgotten to add the referral code while signing up. You can add the
            referral code to your profile now.
          </Text>
        </View>
        <Button variant="small" style={{ marginBottom: 8 }} onPress={navigateToEditProfile}>
          Edit Profile
        </Button>
        <Text
          numberOfLines={0}
          style={[styles.marginBottom, { textAlign: 'center', paddingHorizontal: 8 }]}
          onPress={onCancel}
          size={3}
          variant="bold"
          color={COLORS.PRIMARY_COLOR}>
          Cancel
        </Text></view>) : null}
        {blockAppUsage ? (<View><View style={styles.marginBottom}>
          <Text size={9} variant="bold" style={styles.marginBottom}>
            Please use WorqFlexi Web Application
          </Text>
          <Text numberOfLines={0} style={styles.marginBottom}>
            Your organization recommends you to use the web application instead of the mobile app.
          </Text>
        </View>
          <Button variant="small" style={{ marginBottom: 8 }} onPress={openWebApplication}>
            Open Web Application
          </Button>
        </View>) : null}
        {blockWebUsage ?( 
          <View>
          <View style={styles.marginBottom}>
          <Text size={9} variant="bold" style={styles.marginBottom}>
            Please use WorqFlexi Mobile App
          </Text>
          <Text numberOfLines={0} style={styles.marginBottom}>
           Your organization recommends you to use the mobile app instead of the web applocation.
          </Text>
        </View>
        <Button variant="small" style={{ marginBottom: 8 }} onPress={openWebApplication}>
          Open Mobile App
        </Button>
        </View>) : null}
      </Modalize>
    </Portal>
  );
};

const styles = StyleSheet.create({
  marginBottom: {
    marginBottom: 16,
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
  blockAppUsage: getBlockAppUsage(state),
  blockWebUsage:getBlockWebUsage(state)
});

export default connect(mapStateToProps)(NoReferral);
