import React, { useCallback } from 'react';
import { View, StyleSheet, Linking, Pressable, Platform, Share } from 'react-native';
import { FontAwesome5, FontAwesome, Octicons, Feather } from '@expo/vector-icons';
import * as Clipboard from 'expo-clipboard';
import * as Calendar from 'expo-calendar';
import * as AddCalendarEvent from 'react-native-add-calendar-event';

import * as Sharing from 'expo-sharing';
import { LinearGradient } from 'expo-linear-gradient';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';
import _map from 'lodash/map';
import _chunk from 'lodash/chunk';

import { useLinkProps } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';
import { connect } from 'react-redux';
import { COLORS } from '../../constants/colors';
import Separator from '../Separator';
import Text from '../Text';
import BookingTimeline from '../BookingTimeline';
import IconLabelCard from '../IconLabelCard';
import {
  canBookingBeCancelled,
  canBookingBeRated,
  getBookingEventDetails,
  getBookingInfoToShare,
  getFormattedStartDateTime,
  getFormattedEndDateTime,
  getGoogleMapsLink,
  getSpaceDetails,
  isBookingConfirmed,
  isWeWorkBooking,
  isAwfisBooking,
  isCowrksBooking,
  isAwfisDeskBooking,
  isOfficeSpace,
  getSpaceBookingDuration,
  isBookingActive,
  isBookingActiveFuture,
  isCancelBeforePrevDayMidnight,
  canCheckinBooking,
} from '../../utils/common';
import { ROUTES } from '../../constants/routes';
import { IMAGES } from '../../assets/images';
import { cancelBooking, redeemToConfrimBooking } from '../../api/common';
import { setCalendarId as setCalendarIdAction } from '../../reducers/user';
import RateExperience from '../rateExperience';
import AddToCalendar from '../addToCalendar';
import CheckinSpace from '../checkinSpace';

function BookingsCard({ bookingInfo, navigation, calendarId, setCalendarId, user, onPress = _noop, organization }) {
  const {
    bookingid,
    bookingkey,
    spaceName,
    spaceaddress,
    startTime,
    status,
    spacecoords,
    gofloatersSpaceName,
    originalName,
    spaceDisplayName,
    headCount,
    spaceId,
    customerName,
  } = bookingInfo;
  const toast = useToast();
  // console.log({ bookingInfo });
  // const { onPress, ...props } = useLinkProps({ to: { screen: ROUTES.BOOKING_DETAILS.name, params: { bookingkey } } });
  // const Container = Platform.OS === 'web' ? View : Pressable;
  // const onClick = Platform.OS === 'web' ? { onClick: onPress } : { onPress };

  const onClickOpenMaps = useCallback(() => {
    if (_get(bookingInfo, 'googleMapsLink')) {
      Linking.openURL(_get(bookingInfo, 'googleMapsLink'));
    } else {
      Linking.openURL(getGoogleMapsLink(spacecoords));
    }
  }, [spacecoords]);

  const onSharePress = useCallback(async () => {
    // const isSharingAvailable = await Sharing.isAvailableAsync();
    // if(isSharingAvailable) {
    //   Sharing.shareAsync()
    // }

    const message = getBookingInfoToShare(bookingInfo);
    if (Platform.OS === 'web') {
      if (navigator?.share) {
        await navigator.share({
          text: message,
          title: originalName,
        });
        return;
      }
      await Clipboard.setStringAsync(message);
      toast.show('Message copied.');
      return;
    }

    try {
      await Share.share({
        message,
        title: originalName,
      });
    } catch (error) {
      console.log({ error });
    }
  }, [bookingInfo]);

  const onCopyClick = useCallback(async () => {
    try {
      await Clipboard.setStringAsync(bookingid);
      toast.show('Booking Id copied.');
    } catch (error) {
      console.log({ error });
    }
  }, [bookingid]);

  const onCancelBooking = useCallback(
    async e => {
      const response = await cancelBooking(bookingkey);
      if (!_isEmpty(response)) {
        toast.show('Booking has been cancelled successfully.');
      } else {
        toast.show('Error cancelling booking. Please try again.');
      }
    },
    [bookingid]
  );

  const onCardClick = () => {
    // navigation.navigate(ROUTES.BOOKING_DETAILS.name, { bookingkey });
    onPress(bookingkey);
  };

  async function getDefaultCalendarSource() {
    const defaultCalendar = await Calendar.getDefaultCalendarAsync();
    return defaultCalendar.source;
  }

  // const onAddToCalendarPress = async () => {
  //   const { status: calendarPermissionStatus } = await Calendar.requestCalendarPermissionsAsync();
  //   console.log({ calendarPermissionStatus, calendarId });
  //   if (calendarPermissionStatus === 'granted') {
  //     if (!calendarId) {
  //       const newCalendarID = await createCalendar();
  //       console.log({ newCalendarID });
  //       const eventDetails = await Calendar.createEventAsync(newCalendarID, {
  //         id: bookingid,
  //         ...getBookingEventDetails(bookingInfo),
  //       });
  //       console.log({ eventDetails });
  //       setCalendarId(newCalendarID);
  //       toast.show('Event added to calendar.');
  //     } else {
  //       const eventDetails = await Calendar.createEventAsync(calendarId, {
  //         id: bookingid,
  //         ...getBookingEventDetails(bookingInfo),
  //       });
  //       console.log({ eventDetailsOld: eventDetails });
  //       toast.show('Event added to calendar.');
  //     }

  //     // const calendars = await Calendar.getCalendarsAsync(Calendar.EntityTypes.EVENT);
  //     // console.log('Here are all your calendars:');
  //     // console.log({ calendars });
  //   } else {
  //     toast.show('Permission needed to add to calendar. Please provide permission from settings.');
  //   }
  // };

  const onAddToCalendarPress = () => {
    const eventConfig = getBookingEventDetails(bookingInfo);
    if (Platform.OS !== 'web') {
      AddCalendarEvent.presentEventCreatingDialog(eventConfig)
        .then(eventInfo => {
          if (_get(eventInfo, 'action') === 'SAVED') {
            toast.show('Event added successfully');
          }
        })
        .catch(error => {
          // handle error such as when user rejected permissions
          toast.show('Permission needed to add to calendar. Please provide permission from settings.');
          console.log(error);
        });
    }
  };

  const onRedeemToConfirmPress = async () => {
    try {
      const response = await redeemToConfrimBooking(bookingInfo, user);
      if (!_isEmpty(response)) {
        toast.show('Credits redeemed successfully.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSpacecActions = () => {
    const spaceActions = [];
    if (isBookingConfirmed(bookingInfo)) {
      spaceActions.push(
        <Pressable onPress={onRedeemToConfirmPress}>
          <IconLabelCard
            iconBackgroundStyle={styles.iconBackgroundStyle}
            label="Redeem to confirm"
            image={IMAGES.COIN_SHARE}
            containerStyle={styles.iconLabelStyle}
            imageStyle={{ width: 12, height: 12 }}
            labelProps={{ size: 3, color: COLORS.TEXT_COLOR_SECONDARY }}
          />
        </Pressable>
      );
    }
    if (canBookingBeRated(bookingInfo)) {
      spaceActions.push(
        <RateExperience bookingkey={bookingkey} spaceName={spaceName} spaceId={spaceId} customerName={customerName} />
      );
    }
    if (canBookingBeCancelled(bookingInfo)) {
      spaceActions.push(
        <Pressable style={[styles.iconButtonContainer, styles.flexRowCenter]} onPress={onCancelBooking}>
          <LinearGradient
            // Button Linear Gradient
            colors={['rgba(255, 199, 167, 0.2)', 'rgba(255, 213, 121, 0.2)']}
            style={styles.iconBackgroundStyle}>
            <FontAwesome name="close" size={12} color={COLORS.STATUS_COLORS.FAILURE} />
          </LinearGradient>
          <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY}>
            Cancel Booking
          </Text>
        </Pressable>
      );
    }
    if (canCheckinBooking(bookingInfo, organization))
      spaceActions.push(<CheckinSpace user={user} booking={bookingInfo} />);
    return _chunk(spaceActions, 2);
  };

  return (
    <Pressable style={styles.container} onPress={onCardClick}>
      <View style={[styles.basePaddingHorizontal, styles.basePaddingVertical, styles.flexRowCenter]}>
        <View>
          <Text variant="bold" size={1} color={COLORS.PRIMARY_COLOR} style={styles.margin}>
            Booking ID: {bookingid}
          </Text>
          <Text variant="medium" size={1} style={{ alignSelf: 'flex-start' }} >
            {isOfficeSpace(bookingInfo) ? `${headCount}x Individual Desks` : `${headCount} Seater Meeting Room`}
          </Text>
        </View>
        <View style={[styles.basePaddingHorizontal, styles.flexRowCenter]}>
          <Pressable
            onPress={onCopyClick}
            onTouchEnd={event => event.stopPropagation()}
            onPressOut={event => event.stopPropagation()}>
            <Octicons name="copy" size={24} color={COLORS.PRIMARY_COLOR} />
          </Pressable>
          <Feather
            name="share"
            size={24}
            color={COLORS.PRIMARY_COLOR}
            style={styles.basePaddingHorizontal}
            onPress={onSharePress}
          />
        </View>
      </View>
      <Separator gap={0} />
      <View>
        <View style={[styles.basePaddingHorizontal, styles.basePaddingTop]}>
          <BookingTimeline status={status} />
        </View>
        <View style={[styles.basePaddingHorizontal, styles.basePaddingTop]}>
          <Text variant="bold" size={3} style={{ alignSelf: 'flex-start' }} >
            {gofloatersSpaceName} - {originalName}
          </Text>
          <Text variant="medium" size={4} color={COLORS.PRIMARY_COLOR} style={{ alignSelf: 'flex-start' }} >
            {spaceDisplayName}
          </Text>
          <Text size={4} color={COLORS.TEXT_COLOR_SECONDARY} style={{ alignSelf: 'flex-start' }} >
            {getSpaceDetails(spaceaddress)}
          </Text>
        </View>
        <View
          style={[
            styles.basePaddingHorizontal,
            styles.basePaddingTop,
            styles.bookingsActions,
            styles.justifySpaceBetween,
          ]}>
          {/* <LinearGradient
          // Button Linear Gradient
          colors={['rgba(255, 199, 167, 0.2)', 'rgba(255, 213, 121, 0.2)']}
          style={styles.iconBackgroundStyle}>
          <FontAwesome5 name="calendar-plus" size={12} color={COLORS.PRIMARY_COLOR} />
        </LinearGradient> */}
          {/* <Text variant="semiBold" size={3} color={COLORS.BUTTON_COLORS.SECONDARY_COLOR} onPress={onAddToCalendarPress}>
            Add to Calendar {'>'}
          </Text> */}
          <AddToCalendar bookingInfo={bookingInfo} />
          <Text variant="semiBold" size={3} color={COLORS.BUTTON_COLORS.SECONDARY_COLOR} onPress={onClickOpenMaps}>
            Open in Maps {'>'}
          </Text>
        </View>
        <View style={[styles.basePaddingHorizontal, styles.basePaddingTop]}>
          <Text size={4} color={COLORS.TEXT_COLOR_SECONDARY} numberOfLines={0} style={{ textAlign:'left'}} >
            {getFormattedStartDateTime(startTime, 'DD MMM, YYYY')}
          </Text>
          <Text size={4} color={COLORS.TEXT_COLOR_SECONDARY} numberOfLines={0} style={{ textAlign:'left'}} >
            From{' '} {getFormattedStartDateTime(startTime, 'hh:mm a')}
          </Text>
          <Text size={4} color={COLORS.TEXT_COLOR_SECONDARY} numberOfLines={0} style={{ textAlign:'left'}} >
            For{' '} {getSpaceBookingDuration(bookingInfo,organization)} {' '}hours
          </Text>
        </View>

        {isWeWorkBooking(bookingInfo) ? (
          <View style={[styles.basePaddingHorizontal, styles.basePaddingTop]}>
            <Text size={4} color={COLORS.TEXT_COLOR_SECONDARY} style={{ alignSelf: 'flex-start' }} >
              WeWork Order: {_get(bookingInfo, 'wework.orderNumber')}
            </Text>
            <Text size={4} color={COLORS.TEXT_COLOR_SECONDARY} style={{ alignSelf: 'flex-start' }} >
              WeWork Status: {_get(bookingInfo, 'wework.orderStatus')}
            </Text>
          </View>
        ) : null}

        {isAwfisBooking(bookingInfo) ? (
          <View style={[styles.basePaddingHorizontal, styles.basePaddingTop]}>
            <Text size={4} color={COLORS.TEXT_COLOR_SECONDARY} style={{ alignSelf: 'flex-start' }} >
              AWFIS Order: {_get(bookingInfo, 'awfis.orderNumber')}
            </Text>
            <Text size={4} color={COLORS.TEXT_COLOR_SECONDARY} style={{ alignSelf: 'flex-start' }} >
              AWFIS Status: {_get(bookingInfo, 'awfis.orderStatus')}
            </Text>
          </View>
        ) : null}

        {isCowrksBooking(bookingInfo) ? (
          <View style={[styles.basePaddingHorizontal, styles.basePaddingTop]}>
            <Text size={4} color={COLORS.TEXT_COLOR_SECONDARY} style={{ alignSelf: 'flex-start' }} >
              Cowrks Order: {_get(bookingInfo, 'cowrks.orderNumber')}
            </Text>
            <Text size={4} color={COLORS.TEXT_COLOR_SECONDARY}style={{ alignSelf: 'flex-start' }} >
              Cowrks Status: {_get(bookingInfo, 'cowrks.orderStatus')}
            </Text>
          </View>
        ) : null}

        <View style={styles.margin}>
          {_map(getSpacecActions(), ([first = null, second = null]) => (
            <View style={[styles.basePaddingHorizontal, styles.subBasePaddingVertical, styles.flexRowCenter]}>
              <View style={{ flex: 1, marginRight: 8 }}>{first}</View>
              <View style={{ flex: 1, marginLeft: 8 }}>{second}</View>
            </View>
          ))}
        </View>

        {/* <View
          style={[
            styles.basePaddingHorizontal,
            styles.basePaddingVertical,
            styles.flexRowCenter,
            styles.justifySpaceBetween,
          ]}>
          {isBookingConfirmed(bookingInfo) ? (
            <Pressable onPress={onRedeemToConfirmPress}>
              <IconLabelCard
                iconBackgroundStyle={styles.iconBackgroundStyle}
                label="Redeem to confirm"
                image={IMAGES.COIN_SHARE}
                containerStyle={styles.iconLabelStyle}
                imageStyle={{ width: 12, height: 12 }}
                labelProps={{ size: 3, color: COLORS.TEXT_COLOR_SECONDARY }}
              />
            </Pressable>
          ) : null}
          {canBookingBeRated(bookingInfo) ? (
            <RateExperience
              bookingkey={bookingkey}
              spaceName={spaceName}
              spaceId={spaceId}
              customerName={customerName}
            />
          ) : null}
          {canBookingBeCancelled(bookingInfo) ? (
            <Pressable style={[styles.iconButtonContainer, styles.flexRowCenter]} onPress={onCancelBooking}>
              <LinearGradient
                // Button Linear Gradient
                colors={['rgba(255, 199, 167, 0.2)', 'rgba(255, 213, 121, 0.2)']}
                style={styles.iconBackgroundStyle}>
                <FontAwesome name="close" size={12} color={COLORS.STATUS_COLORS.FAILURE} />
              </LinearGradient>
              <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY}>
                Cancel Booking
              </Text>
            </Pressable>
          ) : null}
        </View> */}
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: COLORS.PRIMARY_BORDER_COLOR,
    borderRadius: 12,
    marginBottom: 16,
  },
  margin: {
    marginBottom: 8,
  },
  basePaddingHorizontal: {
    paddingHorizontal: 16,

  },
  basePaddingVertical: {
    paddingVertical: 16,
  },
  subBasePaddingVertical: {
    paddingVertical: 8,
  },
  basePaddingTop: {
    paddingTop: 16,
  },
  basePaddingBottom: {
    paddingBottom: 16,
  },
  bookingsActions: {
    flexDirection: 'row',
  },
  iconBackgroundStyle: {
    width: 24,
    height: 24,
    padding: 6,
    borderRadius: 4,
  },
  flexRowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconButtonContainer: {
    columnGap: 8,
  },
  justifySpaceBetween: {
    justifyContent: 'space-between',
  },
});

const mapStateToProps = state => ({
  calendarId: state.user.calendarId,
});

export default connect(mapStateToProps, { setCalendarId: setCalendarIdAction })(BookingsCard);
