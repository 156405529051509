import moment from 'moment-timezone';
import { View, StyleSheet, Image, Dimensions, Platform, Pressable } from 'react-native';
import { useLinkProps } from '@react-navigation/native';
import _head from 'lodash/head';
import _noop from 'lodash/noop';
import { IMAGES } from '../../assets/images';
import { COLORS } from '../../constants/colors';
import { BOOKING_STATUS_LABEL_COLOR, SPACING } from '../../constants/common';
import { getSpaceDetails } from '../../utils/common';
import Text from '../Text';
import { ROUTES } from '../../constants/routes';

const { width } = Dimensions.get('window');

function SpaceCardSmall({ space, style, showGoFLoatersName = true, onPress = _noop }) {
  const {
    spaceName,
    bookingkey,
    spaceaddress,
    startTime,
    status,
    gofloatersSpaceName,
    originalName,
    spaceDisplayName,
    spacePhoto,
  } = space;
  // const { onPress, ...props } = useLinkProps({ to: { screen: ROUTES.BOOKING_DETAILS.name, params: { bookingkey } } });
  // const Container = Platform.OS === 'web' ? View : Pressable;
  // const onClick = Platform.OS === 'web' ? { onClick: onPress } : { onPress };

  const onCardClick = () => {
    onPress(bookingkey);
  };

  return (
    <Pressable style={[styles.container, style]} onPress={onCardClick}>
      <Image source={spacePhoto ? { uri: spacePhoto } : IMAGES.ONBOARDING} style={styles.imageStyle} />
      <View style={styles.contentStyle}>
        <Text variant="bold" size={3}  style={{ textAlign: 'left' }}>
          {`${showGoFLoatersName ? `${gofloatersSpaceName} - ` : ''}${originalName}`}
        </Text>
        <Text variant="medium" size={4} color={COLORS.PRIMARY_COLOR} style={{ textAlign: 'left' }}>
          {spaceDisplayName}
        </Text>
        <Text variant="bold" size={4} color={BOOKING_STATUS_LABEL_COLOR[status]} style={{ textAlign: 'left' }}>
          {status}
        </Text>
        <Text variant="medium" size={4} color={COLORS.TEXT_COLOR_SECONDARY} style={{ textAlign: 'left' }}>
          {getSpaceDetails(spaceaddress)}
        </Text>
        <Text variant="bold" size={3} color={COLORS.PRIMARY_COLOR} style={{ textAlign: 'left' }}>
          {moment(startTime).calendar()}
        </Text>
      </View>
    </Pressable>
  );
}
const styles = StyleSheet.create({
  container: {
    padding: 16,
    flexDirection: 'row',
    // elevation: 8,
    // shadowColor: 'grey',
    // shadowOffset: { width: 0, height: 0 },
    // shadowRadius: 8,
    // shadowOpacity: 0.3,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.19,
    shadowRadius: 5.62,
    elevation: 8,
    borderRadius: 8,
    marginHorizontal: 4,
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
    margin: 16,
    // borderWidth: 1,
    // borderColor: COLORS.HALF_WHITE,
  },
  imageStyle: {
    width: 96,
    height: 96,
    borderRadius: 12,
  },
  contentStyle: {
    flexDirection: 'column',
    flex: 1,
    // justifyContent: 'space-between',
    rowGap: 4,
    marginLeft: 16,
  },
});

export default SpaceCardSmall;
