import moment from 'moment-timezone';
import { useState } from 'react';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import { DatePickerModal } from 'react-native-paper-dates';
import DateItem from './DateItem';

const DatePicker = ({ pickerMode, onSelect, date, disableEdit = false }) => {
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const showDateModal = () => {
    if (disableEdit) return;
    setDatePickerVisibility(true);
    // setPickerMode('date');
  };

  const showTimeModal = () => {
    if (disableEdit) return;
    setDatePickerVisibility(true);
    // setPickerMode('time');
  };

  const hideDateModal = () => {
    setDatePickerVisibility(false);
  };

  const onSelectDateTime = time => {
    setDatePickerVisibility(false);
    onSelect(pickerMode, moment(time.date).valueOf());
    // if (pickerMode === 'date') {
    //   setBookingDate(time);
    // } else {
    //   setSelectedTime(time);
    // }
  };
  return (
    <>
      <DateItem
        onClick={pickerMode === 'time' ? showTimeModal : showDateModal}
        icon="calendar"
        label={moment(date).format(pickerMode === 'time' ? 'LT' : 'D MMM')}
      />
      {/* <DateTimePickerModal
        isVisible={isDatePickerVisible}
        mode={pickerMode}
        onConfirm={onSelectDateTime}
        onCancel={hideDateModal}
        date={date}
      /> */}
      <DatePickerModal
        locale="en"
        mode="single"
        visible={isDatePickerVisible}
        onDismiss={hideDateModal}
        date={date}
        onConfirm={onSelectDateTime}
      />
    </>
  );
};
export default DatePicker;
