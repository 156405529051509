import { registerRootComponent } from 'expo';

import { CodePush } from './src/utils/codepush';

import App from './App';

import './src/utils/messaging';

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(CodePush(App));
