import { Platform, Image as RNImage } from 'react-native';
import _endsWith from 'lodash/endsWith';
import { SvgUri } from 'react-native-svg';

const Image = ({ url, width, height }) => {
  if (Platform.OS !== 'web') {
    if (_endsWith(url, 'svg')) {
      return <SvgUri width={width} height={height} uri={url} />;
    }
    return <RNImage source={{ uri: url }} style={{ width, height }} resizeMode="contain" />;
  }
  return <RNImage source={{ uri: url }} style={{ width, height }} resizeMode="contain" />;
};

export default Image;
