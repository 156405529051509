import { View, StyleSheet, ScrollView, Image as RNImage, Pressable, Platform } from 'react-native';
import _get from 'lodash/get';

import { IMAGES } from '../../assets/images';
import { COLORS } from '../../constants/colors';
import Text from '../Text';
import { useMemo } from 'react';
import { useLinkProps } from '@react-navigation/native';
import { ROUTES } from '../../constants/routes';
import Image from '../Image';

const UserProfileCard = ({ user, organization }) => {
  let { onPress, ...props } = useLinkProps({ to: { screen: ROUTES.MY_ORGANISATION.name } });
  // If the user is not a organization admin then dont show the credits screen
  if (! _get(user, 'isOrganizationAdmin')) {
    onPress = () => {};
  }
  const Container = Platform.OS === 'web' ? View : Pressable;
  let onClick = Platform.OS === 'web' ? { onClick: onPress } : { onPress };

  const regularUser = useMemo(
    () => (
      <View style={styles.userInfo}>
        <Image url={_get(organization, 'logoURL')} width={120} height={40} />
        <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY} style={{ alignSelf: 'flex-start' }}>
          {_get(user, 'displayName', '')}
        </Text>
        <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY} style={{ alignSelf: 'flex-start' }}>
          {_get(user, 'phoneNumber', '')}
        </Text>
        <Text size={3} variant="bold" color={COLORS.PRIMARY_COLOR} style={{ alignSelf: 'flex-start' }}>
          {_get(organization, 'name')}
        </Text>
      </View>
    ),
    [user, organization]
  );

  if (false) {
    onClick = () => {};
  }

  return (
    <Container style={styles.userInfoContainer} {...onClick}>
      {regularUser}
      <RNImage source={IMAGES.WAVE_VERTICAL} resizeMode="contain" style={styles.waveImageStyle} />
    </Container>
  );
};

const styles = StyleSheet.create({
  userInfoContainer: {
    borderRadius: 12,
    backgroundColor: COLORS.PROFILE_CARD_BACKGROUND,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: 170,
    overflow: 'hidden',
  },
  userInfo: {
    flex: 1,
    padding: 20,
    rowGap: 10,
  },
  companyLogo: {
    width: 120,
    height: 40,
  },
  waveImageStyle: {
    width: 120,
    height: 170,
    transform: [{ scale: 1.5 }],
  },
});

export default UserProfileCard;
