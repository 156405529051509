import { Linking } from 'react-native';

export const onChatWithUsClick = () => {
  // Linking.openURL('https://api.whatsapp.com/send?phone=918277995925');
  try {
    $zoho.salesiq.floatwindow.visible('show');
    // console.log('chat init web');
    // console.log($zoho);
  } catch (error) {
    console.log({ error });
  }
};

export const initSalesIq = () => {};
