import { createSlice } from '@reduxjs/toolkit';
import set from 'lodash/set';
import { logoutUserAction } from '../commonActions';

const INITIAL_STATE = {
  spaces: {},
  loadingSpaces: true,
};

const spacesSlice = createSlice({
  name: 'spaces',
  initialState: INITIAL_STATE,
  reducers: {
    setSpaces(state, { payload }) {
      set(state, 'spaces', payload);
      set(state, 'loadingSpaces', false);
    },
    setSpacesLoading(state, { payload }) {
      set(state, 'loadingSpaces', payload);
    },
    clearSpaces(state) {
      set(state, 'spaces', []);
      set(state, 'loadingSpaces', true);
    },
  },
  extraReducers: {
    [logoutUserAction]: () => INITIAL_STATE,
  },
});

export const { setSpaces, setSpacesLoading, clearSpaces } = spacesSlice.actions;
export default spacesSlice.reducer;
