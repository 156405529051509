import { Pressable } from 'react-native';
import { Feather } from '@expo/vector-icons';

import IconLabelCard from '../IconLabelCard';

const DateItem = ({ onClick, label, style, icon }) => (
  <Pressable onPress={onClick}>
    <IconLabelCard
      label={label}
      iconFamily={Feather}
      iconProps={{ name: icon, size: 16 }}
      containerStyle={style}
      labelProps={{ size: 0 }}
    />
  </Pressable>
);

export default DateItem;
