import { View, Dimensions, StyleSheet } from 'react-native';

import ContentLoader, { Rect, Circle, Path } from 'react-content-loader/native';
import { COLORS } from '../../constants/colors';
import { SPACING } from '../../constants/common';

const { width } = Dimensions.get('window');

const CARD_WIDTH = width - SPACING * 2.5;

const IMAGE_HEIGHT = 220;
const SPACE_NAME = 21;
const ADDRESS = 21;
const PRICE = 21;
const SPACE = 12;
const TOTAL_HEIGHT = IMAGE_HEIGHT + SPACE_NAME + ADDRESS + PRICE + SPACE * 3 + 16;

export default () => (
  <View style={styles.container}>
    <ContentLoader
      speed={1}
      width={CARD_WIDTH}
      height={TOTAL_HEIGHT}
      viewBox={`0 0 ${CARD_WIDTH} ${TOTAL_HEIGHT}`}
      backgroundColor={COLORS.SKELETON.FOREGROUND}
      foregroundColor={COLORS.SKELETON.BACKGROUND}>
      <Rect x="0" y="0" rx="12" ry="2" width={CARD_WIDTH} height={IMAGE_HEIGHT} />
      <Rect x={SPACE} y={IMAGE_HEIGHT + SPACE} rx="2" ry="2" width="230" height="21" />
      <Rect x={SPACE} y={IMAGE_HEIGHT + SPACE_NAME + SPACE * 2} rx="2" ry="2" width="150" height="18" />
      <Rect
        x={CARD_WIDTH - 60 - SPACE}
        y={IMAGE_HEIGHT + SPACE_NAME + SPACE * 2}
        rx="2"
        ry="2"
        width="60"
        height="20"
      />

      <Rect x={SPACE} y={IMAGE_HEIGHT + SPACE_NAME + ADDRESS + SPACE * 3} rx="2" ry="2" width="82" height="21" />
      <Rect
        x={CARD_WIDTH - 70 - SPACE}
        y={IMAGE_HEIGHT + SPACE_NAME + ADDRESS + SPACE * 3}
        rx="2"
        ry="2"
        width="70"
        height="18"
      />
    </ContentLoader>
  </View>
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
    elevation: 8,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 4,
    shadowOpacity: 0.2,
    borderRadius: 12,
    marginHorizontal: 4,
  },
});
