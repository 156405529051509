import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { Fontisto } from '@expo/vector-icons';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { WebView } from 'react-native-webview';

import { COLORS } from '../../constants/colors';

const ViewVideo = ({ route, navigation }) => {
  const { videoUrl } = route.params;
  const insets = useSafeAreaInsets();

  const onClose = () => {
    navigation.goBack();
  };

  return (
    <View style={[styles.container, { paddingTop: insets.top + 48 }]}>
      <WebView
        style={{
          flex: 1,
          backgroundColor: 'transparent',
        }}
        source={{
          uri: videoUrl,
        }}
        javaScriptEnabled
      />
      <Fontisto
        name="angle-left"
        size={16}
        color={COLORS.WHITE}
        onPress={onClose}
        style={[styles.backIcon, { top: insets.top + 12, padding: 8 }]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
    justifyContent: 'center',
    paddingTop: 20,
  },
  backIcon: {
    position: 'absolute',
    top: 20,
    left: 12,
  },
  loader: {
    flex: 1,
  },
});

export default ViewVideo;
