import React from 'react';
import { StatusBar, StyleSheet } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import 'react-native-get-random-values';
import { Provider } from 'react-redux';
import { Provider as PaperProvider } from 'react-native-paper';
import { ToastProvider } from 'react-native-toast-notifications';
import { PopupProvider } from 'react-native-relative-popup';
import { PortalProvider } from '@gorhom/portal';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { PersistGate } from 'redux-persist/integration/react';
import { useFonts } from 'expo-font';
import '@expo/match-media';

import SplashScreen from './src/screens/splashScreen';
// import MainNavigator from './src/navigation';
import { AppNavigator } from './src/navigation';
import configureStore from './src/store';
import GlobalStyles from './GlobalStyles';
import FirebaseDBListeners from './src/FirebaseDBListeners';
import RestrictDevices from './src/components/RestrictDevices';
import { configureSentry, overrideConsole } from './src/utils/common';
import ErrorBoundary from './src/components/errorBoundary';

configureSentry();
overrideConsole();

export default function App() {
  const [fontsLoaded] = useFonts({
    'PlusJakartaSans-Bold': require('./src/assets/fonts/PlusJakartaSans-Bold.ttf'),
    'PlusJakartaSans-Medium': require('./src/assets/fonts/PlusJakartaSans-Medium.ttf'),
    'PlusJakartaSans-Regular': require('./src/assets/fonts/PlusJakartaSans-Regular.ttf'),
    'PlusJakartaSans-SemiBold': require('./src/assets/fonts/PlusJakartaSans-SemiBold.ttf'),
  });
  if (!fontsLoaded) {
    return <SplashScreen />;
  }

  const { store, persistor } = configureStore();

  return (
    <RestrictDevices>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary>
            <GestureHandlerRootView style={{ flex: 1 }}>
              <PaperProvider>
                <ToastProvider offsetBottom={60}>
                  <PopupProvider>
                    <PortalProvider>
                      <StatusBar barStyle="dark-content" />
                      <SafeAreaProvider style={styles.container}>
                        <FirebaseDBListeners>
                          <AppNavigator />
                          <GlobalStyles css="input {outline: none;}" />
                        </FirebaseDBListeners>
                      </SafeAreaProvider>
                    </PortalProvider>
                  </PopupProvider>
                </ToastProvider>
              </PaperProvider>
            </GestureHandlerRootView>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </RestrictDevices>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
