import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, ActivityIndicator, FlatList } from 'react-native';
import { connect } from 'react-redux';
import { Entypo } from '@expo/vector-icons';
import { useToast } from 'react-native-toast-notifications';
import _get from 'lodash/get';
import _size from 'lodash/size';

import Header from '../../components/Header';
import { COLORS } from '../../constants/colors';
import { SPACING } from '../../constants/common';
import { addFavoriteCoworker, getFavoriteCoworkers } from '../../api/common';
import { auth } from '../../../firebaseConfig';
import Separator from '../../components/Separator';
import Text from '../../components/Text';
import InviteTeam from '../../components/inviteTeam';
import { getFavoriteCoworkerPayload } from '../../utils/common';

const FavoriteCoworker = ({ user }) => {
  const [gettingFavoriteCoworkers, setGettingFavoriteCoworkers] = useState(true);
  const [favoriteCoworkers, setFavoriteCoworkers] = useState([]);
  const toast = useToast();
  const uid = _get(user, 'uid');
  const { currentUser = null } = auth;

  async function fetchFavoriteCoworkers() {
    setGettingFavoriteCoworkers(true);
    try {
      const coworkers = await getFavoriteCoworkers(uid);
      setFavoriteCoworkers(coworkers);
    } catch (error) {
      toast.show('Error getting favorite coworkers. Please try again.');
    } finally {
      setGettingFavoriteCoworkers(false);
    }
  }

  useEffect(() => {
    if (uid && currentUser?.getIdToken()) fetchFavoriteCoworkers();
  }, [uid, currentUser]);

  const onAddTeamPress = async (selectedMembers, setInvitingMembers, onCancel) => {
    if (_size(selectedMembers) > 0) {
      setInvitingMembers(true);
      try {
        const payload = getFavoriteCoworkerPayload(user, selectedMembers);
        await addFavoriteCoworker(payload);
        toast.show('Coworkers added to favorites successfully');
        onCancel();
        fetchFavoriteCoworkers();
      } catch (error) {
        console.log({ error });
        toast.show('Error adding favorite coworkers. Please try again.');
      } finally {
        setInvitingMembers(false);
      }
    } else {
      toast.show('Please select team members to add.');
    }
  };

  const addFavoriteCoworkerView = useMemo(
    () => (
      <InviteTeam
        user={user}
        onSavePress={onAddTeamPress}
        saveButtonLabel="Add"
        customView={<Entypo name="plus" size={24} color={COLORS.PRIMARY_COLOR} />}
      />
    ),
    []
  );

  const renderItem = useCallback(
    ({ item }) => (
      <View style={styles.favoriteCoworker}>
        <Text variant="semiBold" size={2}>
          {_get(item, 'displayName')}
        </Text>
        <Text variant="medium" size={3}>
          {_get(item, 'orgTeamName')}
        </Text>
      </View>
    ),
    []
  );

  const separator = useCallback(() => <Separator color={COLORS.PRIMARY_BACKGROUND} gap={8} />, []);

  return (
    <>
      <Header title="Favorite Coworkers" rightBarButtons={[addFavoriteCoworkerView]} />
      {gettingFavoriteCoworkers ? (
        <View style={[styles.container, styles.containerPadding, styles.centerView]}>
          <ActivityIndicator />
        </View>
      ) : (
        <View style={[styles.container, styles.containerPadding]}>
          <FlatList
            data={favoriteCoworkers}
            renderItem={renderItem}
            keyExtractor={item => _get(item, 'uid')}
            ItemSeparatorComponent={separator}
          />
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
  },
  containerPadding: {
    paddingHorizontal: SPACING,
    paddingTop: SPACING,
  },
  centerView: {
    flex: 1,
    alignItems: 'center',
  },
  favoriteCoworker: {
    borderWidth: 2,
    borderColor: COLORS.PRIMARY_BORDER_COLOR,
    borderRadius: 8,
    backgroundColor: '#f5f5f5',
    padding: 12,
    rowGap: 4,
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(FavoriteCoworker);
