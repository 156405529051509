import React, { useCallback, useMemo, useState } from 'react';
import { View, Image, StyleSheet, Dimensions, Pressable } from 'react-native';
import moment from 'moment-timezone';
import { useToast } from 'react-native-toast-notifications';
import _size from 'lodash/size';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';

import { COLORS } from '../../constants/colors';
import { SPACING } from '../../constants/common';
import Text from '../Text';
import { getSpaceDetails, isOfficeSpace } from '../../utils/common';
import { ROUTES } from '../../constants/routes';
import Button from '../Button';
import { addUserToEventForMeetingRoomBooking } from '../../api/common';

const { width } = Dimensions.get('window');

function SpaceEventCard({
  event = {
    bookingId: 'GF1123045103',
    bookingKey: '-NjsU3cL9MmLaXJ6au_M',
    createdBy: '287kKs4SCcUqK4zNrIoZmMzEPD52',
    createdOn: 1700938244271,
    eventDescription: 'Test event description',
    eventId: '-Nk6oT-lrZuD5v9KkcCM',
    eventName: 'Test event',
    organizationId: 'gofloaters',
    originalName: 'GoFloaters',
    spaceDisplayName: 'HQ Desk Space',
    spaceId: '-MkPuuQWVk1tWhHjiUg4',
    spacePhoto:
      'https://cdn.app.gofloaters.com/images%2FHQ%20Desk%20Space_1694063916147?alt=media&token=95b8b607-f84e-4588-bf0d-fe3f341c097b',
    spaceType: 'Shared Workspace',
    spaceaddress: {
      city: 'Bengaluru',
      country: 'India',
      landmark: 'Airtel Tower',
      locality: 'MG Road',
      street: '6, MG Road',
      zipcode: '600037',
    },
    startTime: 1701138600000,
  },
  navigation,
  user,
  refreshEvents = _noop,
}) {
  const { spacePhoto, eventName, eventId, spaceId, originalName, spaceaddress, startTime, attendees } = event;
  const toast = useToast();
  const [joiningEvent, setJoiningEvent] = useState(false);
  const userJoinedEvent = useMemo(() => {
    const uid = _get(user, 'uid');
    const attendeeUids = _map(attendees, attendee => _get(attendee, 'uid'));
    return _includes(attendeeUids, uid);
  }, [attendees, user]);

  const onJoinNowPress = useCallback(async () => {
    if (isOfficeSpace(event)) {
      navigation.navigate(ROUTES.SPACE_BOOKINGS.name, { spaceId, eventId, timeStamp: startTime });
    } else {
      setJoiningEvent(true);
      const uid = _get(user, 'uid');
      const response = await addUserToEventForMeetingRoomBooking(eventId, uid);
      if (!_isEmpty(response)) {
        toast.show('Thanks for joining the event.');
        refreshEvents();
      } else toast.show('Error joining the event. Please try again');
      setJoiningEvent(false);
    }
  }, [navigation, eventId, spaceId, startTime, event, user, toast, refreshEvents]);

  const onCardPress = useCallback(() => {
    navigation.navigate(ROUTES.EVENT_DETAILS.name, { eventId });
  }, [navigation, eventId]);

  return (
    <Pressable style={styles.container} onPress={onCardPress}>
      <Image style={styles.spaceImageStyle} source={{ uri: spacePhoto }} resizeMode="cover" />
      <View style={styles.spaceInfoContainer}>
        <View style={styles.horizontalSpaceBetween}>
          <Text variant="bold" size={1}>
            {eventName}
          </Text>
          <Text variant="bold" size={3} color={COLORS.PRIMARY_COLOR}>
            {moment(startTime).calendar()}
          </Text>
        </View>

        <View style={styles.spaceDetailsContainer}>
          <View style={{ flex: 1 }}>
            <Text variant="medium" size={4} color={COLORS.PRIMARY_COLOR}>
              {originalName}, {getSpaceDetails(spaceaddress)}
            </Text>
            <Text variant="bold" size={3}>
              {`${_size(attendees)} Members Attending`}
            </Text>
          </View>
          {userJoinedEvent ? (
            <Text variant="bold" size={3}>
              Joined
            </Text>
          ) : (
            <Button
              variant="small"
              textProps={{ variant: 'bold', size: 3 }}
              style={styles.joinButtonStyle}
              loading={joiningEvent}
              onPress={onJoinNowPress}>
              Join
            </Button>
          )}
        </View>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
    elevation: 8,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 4,
    shadowOpacity: 0.2,
    borderRadius: 12,
    marginHorizontal: 4,
    borderWidth: 1,
    borderColor: COLORS.HALF_WHITE,
  },
  spaceImageStyle: {
    width: '100%',
    height: (1 / 1.78) * (width - SPACING * 3),
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  spaceInfoContainer: {
    padding: 12,
    rowGap: 4,
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
  },
  spaceDetailsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  joinButtonStyle: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  horizontalSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default SpaceEventCard;
