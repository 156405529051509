import { View, FlatList, ImageBackground } from 'react-native';
import { forwardRef, useRef } from 'react';
import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated';
import { Pagination } from './Pagination';

const Carousel = forwardRef(
  (
    {
      data,
      containerStyle,
      renderItem,
      keyExtractor,
      paginationWidth,
      paginationStyle = {},
      paginationDotStyle = {},
      paginationContainerStyle,
      hidePagination = false,
      onLayout = () => {},
    },
    ref
  ) => {
    // const scrollX = useRef(new Animated.Value(0)).current;
    const scrollX = useSharedValue(0);

    // const scrollHandler = useAnimatedScrollHandler(event => {
    //   scrollX.value = event.contentOffset.x;
    // });

    const scrollHandler = useAnimatedScrollHandler({
      onScroll: e => {
        scrollX.value = e.contentOffset.x;
      },
    });

    return (
      <View
        style={[{ position: 'relative' }, containerStyle]}
        onLayout={e => {
          onLayout({ height: e.nativeEvent.layout.height });
        }}>
        <Animated.FlatList
          ref={ref}
          style={[containerStyle]}
          contentContainerStyle={{ flexGrow: 1 }}
          data={data}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          horizontal
          pagingEnabled
          bounces={false}
          showsHorizontalScrollIndicator={false}
          onScroll={scrollHandler}
          // onScroll={Animated.event(
          //   // scrollX = e.nativeEvent.contentOffset.x
          //   [
          //     {
          //       nativeEvent: {
          //         contentOffset: {
          //           x: scrollX,
          //         },
          //       },
          //     },
          //   ],
          //   {
          //     useNativeDriver: false,
          //   }
          // )}
          scrollEventThrottle={32}
        />
        {!hidePagination ? (
          <View
            style={{ position: 'absolute', bottom: 16, right: 0, left: 0, alignItems: 'center', ...paginationStyle }}>
            <Pagination
              scrollX={scrollX}
              data={data}
              paginationWidth={paginationWidth}
              paginationDotStyle={paginationDotStyle}
              containerStyle={paginationContainerStyle}
            />
          </View>
        ) : null}
      </View>
    );
  }
);

export default Carousel;
