import { useCallback, useRef } from 'react';
import { FlatList, Image, Linking, Platform, Pressable, StyleSheet, View } from 'react-native';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import * as AddCalendarEvent from 'react-native-add-calendar-event';
import { useToast } from 'react-native-toast-notifications';
import _get from 'lodash/get';

import { COLORS } from '../../constants/colors';
import Text from '../Text';
import { CALENDAR_WEB_OPTIONS, CALENDAR_WEB_VALUES } from '../../constants/common';
import Separator from '../Separator';
import {
  getBookingEventDetails,
  getCalndarInviteURLForGoogle,
  getCalndarInviteURLForOutlook,
} from '../../utils/common';

const AddToCalendar = ({ bookingInfo }) => {
  const calendarSelectionRef = useRef(null);
  const toast = useToast();

  const onOpen = useCallback(() => {
    if (calendarSelectionRef?.current) {
      calendarSelectionRef.current?.open();
    }
  }, [calendarSelectionRef.current]);

  const onCancel = useCallback(() => {
    calendarSelectionRef.current?.close();
  }, [calendarSelectionRef.current]);

  const addToCalendarOnNative = () => {
    const eventConfig = getBookingEventDetails(bookingInfo);
    if (Platform.OS !== 'web') {
      AddCalendarEvent.presentEventCreatingDialog(eventConfig)
        .then(eventInfo => {
          if (_get(eventInfo, 'action') === 'SAVED') {
            toast.show('Event added successfully');
          }
        })
        .catch(() => {
          // handle error such as when user rejected permissions
          toast.show('Permission needed to add to calendar. Please provide permission from settings.');
        });
    }
  };

  const addToCalendarOnWeb = useCallback(
    selectedCalendar => {
      switch (selectedCalendar) {
        case CALENDAR_WEB_VALUES.GOOGLE:
          Linking.openURL(getCalndarInviteURLForGoogle(bookingInfo));
          break;

        case CALENDAR_WEB_VALUES.OUTLOOK:
          Linking.openURL(getCalndarInviteURLForOutlook(bookingInfo));
          break;

        default:
          break;
      }
      onCancel();
    },
    [bookingInfo]
  );

  const onAddToCalendarPress = () => {
    if (Platform.OS !== 'web') addToCalendarOnNative();
    else onOpen();
  };

  const renderCalendarOption = ({ item }) => (
    <Pressable
      style={styles.option}
      onPress={() => {
        addToCalendarOnWeb(_get(item, 'value'));
      }}>
      <Image source={_get(item, 'icon')} style={styles.iconStyle} />
      <Text size={3} variant="semiBold">
        {_get(item, 'title')}
      </Text>
    </Pressable>
  );

  const renderSeparator = () => <Separator />;
  const renderCalendarOptions = () => (
    <View style={{ marginBottom: 16 }}>
      <View style={styles.heading}>
        <Text variant="semiBold" size={1}>
          Select Calendar
        </Text>
      </View>
      <FlatList
        data={CALENDAR_WEB_OPTIONS}
        renderItem={renderCalendarOption}
        keyExtractor={item => _get(item, 'value')}
        ItemSeparatorComponent={renderSeparator}
        style={styles.bottomMargin}
      />
    </View>
  );
  return (
    <>
      <Text variant="semiBold" size={3} color={COLORS.BUTTON_COLORS.SECONDARY_COLOR} onPress={onAddToCalendarPress}>
        Add to Calendar {'>'}
      </Text>
      <Portal>
        <Modalize ref={calendarSelectionRef} adjustToContentHeight withHandle={false} modalStyle={{ padding: 16 }}>
          {renderCalendarOptions()}
        </Modalize>
      </Portal>
    </>
  );
};

const styles = StyleSheet.create({
  heading: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 16,
  },
  option: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
  },
  iconStyle: {
    width: 24,
    height: 24,
  },
});

export default AddToCalendar;
