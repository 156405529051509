const PRIMARY_COLOR = '#2798B5';
const SECONDARY_COLOR = '#7DC4D7';

const TEXT_COLOR_PRIMARY = '#101010';
const TEXT_COLOR_SECONDARY = '#878787';

export const COLORS = {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TEXT_COLOR_PRIMARY,
  TEXT_COLOR_SECONDARY,
  STATUS_COLORS: {
    SUCCESS: '#2DB866',
    FAILURE: '#D2673B',
  },
  PRIMARY_BACKGROUND: '#fff',
  PRIMARY_BORDER_COLOR: '#F1F0F3',
  BUTTON_COLORS: {
    SECONDARY_COLOR: '#7B61FF',
    TERTIARY_COLOR: '#F5F5F5',
  },
  WHITE: '#fff',
  HALF_WHITE: '#F8F8F9',
  BLACK: '#000',
  RED: '#EA5B5B',
  PROFILE_CARD_BACKGROUND: '#FFF6E7',
  NOTIFICATION_COLORS: {
    BOOKING_SUCCESS: '#2ED573',
    VISIT_COMPLETED: '#F3B923',
    BOOKING_FAILED: '#EA5B5B',
  },
  SKELETON: {
    FOREGROUND: '#e3e3e3',
    BACKGROUND: '#dfdddd',
  },
  LOGIN_BG_COLOR: '#2193B0',
  STAR_RATING: '#f1c40f',
  CATEGORY_BG: '#FAFAFA',
  CATEGORY_BORDER: '#A6A6A6',
};
