import { useEffect, useMemo } from 'react';
import { View, StyleSheet, ScrollView, Image, Pressable, Platform } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { useLinkProps, useNavigation } from '@react-navigation/native';
import { connect, useDispatch } from 'react-redux';

import _noop from 'lodash/noop';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _size from 'lodash/size';
// import { Link } from 'expo-router';
import { getProfileOptions } from '../../utils/profile';
import Text from '../../components/Text';
import { COLORS } from '../../constants/colors';
import Separator from '../../components/Separator';
import { IMAGES } from '../../assets/images';
import { signUserOut } from '../../utils/firebaseUtils';
import { logoutUserAction } from '../../commonActions';
import { UserProfileCard } from '../../components/cards';
import Header from '../../components/Header';
import { ROUTES } from '../../constants/routes';
// import { logEvent } from 'firebase/analytics';
// import { anaytics } from '../../../firebaseConfig';

const ProfileOption = ({ navigation, onPress: onOptionPress, children, title }) => {
  const dispatch = useDispatch();
  const navigateUserTo = useNavigation();
  let onItemPress = _noop;
  if (onOptionPress) onItemPress = onOptionPress;
  else onItemPress = () => navigateUserTo.navigate(navigation);
  // const { onPress, ...props } = useLinkProps({ to: { screen: navigation } });
  // if (Platform.OS === 'web') {
  //   if (navigation) {
  //     return (
  //       <View onClick={onPress} {...props}>
  //         {children}
  //       </View>
  //     );
  //   }
  //   if (title === 'Log out') {
  //     return (
  //       <Pressable
  //         onPress={async () => {
  //           console.log('logging out');
  //           await signUserOut();
  //           dispatch(logoutUserAction());
  //         }}>
  //         {children}
  //       </Pressable>
  //     );
  //   }
  //   return <>{children}</>;
  // }
  if (title === 'Log out') {
    return (
      <Pressable
        onPress={async () => {
          console.log('logging out');
          await signUserOut();
          dispatch(logoutUserAction());
        }}>
        {children}
      </Pressable>
    );
  }
  return <Pressable onPress={onItemPress}>{children}</Pressable>;
};

function Profile({ user, organization, navigation: mainNavigation }) {
  const profileOptions = getProfileOptions({ user, organization });
  // console.log("profileOptions",profileOptions);

  // useEffect(() => {
  //   logEvent(anaytics, 'page_view');
  // }, []);

  const onBackPress = () => {
    mainNavigation.navigate(ROUTES.TAB_NAVIGATOR.name);
  };

  return (
    <View style={styles.container}>
      <Header title="My Account" onBackPress={onBackPress} />
      <ScrollView style={{ paddingHorizontal: 16 }} showsVerticalScrollIndicator={false}>
        <UserProfileCard user={user} organization={organization} />
        {_map(profileOptions, (profileOption, sectionIndex) => (
          <View key={`${_get(profileOption, 'section')}${sectionIndex}`}>
            <Text size={3} style={styles.sectionTitle} variant="medium">
              {_get(profileOption, 'section')}
            </Text>
            {_map(
              _get(profileOption, 'options'),
              ({ title, iconName, iconFamily: IconFamily, iconSize, color, navigation, onPress }, index) => (
                <ProfileOption title={title} navigation={navigation} onPress={onPress} key={`${title} - ${index}`}>
                  <View key={`${title}-${index}`}>
                    <View style={styles.profileOption}>
                      <View style={styles.profileOptionIcon}>
                        <IconFamily name={iconName} size={iconSize} color={color} />
                      </View>
                      <Text size={3} style={styles.profileOptionTitle} color={color}>
                        {title}
                      </Text>
                      {navigation ? (
                        <MaterialIcons name="arrow-forward-ios" size={12} color={COLORS.TEXT_COLOR_SECONDARY} />
                      ) : null}
                    </View>
                    {index !== _size(_get(profileOption, 'options')) - 1 ? <Separator gap={0} /> : null}
                  </View>
                </ProfileOption>
              )
            )}
          </View>
        ))}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
  },
  userInfoContainer: {
    borderRadius: 12,
    backgroundColor: COLORS.PROFILE_CARD_BACKGROUND,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: 170,
    overflow: 'hidden',
  },
  userInfo: {
    flex: 1,
    padding: 20,
    rowGap: 10,
  },
  profileOption: {
    flexDirection: 'row',
    paddingVertical: 20,
    alignItems: 'center',
  },
  profileOptionTitle: {
    flex: 1,
    marginLeft: 12,
    textAlign:'left'
  },
  profileOptionIcon: {
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionTitle: {
    marginVertical: 12,
    alignSelf: 'flex-start'
  },
  companyLogo: {
    width: 64,
    height: 32,
  },
  waveImageStyle: {
    width: 120,
    height: 170,
    transform: [{ scale: 1.5 }],
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
  organization: state.user.organization,
});

export default connect(mapStateToProps)(Profile);
