import { View, Image, StyleSheet, Dimensions, Pressable } from 'react-native';
import _map from 'lodash/map';
import _head from 'lodash/head';

import { COLORS } from '../../constants/colors';
import { SPACING,MAXDEVICEWIDTH } from '../../constants/common';
import Text from '../Text';
import { ROUTES } from '../../constants/routes';
import { getSpaceDetails } from '../../utils/common';

const { width } = Dimensions.get('window');

const QuickBooking = ({ timeStamp, timeSlot, spaceId, navigation }) => {
  const onSlotPress = () => {
    navigation.navigate(ROUTES.SPACE_BOOKINGS.name, { spaceId, timeStamp });
  };

  return (
    <Text
      style={styles.actionClickstyle}
      variant="semiBold"
      size={4}
      onPress={onSlotPress}
      color={COLORS.PRIMARY_COLOR}>
      {timeSlot}
    </Text>
  );
};

function SpaceCardwithActions({ space = {}, navigation }) {
  const { originalName, address, photos, timeSlots, spaceId, gofloatersSpaceName } = space;

  const onCardPress = () => {
    navigation.navigate(ROUTES.SPACE_DETAILS.name, { spaceId });
  };

  return (
    <Pressable style={styles.container} onPress={onCardPress}>
      <Image style={styles.spaceImageStyle} source={{ uri: _head(photos) }} resizeMode="cover" />
      <View style={styles.spaceInfoContainer}>
        <Text variant="bold" size={1} style={styles.spaceNameStyle}>
          {gofloatersSpaceName} - {originalName}
        </Text>
        <Text variant="medium" size={4} color={COLORS.TEXT_COLOR_SECONDARY} style={styles.spaceNameStyle}>
          {getSpaceDetails(address)}
        </Text>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <Text variant="semiBold" size={4} color={COLORS.PRIMARY_COLOR} style={{ flexDirection: 'row' }}>
            {'Quick Book:   \n'}
          </Text>
        </View>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {_map(timeSlots.slice(0, 2), ({ timeSlot, timeStamp = '1684345181000' }) => (
            <QuickBooking
              timeSlot={timeSlot}
              timeStamp={timeStamp}
              spaceId={spaceId}
              key={timeSlot}
              navigation={navigation}
            />
          ))}
        </View>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
    elevation: 8,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 4,
    shadowOpacity: 0.2,
    borderRadius: 12,
    marginHorizontal: 4,
    marginBottom: 16,
    padding: 0,
  },
  spaceImageStyle: {
    width: '100%',
    height: (1 / 1.78) * ((width > MAXDEVICEWIDTH? MAXDEVICEWIDTH : width) - SPACING * 3 - 4),
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    overflow: 'hidden',
  },
  spaceInfoContainer: {
    padding: 12,
    rowGap: 4,
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
  },
  actionClickstyle: {
    marginRight: 8,
    textDecorationLine: 'underline',
  },
  spaceNameStyle: {
    textAlign:'left'
  },
});

export default SpaceCardwithActions;
