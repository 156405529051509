import { useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';

import _isNull from 'lodash/isNull';
import Text from './Text';
import { getIsDeviceRooted } from '../utils/platform';
import { MAXDEVICEWIDTH } from '../constants/common';

const RestrictDevices = ({ children }) => {
  const [isDeviceRooted, setIsDeviceRooted] = useState(null);
  const isSupportedWidth = useMediaQuery({
    maxDeviceWidth: MAXDEVICEWIDTH,
  });

  useEffect(() => {
    setIsDeviceRooted(getIsDeviceRooted());
  }, []);

  if (_isNull(isDeviceRooted)) return null;

  if (isDeviceRooted === true)
    return (
      <View style={styles.container}>
        <Text variant="semiBold" size={2}>
          Worqflexi cannot be used in a rooted device
        </Text>
      </View>
    );

  if (isSupportedWidth || Platform.OS !== 'web') return children;
  return (
    // <View style={styles.container}>
    //   <Text variant="semiBold" size={2}>
    //     This app is not optimized for desktop yet. Please access it from a mobile device.
    //   </Text>
    // </View>
    <View style={styles.container}>
      <View style={styles.contentContainer}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    // backgroundColor: 'white',
  },
  contentContainer: {
    width: MAXDEVICEWIDTH,
    maxWidth: MAXDEVICEWIDTH,
    height: '100%',
  },
});

export default RestrictDevices;
