import React from 'react';
import * as Sentry from '@sentry/react-native';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import Text from '../Text';

// const ErrorBoundary = ({ children, user }) => {
//   const logError = (error, info) => {
//     console.log({ sentryError: error });
//     const userObj = {
//       email: _get(user, 'email'),
//       username: _get(user, 'displayName'),
//       id: _get(user, 'uid'),
//       userId: _get(user, 'uid'),
//     };
//     Sentry.setExtras(info);
//     Sentry.setUser(user);
//     Sentry.captureMessage(`${userObj.id} - ${userObj.email}`, 'fatal');
//   };
//   return <RErrorBoundary onError={logError}>{children}</RErrorBoundary>;
// };

// const mapStateToProps = state => ({
//   user: state.user.user,
// });

// export default connect(mapStateToProps)(ErrorBoundary);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { user } = this.props;
    // You can also log the error to an error reporting service
    const userObj = {
      email: _get(user, 'email'),
      username: _get(user, 'displayName'),
      id: _get(user, 'uid'),
      userId: _get(user, 'uid'),
    };
    Sentry.setExtras(errorInfo);
    Sentry.setUser(userObj);
    Sentry.captureMessage(error);
  }

  render() {
    if (this.state.hasError) return <Text>Error</Text>;
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(ErrorBoundary);
