import { useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { Foundation } from '@expo/vector-icons';
import _map from 'lodash/map';

import Text from './Text';
import Button from './Button';
import { COLORS } from '../constants/colors';
import { WHATS_NEW } from '../constants/whatsNew';
import { updateWhatsNew as updateWhatsNewAction } from '../reducers/user';
import { APP_VERSION } from '../constants/common';

const WhatsNew = ({ updateWhatsNew }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const modalizeRef = useRef(null);

  useEffect(() => {
    setModalOpen(true);
  }, []);

  useEffect(() => {
    if (modalOpen) onOpen();
  }, [modalOpen]);

  const onOpen = () => {
    if (modalizeRef?.current) {
      modalizeRef.current?.open();
    }
  };

  const onCancel = () => {
    modalizeRef.current?.close();
    updateWhatsNew({ version: APP_VERSION });
  };

  return (
    <Portal>
      <Modalize
        ref={modalizeRef}
        adjustToContentHeight
        withHandle={false}
        closeOnOverlayTap={false}
        modalStyle={{ padding: 16 }}>
        <View style={styles.marginBottom}>
          <View style={styles.headingContainer}>
            <Text size={0} variant="semiBold">
              What is New
            </Text>
            <Foundation name="burst-new" size={32} color={COLORS.PRIMARY_COLOR} />
          </View>
          {_map(WHATS_NEW, whatsNew => (
            <View style={styles.pointContainer}>
              <Text variant="medium" style={{ alignSelf: 'flex-start',textAlign: 'left' }}>&#x2022; &nbsp;{whatsNew}</Text>
            </View>
          ))}
        </View>
        <Button variant="medium" style={{ marginBottom: 8, marginTop: 32 }} onPress={onCancel}>
          Close
        </Button>
      </Modalize>
    </Portal>
  );
};

const styles = StyleSheet.create({
  marginBottom: {
    marginBottom: 16,
  },
  pointContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginHorizontal: 8,
    marginBottom: 8,
  },
  headingContainer: {
    marginVertical: 16,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 4,
  },
});

export default connect(null, {
  updateWhatsNew: updateWhatsNewAction,
})(WhatsNew);
