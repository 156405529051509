import { StyleSheet, View, TextInput as RNTextinput } from 'react-native';
import { COLORS } from '../constants/colors';
import Text from './Text';

function TextInput({ leftIcon, rightIcon, containerStyle, style, label, editable = true, ...rest }) {
  return (
    <>
      {label ? (
        <Text style={{ marginBottom: 4,textAlign: 'left' }} size={3} color={COLORS.TEXT_COLOR_SECONDARY} variant="semiBold">
          {label}
        </Text>
      ) : null}
      <View style={[styles.container, containerStyle]}>
        {leftIcon ? <View style={styles.leftIconStyle}>{leftIcon}</View> : null}
        <RNTextinput
          placeholderTextColor={COLORS.TEXT_COLOR_SECONDARY}
          style={[styles.textInputDefaultStyle, style]}
          editable={editable}
          {...rest}
        />
        {rightIcon ? <View style={styles.rightIconStyle}>{rightIcon}</View> : null}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 45,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 12,
    borderWidth: 1,
    borderColor: COLORS.PRIMARY_BORDER_COLOR,
    borderRadius: 8,
  },
  leftIconStyle: {
    marginRight: 10,
  },
  rightIconStyle: {
    marginLeft: 10,
  },
  textInputDefaultStyle: {
    flex: 1,
  },
});

export default TextInput;
