import { useCallback, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _map from 'lodash/map';

import { COLORS } from '../../constants/colors';
import Map from '../../components/maps/Mapview';
import { getWhereOrgIs } from '../../api/common';
import { MAP_DATES, MAP_DATES_OPTIONS } from '../../constants/common';
import Button from '../../components/Button';
import { getOrganizationId, getShowMemberNames } from '../../selectors/organization';
import TeamViewCard from '../../components/teamViewCard';
import {
  getOperationalBookingDateAndTimeFromTimestamp,
  getTimestampForOrgView,
  getTimestampFromDateAndTime,
} from '../../utils/common';
import { ROUTES } from '../../constants/routes';

const TeamView = ({ organizationId, user, navigation, showMemberNames }) => {
  const [orgMembers, setOrgMembers] = useState([]);
  const [selectedDay, setSelectedDay] = useState(MAP_DATES.TODAY);
  const [selectedSpace, setSelectedSpace] = useState(null);
  useEffect(() => {
    async function fetchWhereIsOrg() {
      try {
        const response = await getWhereOrgIs(organizationId, getTimestampForOrgView(selectedDay));
        // const response = await getWhereOrgIs('chargebee', getTimestampForOrgView(selectedDay));
        // const response = await getWhereOrgIs('chargebee', 1688047123000);
        setOrgMembers(_get(response, 'data', []));
      } catch {
        // log the error
      }
    }
    fetchWhereIsOrg();
  }, [organizationId, selectedDay, user]);

  const dayPickerView = useMemo(
    () => (
      <View style={styles.daySelection}>
        {_map(MAP_DATES_OPTIONS, ({ label, value }) => (
          <Button
            key={value}
            variant="small"
            style={[
              styles.daySelectionButton,
              { backgroundColor: value === selectedDay ? COLORS.PRIMARY_COLOR : COLORS.HALF_WHITE },
            ]}
            textProps={{ color: value === selectedDay ? COLORS.WHITE : COLORS.BLACK, style: { letterSpacing: 0 } }}
            onPress={() => {
              setSelectedSpace(null);
              setSelectedDay(value);
            }}>
            {label}
          </Button>
        ))}
      </View>
    ),
    [selectedDay]
  );

  const onJoinNowPress = useCallback(
    details => {
      // console.log({
      //   selectedDay,
      //   timeStampForday: getTimestampForOrgView(selectedDay),
      //   bookingDateTime: getOperationalBookingDateAndTimeFromTimestamp(details, getTimestampForOrgView(selectedDay)),
      //   timeStamp: getTimestampFromDateAndTime(
      //     getOperationalBookingDateAndTimeFromTimestamp(details, getTimestampForOrgView(selectedDay))
      //   ),
      // });

      navigation.navigate(ROUTES.SPACE_BOOKINGS.name, {
        spaceId: _get(details, 'spaceId'),
        timeStamp: getTimestampFromDateAndTime(
          getOperationalBookingDateAndTimeFromTimestamp(details, getTimestampForOrgView(selectedDay))
        ),
      });
    },
    [selectedDay]
  );

  const teamViewDetail = useMemo(() => {
    if (selectedSpace)
      return (
        <View style={styles.spaceDetails}>
          <TeamViewCard
            spaceDetails={selectedSpace}
            onJoinNowPress={onJoinNowPress}
            showMemberNames={showMemberNames}
          />
        </View>
      );
    return null;
  }, [selectedSpace, onJoinNowPress]);

  const onMarkerPress = spaceDetails => {
    setSelectedSpace(spaceDetails);
  };

  return (
    <View style={styles.container}>
      {dayPickerView}
      <Map
        members={orgMembers}
        initial={{
          latitude: 20.5937,
          longitude: 78.9629,
        }}
        showMemberNames
        onMarkerPress={onMarkerPress}
      />
      {teamViewDetail}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
  },
  daySelection: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    columnGap: 16,
    position: 'absolute',
    left: 0,
    right: 0,
    top: Platform.OS === 'ios' ? 55 : 16,
    zIndex: 1,
  },
  daySelectionButton: {
    flex: 1,
  },
  spaceDetails: {
    position: 'absolute',
    right: 16,
    left: 16,
    bottom: 16,
  },
});

const mapStateToProps = state => ({
  organizationId: getOrganizationId(state),
  showMemberNames: getShowMemberNames(state),
  user: state.user.user,
});

export default connect(mapStateToProps)(TeamView);
