import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import _get from 'lodash/get';
import { COLORS } from '../constants/colors';

// Zoho Sales Iq Script:
const useScript = (url, widgetCode, user) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');

    const code = `
    var $zoho=$zoho || {};
    $zoho.salesiq = $zoho.salesiq || {
      widgetcode: "${widgetCode}",
       values:{},
       ready:function(){
        $zoho.salesiq.floatbutton.visible("hide"); 
        $zoho.salesiq.visitor.email("${_get(user, 'email')}");
        $zoho.salesiq.visitor.contactnumber("${_get(user, 'phoneNumber')}");
        $zoho.salesiq.visitor.name("${_get(user, 'displayName')}");
        $zoho.salesiq.chat.theme("${COLORS.PRIMARY_COLOR}");
       }
      };
      var d=document;
      s=d.createElement("script");
      s.type="text/javascript";
      s.id="zsiqscript";
      s.defer=true;
      s.src="${url}";
      t=d.getElementsByTagName("script")[0];
      t.parentNode.insertBefore(s,t);
      d.innerHTML = "<div id='zsiqwidget'></div>";`;

    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default function SalesIQ({ user }) {
  if (Platform.OS === 'web')
    return (
      <>
        {useScript(
          'https://salesiq.zoho.com/widget',
          'b44432658a542d07bc19f8bc6ddde1c5e3cc84b7211b8c517f5681b97fc19cf1',
          user
        )}
      </>
    );
  return null;
}
