import { FontAwesome5 } from '@expo/vector-icons';
import messaging from '@react-native-firebase/messaging';
import notifee from '@notifee/react-native';

import _lowercase from 'lodash/lowerCase';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import { Alert, PermissionsAndroid } from 'react-native';
import { COLORS } from '../constants/colors';
import { updatePushNotificationToken } from './firebaseUtils';

export const getNotificationImageStyle = (notificationType, notificationText) => {
  if (_includes(_lowercase(notificationText), _lowercase('Booking Confirmed'))) {
    return {
      backgroundColor: COLORS.NOTIFICATION_COLORS.BOOKING_SUCCESS,
      icon: <FontAwesome5 name="calendar-check" size={18} color={COLORS.WHITE} />,
    };
  }
  if (_includes(_lowercase(notificationText), _lowercase('Booking Cancelled'))) {
    return {
      backgroundColor: COLORS.NOTIFICATION_COLORS.BOOKING_FAILED,
      icon: <FontAwesome5 name="calendar-times" size={18} color={COLORS.WHITE} />,
    };
  }

  return {
    backgroundColor: COLORS.NOTIFICATION_COLORS.VISIT_COMPLETED,
    icon: <FontAwesome5 name="star" size={18} color={COLORS.WHITE} />,
  };
};

export const requestUserPermissionIOS = async uid => {
  const authStatus = await messaging().requestPermission();
  const enabled =
    authStatus === messaging.AuthorizationStatus.AUTHORIZED || authStatus === messaging.AuthorizationStatus.PROVISIONAL;

  if (enabled) {
    getAndStoreFCMToken(uid);
  }
};

export const requestUserPermissionAndroid = async uid => {
  // const authStatus = await messaging().requestPermission();
  // const enabled =
  //   authStatus === messaging.AuthorizationStatus.AUTHORIZED || authStatus === messaging.AuthorizationStatus.PROVISIONAL;

  // if (enabled) {
  //   console.log('Authorization status:', authStatus);
  //   getAndStoreFCMToken(uid);
  // }

  try {
    const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.POST_NOTIFICATIONS, {
      title: 'Notifications',
      message: 'Gofloaters would need permission to send notifications',

      buttonNeutral: 'Ask Me Later',
      buttonNegative: 'Cancel',
      buttonPositive: 'OK',
    });
    // console.log({ granted });
    if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      getAndStoreFCMToken(uid);
    } else {
      console.log('Camera permission denied');
    }
  } catch (err) {
    console.warn(err);
  }
};

export const getAndStoreFCMToken = async uid => {
  try {
    const fcmToken = await messaging().getToken();
    // console.log({ fcmToken });
    // const response = await updateUserDetails({ pushNotificationToken: fcmToken }, user);
    // console.log({ response });
    // console.log({ uid });
    if (uid) {
      const { status, msg } = await updatePushNotificationToken(fcmToken, { uid });
      // console.log('fcm details', { status, msg });
    }
  } catch (error) {
    console.log(error, 'Error fetching token');
  }
};

export const addNotificationListeners = () => {
  messaging().onNotificationOpenedApp(remoteMessage => {
    console.log('Notification caused app to open from background state:', remoteMessage.notification);
  });

  messaging()
    .getInitialNotification()
    .then(remoteMessage => {
      if (remoteMessage) {
        console.log('Notification caused app to open from quit state:', remoteMessage.notification);
      }
    });

  const unsubscribe = messaging().onMessage(async remoteMessage => {
    Alert.alert('A new FCM message arrived!', JSON.stringify(remoteMessage));
  });

  return unsubscribe;
};

export const getScreenAndAdditionalPropsFromRemoteMessage = (
  remoteMessage = {
    data: { screen: 'bookings' },
    from: '187167465849',
    messageId: '1692094146305009',
    notification: { body: 'workflexi test new', title: 'workflexi test' },
  }
) => {
  // console.log({ parsedJson: JSON.parse(remoteMessage) });
  const dataMessage = _get(remoteMessage, 'data');
  const screen = _get(dataMessage, 'screen');
  const navProps = _get(dataMessage, 'navProps');
  let parsedNavProps = {};
  try {
    parsedNavProps = JSON.parse(navProps);
  } catch {
    parsedNavProps = {};
  }
  return {
    screen,
    navProps: parsedNavProps,
  };
};

// const dataMessage = "data": {"navProps": "{\"bookingKey\":\"-NYyCZLr2OHbHNZ_NdBa\"}", "screen": "bookingDetails"}, "from": "187167465849", "messageId": "1692094732575845", "notification": {"body": "workflexi test new", "title": "workflexi test"}}

export const subscribeUserToTopic = organizationId => {
  messaging().subscribeToTopic('worqflexi');
  if (organizationId) {
    messaging().subscribeToTopic(`${organizationId}_worqflexi`);
  }
};

export const showLocalNotification = async remoteMessage => {
  await notifee.requestPermission();

  const notification = _get(remoteMessage, 'notification');
  const data = _get(remoteMessage, 'data');

  // Create a channel (required for Android)
  const channelId = await notifee.createChannel({
    id: 'default',
    name: 'worqflexi',
  });

  // Display a notification
  await notifee.displayNotification({
    ...notification,
    data,
    android: {
      channelId,
      pressAction: {
        id: 'default',
      },
    },
  });
};
