import { LinearGradient } from 'expo-linear-gradient';
import { View, StyleSheet, Image } from 'react-native';
import { COLORS } from '../constants/colors';
import Text from './Text';

const AmenitiesItem = ({
  label,
  image,
  textStyle = {},
  iconFamily: IconFamily,
  iconProps,
  labelProps,
  containerStyle,
  colors,
  iconBackgroundStyle,
  imageStyle,
}) => (
  <View style={[styles.amenityContainer, containerStyle]}>
    <LinearGradient
      // Button Linear Gradient
      colors={colors || ['rgba(255, 199, 167, 0.2)', 'rgba(255, 213, 121, 0.2)']}
      style={[styles.iconBackgroundStyle, iconBackgroundStyle]}>
      {image ? (
        <Image source={image} style={[styles.imageStyle, imageStyle]} />
      ) : (
        <IconFamily size={16} color={COLORS.PRIMARY_COLOR} {...iconProps} />
      )}
    </LinearGradient>
    <View style={textStyle}>
      <Text size={4} {...labelProps} style={{ alignSelf: 'flex-start' }} >
        {label}
      </Text>
    </View>
  </View>
);

const styles = StyleSheet.create({
  amenityContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 12,
  },
  iconBackgroundStyle: {
    borderRadius: 5,
    width: 38,
    height: 38,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageStyle: {
    width: 24,
    height: 24,
  },
});

export default AmenitiesItem;
