import axios from 'axios';
import moment from 'moment';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _head from 'lodash/head';
import _replace from 'lodash/replace';
import { auth, currentEnvironment } from '../../firebaseConfig';

const PATH_PREFIX = {
  prod: 'https://gofloaters.firebaseapp.com',
  testing: 'https://gofloaters-in.firebaseapp.com',
  local: 'http://localhost:5001/gofloaters/us-central1/api',
};

const API_PATHS = {
  GET_METADATA: `${PATH_PREFIX[currentEnvironment]}/worqflexi/meta`,
  GET_FAVORITE_SPACES: `${PATH_PREFIX[currentEnvironment]}/worqflexi/user/favorite-spaces`,
  GET_SPACE_DETAILS: `${PATH_PREFIX[currentEnvironment]}/worqflexi/space`,
  GET_SEARCH_RESULTS: `${PATH_PREFIX[currentEnvironment]}/worqflexi/spaces/nearby`,
  REFERRAL_VALIDATION: `${PATH_PREFIX[currentEnvironment]}/checkreferralcode`,
  CHECK_BOOKING_AVAILABILITY: `${PATH_PREFIX[currentEnvironment]}/worqflexi/space/availability`,
  CREATE_BOOKING: `${PATH_PREFIX[currentEnvironment]}/bookings/reactnative/doBooking`,
  CREATE_USER: `${PATH_PREFIX[currentEnvironment]}/updateuserdetails`,
  UPDATE_USER: `${PATH_PREFIX[currentEnvironment]}/user/updateProfile`,
  WHERE_ORG_IS: `${PATH_PREFIX[currentEnvironment]}/booking/organization/whereistoday`,
  CANCEL_BOOKING: `${PATH_PREFIX[currentEnvironment]}/booking/update`,
  REDEEM_BOOKING: `${PATH_PREFIX[currentEnvironment]}/booking/update`,
  REQUEST_DELETION: `${PATH_PREFIX[currentEnvironment]}/user/deleteUserAccount`,
  ADD_FEEDBACK: `${PATH_PREFIX[currentEnvironment]}/addfeedback`,
  GET_TEAM_MEMBERS: `${PATH_PREFIX[currentEnvironment]}/worqflexi/user/teammembers`,
  INVITE_TEAM: `${PATH_PREFIX[currentEnvironment]}/worqflexi/booking/inviteteam`,
  APPLE_PROFILE: `${PATH_PREFIX[currentEnvironment]}/user/apple-profile`,
  SEND_SOS: `${PATH_PREFIX[currentEnvironment]}/worqflexi/user/sos`,
  GET_FAVORITE_COWORKERS: `${PATH_PREFIX[currentEnvironment]}/worqflexi/user/{{userId}}/favorite-coworkers`,
  ADD_FOVORITE_COWORKER: `${PATH_PREFIX[currentEnvironment]}/worqflexi/user/{{userId}}/favorite-coworkers`,
  GET_EVENTS: `${PATH_PREFIX[currentEnvironment]}/worqflexi/{{userId}}/organization-events`,
  CREATE_EVENT: `${PATH_PREFIX[currentEnvironment]}/worqflexi/{{userId}}/organization-event`,
  GET_EVENT: `${PATH_PREFIX[currentEnvironment]}/worqflexi/{{userId}}/organization-event/{{eventId}}`,
  ADD_USER_TO_EVENT: `${PATH_PREFIX[currentEnvironment]}/worqflexi/{{userId}}/organization-event/{{eventId}}/attendee`,
  GET_SURVEY_QUESTIONS: `${PATH_PREFIX[currentEnvironment]}/worqflexi/{{userId}}/survey`,
  SAVE_SURVEY: `${PATH_PREFIX[currentEnvironment]}/worqflexi/{{userId}}/survey/{{surveyId}}/response`,
  SEND_USER_OTP: `${PATH_PREFIX[currentEnvironment]}/worqflexi/{{userId}}/otp`,
  APPROVE_DISAPPROVE_SPACE: `${PATH_PREFIX[currentEnvironment]}/worqflexi/space/{{spaceId}}/{{organizationId}}/approve-disapprove`,
  CHECKIN_BOOKING: `${PATH_PREFIX[currentEnvironment]}/booking/{{bookingId}}/check-in`,
};

export const getMetaData = async () => {
  try {
    const response = await axios.get(API_PATHS.GET_METADATA, {
      headers: { 'Content-Type': 'application/json', apikey: 'asdfkashfakfjhas8787faasdfasfdsdfgfdg' },
    });

    const metadata = _get(response, 'data', {});
    return metadata;
  } catch (e) {
    return {};
  }
};

export const getUserFavorites = async custId => {
  const token = await auth.currentUser.getIdToken(/* forceRefresh */ true);
  try {
    const response = await axios.post(
      API_PATHS.GET_FAVORITE_SPACES,
      { custId },
      {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      }
    );
    return _get(response, 'data');
  } catch (error) {
    return {};
  }
};

export const getSpaceDetails = async (spaceId, custId) => {
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.post(
      API_PATHS.GET_SPACE_DETAILS,
      { spaceId, custId },
      {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      }
    );
    return _get(response, 'data');
  } catch (error) {
    return [];
  }
};

export const getSearchResults = async (lat, lng, spaceSubType = 'officeSpace', userId, selectedLocality) => {
  // console.log({ selectedLocality });
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.get(API_PATHS.GET_SEARCH_RESULTS, {
      params: {
        lat,
        lng,
        spaceSubType,
        userId,
        searchType: _get(selectedLocality, 'searchType'),
        address: _get(selectedLocality, 'address'),
        city: _get(selectedLocality, 'city'),
      },
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });
    return _get(response, 'data');
  } catch (error) {
    return [];
  }
};

export const isValidReferralCode = async (referralCode, email) => {
  try {
    const response = await axios.post(
      API_PATHS.REFERRAL_VALIDATION,
      { referralCode, email },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return response;
  } catch (error) {
    return false;
  }
};

export const createBooking = async bookingDetails => {
  const token = await auth.currentUser.getIdToken(/* forceRefresh */ true);
  try {
    const response = await axios.post(API_PATHS.CREATE_BOOKING, bookingDetails, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });
    // console.log({ response });
    return _get(response, 'data');
  } catch (error) {
    throw error;
  }
};

export const createUser = async userDetails => {
  const token = await auth.currentUser.getIdToken(/* forceRefresh */ true);
  try {
    const response = await axios.post(API_PATHS.CREATE_USER, userDetails, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    return {};
  }
};

export const updateUserDetails = async (newUserDetails, user) => {
  const token = await auth.currentUser.getIdToken(/* forceRefresh */ true);
  // console.log({ newUserDetails, auth, token });
  try {
    const response = await axios.post(
      API_PATHS.UPDATE_USER,
      { uid: _get(user, 'uid'), ...newUserDetails },
      {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    console.log({ error });
    return {};
  }
};

// ?spaceId=-N3QMaQ4_KHD0seaY-Rj&date=1687757400000&numberOfSeats=1&duration=1
export const checkBookingAvailability = async ({ spaceId, dates, numberOfSeats, duration }) => {
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.post(
      API_PATHS.CHECK_BOOKING_AVAILABILITY,
      {
        spaceId,
        dates,
        numberOfSeats,
        duration,
      },
      {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      }
    );
    // console.log({
    //   data: _get(response, 'data'),
    //   available: _size(_filter(_get(response, 'data'), booking => _get(booking, 'availability'))) === _size(dates),
    // });
    return _size(_filter(_get(response, 'data'), booking => _get(booking, 'availability'))) === _size(dates);
  } catch (error) {
    return false;
  }
};

export const getSeatsAvailable = async ({ spaceId, dates, numberOfSeats, duration }) => {
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.post(
      API_PATHS.CHECK_BOOKING_AVAILABILITY,
      {
        spaceId,
        dates,
        numberOfSeats,
        duration,
      },
      {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      }
    );
    // console.log({ data: _get(response, 'data') });
    return _get(_head(_get(response, 'data')), 'seatsAvailable') - _get(_head(_get(response, 'data')), 'seatsBooked');
  } catch (error) {
    return {};
  }
};

export const getWhereOrgIs = async (organizationId, searchDate) => {
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.post(
      API_PATHS.WHERE_ORG_IS,
      { organizationId, searchDate },
      {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    console.log({ error });
    return {};
  }
};

export const cancelBooking = async bookingId => {
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.post(
      API_PATHS.CANCEL_BOOKING,
      { id: bookingId, cancelledby: 'User', status: 'Cancelled' },
      {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    console.log({ error });
    return {};
  }
};

export const redeemToConfrimBooking = async (booking, user) => {
  const token = await auth.currentUser.getIdToken(/* forceRefresh */ true);
  try {
    const response = await axios.post(
      API_PATHS.REDEEM_BOOKING,
      {
        id: _get(booking, 'bookingkey'),
        status: 'Prepaid',
        paymentRefNumber: 'Redeemed',
        bookingUsageFee: 0,
        spaceUsageFee: _get(booking, 'spaceUsageFee'),
        spaceType: _get(booking, 'spaceType'),
        gstFee: 0,
        redeemedPoints: _get(booking, 'spaceUsageFee'),
        uid: _get(user, 'uid'),
      },
      {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      }
    );
    return _get(response, 'data');
  } catch (error) {
    console.log({ error });
    return {};
  }
};

export const requestAccountDeletion = async user => {
  const token = await auth.currentUser.getIdToken(/* forceRefresh */ true);
  try {
    const response = await axios.post(
      API_PATHS.REQUEST_DELETION,
      {
        uid: _get(user, 'uid'),
        displayName: _get(user, 'displayName'),
        phoneNumber: _get(user, 'phoneNumber'),
        email: _get(user, 'email'),
      },
      {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      }
    );
    return _get(response, 'data');
  } catch (error) {
    return {};
  }
};

export const addUserBookingFeedback = async ({
  rating,
  commentForSpaces,
  customerName,
  spaceName,
  spaceId,
  bookingId,
}) => {
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.post(
      API_PATHS.ADD_FEEDBACK,
      {
        rating,
        gofloaterrating: 5,
        commentForSpaces,
        customerName,
        spaceName,
        spaceId,
        feedbackDate: moment().valueOf(),
        bookingId,
      },
      {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      }
    );

    return _get(response, 'data.status') === 'success';
  } catch (error) {
    return false;
  }
};

export const getUserTeam = async user => {
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.post(
      API_PATHS.GET_TEAM_MEMBERS,
      {
        userId: _get(user, 'uid'),
      },

      {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      }
    );

    return _get(response, 'data');
  } catch (error) {
    return [];
  }
};

export const inviteTeam = async payload => {
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.post(API_PATHS.INVITE_TEAM, payload, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });

    return _get(response, 'data');
  } catch (error) {
    return [];
  }
};

export const saveAppleDetailsTemp = async (uid, fullName, email) => {
  try {
    const response = await axios.post(API_PATHS.APPLE_PROFILE, {
      uid,
      fullName,
      email,
    });
    // console.log({ appleLoginResponse: response });
    return _get(response, 'data');
  } catch (error) {
    console.log({ error });
    return {};
  }
};

export const getUserAppleDetails = async uid => {
  try {
    const response = await axios.get(`${API_PATHS.APPLE_PROFILE}/${uid}`);
    // console.log({ applelogingetresponse: _get(response, 'data.appleUser') });
    return _get(response, 'data.appleUser');
  } catch (e) {
    return {};
  }
};

export const sendSOS = async ({ userId, displayName, email, platform }) => {
  const token = await auth.currentUser.getIdToken(/* forceRefresh */ true);
  try {
    const response = await axios.post(
      API_PATHS.SEND_SOS,
      { userId, displayName, email, platform },
      {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      }
    );
    return _get(response, 'data');
  } catch (error) {
    return {};
  }
};

export const getFavoriteCoworkers = async userId => {
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.get(_replace(API_PATHS.GET_FAVORITE_COWORKERS, '{{userId}}', userId), {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });
    // console.log({ response });
    return _get(response, 'data.favoriteCoworkers') || [];
  } catch (error) {
    return [];
  }
};

export const addFavoriteCoworker = async payload => {
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.post(
      _replace(API_PATHS.ADD_FOVORITE_COWORKER, '{{userId}}', _get(payload, 'userId')),
      payload,
      {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      }
    );
    return _get(response, 'data');
  } catch (error) {
    throw new Error();
  }
};

export const getEvents = async userId => {
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.get(_replace(API_PATHS.GET_EVENTS, '{{userId}}', userId), {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });
    return _get(response, 'data.organizationEvents') || [];
  } catch (error) {
    return [];
  }
};

export const createEvent = async payload => {
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.post(
      _replace(API_PATHS.CREATE_EVENT, '{{userId}}', _get(payload, 'userId')),
      payload,
      {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    return [];
  }
};

export const getEvent = async (eventId, userId) => {
  const token = await auth.currentUser.getIdToken();
  const urlWithUserId = _replace(API_PATHS.GET_EVENT, '{{userId}}', userId);
  const urlWithEventId = _replace(urlWithUserId, '{{eventId}}', eventId);
  try {
    const response = await axios.get(urlWithEventId, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });
    return _get(response, 'data.organizationEvent') || {};
  } catch (error) {
    return [];
  }
};

export const addUserToEventForMeetingRoomBooking = async (eventId, userId) => {
  const token = await auth.currentUser.getIdToken();
  const urlWithUserId = _replace(API_PATHS.ADD_USER_TO_EVENT, '{{userId}}', userId);
  const urlWithEventId = _replace(urlWithUserId, '{{eventId}}', eventId);
  try {
    const response = await axios.post(
      urlWithEventId,
      {},
      {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    return [];
  }
};

export const getSurveyQuestions = async userId => {
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.get(_replace(API_PATHS.GET_SURVEY_QUESTIONS, '{{userId}}', userId), {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });
    return _get(response, 'data.survey') || {};
  } catch (error) {
    return {};
  }
};

export const saveSurveyResponse = async (userId, surveyId, userResponse) => {
  const token = await auth.currentUser.getIdToken();
  const urlWithUserId = _replace(API_PATHS.SAVE_SURVEY, '{{userId}}', userId);
  const urlWithSurvey = _replace(urlWithUserId, '{{surveyId}}', surveyId);

  return axios.post(urlWithSurvey, userResponse, {
    headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
};

export const sendUserOtp = async userId => {
  const token = await auth.currentUser.getIdToken();
  return axios.get(_replace(API_PATHS.SEND_USER_OTP, '{{userId}}', userId), {
    headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
};

export const approveDisapproveSpace = async (spaceId, organizationId, payload) => {
  const token = await auth.currentUser.getIdToken(/* forceRefresh */ true);
  const urlWithSpacecId = _replace(API_PATHS.APPROVE_DISAPPROVE_SPACE, '{{spaceId}}', spaceId);
  const urlWIthOrgId = _replace(urlWithSpacecId, '{{organizationId}}', organizationId);

  return axios.post(urlWIthOrgId, payload, {
    headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
};

export const checkinUserForBooking = async ({ userId, latitude, longitude, bookingId }) => {
  const token = await auth.currentUser.getIdToken();
  const urlWithbookingId = _replace(API_PATHS.CHECKIN_BOOKING, '{{bookingId}}', bookingId);

  return axios.post(
    urlWithbookingId,
    { userId, latitude, longitude },
    {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
  );
};
