import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import { onValue, ref, get } from 'firebase/database';
import { database } from '../firebaseConfig';

import { setUserSetInfo as setUserSetInfoAction, setOrganizationData as setOrgData } from './reducers/user';

const FirebaseDBListeners = ({ children, setUserSetInfo, userData, setOrganizationData }) => {
  const uid = _get(userData, 'uid');
  const organizationId = _get(userData, 'organizationId');
  useEffect(() => {
    let unsubscribe = () => {};
    function initUserDBListener() {
      const usersRef = ref(database, `users/${uid}`);
      unsubscribe = onValue(
        usersRef,
        snapshot => {
          const data = snapshot.val();
          setUserSetInfo(data);
        },
        error => console.log({ error })
      );
    }
    if (uid) initUserDBListener();
    return unsubscribe;
  }, [uid]);

  useEffect(() => {
    const unsubscribe = () => {};
    async function initOrgDBListener() {
      const usersRef = ref(database, `organizations//${organizationId}`);
      const snapshot = await get(usersRef);
      const data = snapshot.val();
      // console.log({ orgDta: data });
      setOrganizationData(data);
    }
    if (organizationId) initOrgDBListener();
    return unsubscribe;
  }, [organizationId, setOrganizationData]);
  return children;
};

const mapStateToProps = state => ({
  userData: state.user.user,
});

export default connect(mapStateToProps, { setUserSetInfo: setUserSetInfoAction, setOrganizationData: setOrgData })(
  FirebaseDBListeners
);
