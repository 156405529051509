import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Image, View, StyleSheet, Dimensions, ImageBackground } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { useToast } from 'react-native-toast-notifications';
import { connect } from 'react-redux';
import { IMAGES } from '../../assets/images';
import Wave from '../../components/Wave';
import Button from '../../components/Button';
import { ROUTES } from '../../constants/routes';
import LinkButton from '../../components/LinkButton';
import { authenticateWithGoogle, isUserExistinDB } from '../../utils/firebaseUtils';
import { createUser } from '../../api/common';
import SignupDetails from '../signupDetails/SignupDetails';
import { getCreateUserPayload, getUserLocation } from '../../utils/common';
import Carousel from '../../components/carousel/CarouselFlatlist';

const { width } = Dimensions.get('window');

WebBrowser.maybeCompleteAuthSession();

function Login({ metadata }) {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [currentView, setCurrentView] = useState('LOGIN');
  const usersDataRef = useRef();
  const toast = useToast();
  const [_, response, promptAsync] = Google.useAuthRequest({
    androidClientId: '187167465849-uonvt7aghkl891soirk9209mev8d3bkn.apps.googleusercontent.com',
    iosClientId: '187167465849-uonvt7aghkl891soirk9209mev8d3bkn.apps.googleusercontent.com',
    webClientId: '56958671731-tiob4n5q21aa5nghp50q5fohok4huqr4.apps.googleusercontent.com',
    expoClientId: '56958671731-tiob4n5q21aa5nghp50q5fohok4huqr4.apps.googleusercontent.com',
    // redirectUri: makeRedirectUri({
    //   scheme: 'goFloaters',
    // }),
  });

  useEffect(() => {
    if (response?.type === 'success') {
      // setToken(response.authentication.accessToken);
      getUserInfo(response.authentication.accessToken);
    }
  }, [response]);

  const getUserInfo = async userToken => {
    try {
      const userResponse = await fetch('https://www.googleapis.com/userinfo/v2/me', {
        headers: { Authorization: `Bearer ${userToken}` },
      });

      await authenticateWithGoogle(userToken);
      const usersData = await userResponse.json();
      usersDataRef.current = usersData;
      // console.log({ usersData });
      const userEmail = _get(usersData, 'email');
      const userExists = await isUserExistinDB(userEmail);
      if (!userExists) {
        setToken(userToken);
        setEmail(userEmail);
        setCurrentView('SIGN_UP');
      }
    } catch (error) {}
  };

  const onSignupPress = useCallback(
    async ({ displayName, phoneNumber, email, companyName, profession, referral, userCity }) => {
      const authDetails = await authenticateWithGoogle(token);
      const userResponse = await createUser(
        getCreateUserPayload(authDetails, {
          displayName,
          phoneNumber,
          companyName,
          signupReferralCode: referral,
          profession,
          userCity,
          location: getUserLocation(userCity, metadata),
          email,
        })
      );

      if (!_isEmpty(userResponse)) {
        toast.show('User signup successful.');
      } else {
        toast.show('Error updating user details. please try again');
      }
    },
    [email]
  );

  return (
    <View style={styles.container}>
      {currentView === 'LOGIN' ? (
        <>
          <View style={styles.image}>
            <Carousel
              data={[{ image: IMAGES.ONBOARDING_1 }, { image: IMAGES.ONBOARDING_2 }, { image: IMAGES.ONBOARDING_3 }]}
              paginationWidth={width}
              containerStyle={styles.image}
              renderItem={({ item }) => (
                <ImageBackground style={styles.image} source={item.image} resizeMode="stretch" />
              )}
              keyExtractor={(_, index) => index}
            />
          </View>
          <View>
            <Image source={IMAGES.GOFLOATERS_LOGO} style={styles.logoStyle} resizeMode="contain" />
            <View style={styles.actioncontainer}>
              <LinkButton variant="small" to={{ screen: ROUTES.SIGNIN_WITH_EMAIL.name }}>
                Sign in with Email
              </LinkButton>
              <Button variant="small" style={{ marginVertical: 16 }} onPress={() => promptAsync()}>
                Sign in with Google
              </Button>
              {/* <View style={styles.iconContainer}>
                <Button variant="small" style={styles.iconStyle} onPress={() => promptAsync()}>
                  <FontAwesome name="google" size={18} color="white" />
                </Button>
                <Button variant="small" style={styles.iconStyle}>
                  <FontAwesome name="apple" size={18} color="white" />
                </Button>
                <Button variant="small" style={styles.iconStyle}>
                  <FontAwesome name="linkedin" size={18} color="white" />
                </Button>
              </View> */}
            </View>
          </View>
        </>
      ) : (
        <SignupDetails email={email} onSignupPress={onSignupPress} />
      )}

      <Wave />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  image: {
    width,
    height: 300,
    resizeMode: 'cover',
  },
  logoStyle: {
    width: '100%',
    height: 80,
    marginVertical: 48,
  },
  actioncontainer: {
    width: '60%',
    alignSelf: 'center',
    marginTop: 24,
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingHorizontal: 16,
  },
  iconStyle: {
    paddingVertical: 10,
    paddingHorizontal: 12,
  },
});

const mapStateToProps = state => ({
  metadata: state.metadata.data,
});

export default connect(mapStateToProps)(Login);
