import React, { useMemo } from "react";
import { View } from "react-native";
import { COLORS } from "../constants/colors";

function Separator({
  type = "horizontal",
  gap = 16,
  color = COLORS.PRIMARY_BORDER_COLOR,
  thickness = 1,
  style,
}) {
  const defaultStyle = useMemo(() => {
    if (type === "horizontal") {
      return { marginVertical: gap, height: thickness, backgroundColor: color };
    }
    return { width: thickness, marginHorizontal: gap, backgroundColor: color };
  }, [type, gap]);
  return <View style={[defaultStyle, style]} />;
}

export default Separator;
