import React from 'react';
import { ActivityIndicator, Pressable, StyleSheet, View } from 'react-native';

import Text from './Text';
import { COLORS } from '../constants/colors';

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.PRIMARY_COLOR,
    flexDirection: 'row',
    alignItems: 'center',
  },
  small: {
    paddingVertical: 8,
    borderRadius: 8,
  },
  medium: {
    paddingVertical: 10,
    borderRadius: 8,
  },
  large: {
    paddingVertical: 16,
    borderRadius: 8,
  },
  bordered: {
    borderWidth: 1,
    borderColor: '#2798B5',
    backgroundColor: 'transparent',
    borderRadius: 8,
  },
  borderedText: {
    color: '#2798B5',
  },

  textStyle: {
    color: 'white',
  },
  iconContainerStyle: {
    marginHorizontal: 16,
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  iconButtonStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

function Button({
  children,
  variant = 'large',
  textProps,
  iconButtonStyle = styles.iconButtonStyle,
  style,
  leftIcon,
  bordered,
  loading,
  ...pressableProps
}) {
  const containerStyle = [styles.container, styles[variant], style];
  if (!leftIcon) containerStyle.push(styles.justifyContentCenter);
  const textStyle = [styles.textStyle];
  if (bordered) {
    containerStyle.push(styles.bordered);
    textStyle.push(styles.borderedText);
  }
  if (loading) containerStyle.push({ backgroundColor: 'transparent', borderColor: 'transparent' });
  return (
    <Pressable style={containerStyle} {...pressableProps}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <View style={iconButtonStyle}>
          {leftIcon ? <View style={styles.iconContainerStyle}>{leftIcon}</View> : null}
          <Text style={[textStyle, textProps?.style ?? {}]} {...textProps}>
            {children}
          </Text>
        </View>
      )}
    </Pressable>
  );
}

export default Button;
