import { useState, useCallback } from 'react';
import { View, StyleSheet, Image, Pressable, ScrollView } from 'react-native';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _split from 'lodash/split';
import { MaterialIcons } from '@expo/vector-icons';

import Text from '../Text';
import { COLORS } from '../../constants/colors';
import Button from '../Button';
import { getSpaceDetails } from '../../utils/common';

const MEMBER_VIEW_HEIGHT = 96;

const TeamViewCard = ({
  onJoinNowPress,
  showGoFloatersName = true,
  showMemberNames,
  spaceDetails = {
    id: 0,
    count: 13,
    members:
      'Ramamoorthy, Murali Balaji , Medha Thayumanavan, Karthikeyan K, Rajaraman | Chargebee, Raunak Verma, Maheshwar V, Sona, Sundar, Siddharth Burman, Shiva Suresh, Sharon Ebenezer, Navaneedhan Ravichandran',
    originalName: 'Cowrks Sterling Technopolis',
    spacecoords: [12.9697966, 80.249498],
    spaceLocality: 'Perungudi',
    spaceId: '-N96CueILpV07AP6IuPl',
    spacePhoto: '',
    operationTiming: {
      days: {
        friday: {
          from: '09:00',
          to: '18:00',
          useNow: true,
        },
        monday: {
          from: '09:00',
          to: '18:00',
          useNow: true,
        },
        saturday: {
          from: '00:00',
          holiday: true,
          to: '00:00',
        },
        sunday: {
          from: '00:00',
          holiday: true,
          to: '00:00',
        },
        thursday: {
          from: '09:00',
          to: '18:00',
          useNow: true,
        },
        tuesday: {
          from: '09:00',
          to: '18:00',
          useNow: true,
        },
        wednesday: {
          from: '09:00',
          to: '18:00',
          useNow: true,
        },
      },
      displayName: 'Monday-Friday : 09:00 AM to 06:00 PM, Saturday-Sunday : Holiday',
    },
    gofloatersSpaceName: 'Chargebee Space',
    spaceDisplayName: 'Chargebee Executive Desks',
    spaceaddress: {
      city: 'Chennai',
      country: 'India',
      landmark: 'Hotel Turyaa',
      locality: 'Perungudi',
      street: '4, 293, Old Mahabalipuram Rd,',
      zipcode: '600096',
    },
  },
}) => {
  const [showMore, setShowMore] = useState(false);
  const [showViewMoreButton, setShowViewMoreButton] = useState(false);

  const updateShowViewMoreButton = useCallback((_, height) => setShowViewMoreButton(height > MEMBER_VIEW_HEIGHT), []);

  const onJoinPress = () => {
    onJoinNowPress(spaceDetails);
  };

  return (
    <View style={styles.container}>
      {/* <View style={styles.spaceDetails}>
        <Image
          style={styles.spaceImage}
          source={{
            uri: _get(spaceDetails, 'spacePhoto'),
          }}
          resizeMode="cover"
        />
        <View style={{ flex: 1 }}>
          <Text size={3} variant="bold">
            {_get(spaceDetails, 'originalName')}
          </Text>
          <Text size={4} color={COLORS.TEXT_COLOR_SECONDARY}>
            {_get(spaceDetails, 'count')} colleagues{' '}
          </Text>
          <Text size={4} color={COLORS.TEXT_COLOR_SECONDARY}>
            Preferred by your colleagues
          </Text>
        </View>
      </View> */}

      <View style={styles.spaceDetails}>
        <Image
          source={{
            uri: _get(spaceDetails, 'spacePhoto'),
          }}
          style={styles.imageStyle}
        />
        <View style={styles.contentStyle}>
          <Text variant="bold" size={3} style={{textAlign:'left'}}>
            {`${showGoFloatersName ? `${_get(spaceDetails, 'gofloatersSpaceName')} - ` : ''}${_get(
              spaceDetails,
              'originalName'
            )}`}
          </Text>
          <Text variant="medium" size={4} color={COLORS.PRIMARY_COLOR}  style={{textAlign:'left'}}>
            {_get(spaceDetails, 'spaceDisplayName')}
          </Text>
          <Text variant="bold" size={4}  style={{textAlign:'left'}}>
            {_get(spaceDetails, 'count')} colleagues{' '}
          </Text>
          <Text variant="medium" size={4} color={COLORS.TEXT_COLOR_SECONDARY}  style={{textAlign:'left'}}>
            {getSpaceDetails(_get(spaceDetails, 'spaceaddress'))}
          </Text>
        </View>
      </View>

      {showMemberNames ? (
        <ScrollView
          style={{ maxHeight: showMore ? 2 * MEMBER_VIEW_HEIGHT : MEMBER_VIEW_HEIGHT }}
          contentContainerStyle={{ flexDirection: 'row', flexWrap: 'wrap' }}
          scrollEnabled={showMore}
          onContentSizeChange={updateShowViewMoreButton}
          showsVerticalScrollIndicator={false}>
          {_map(_split(_get(spaceDetails, 'members'), ','), (member, index) => (
            <View style={{ marginBottom: 4, height: 20 }} key={member + index}>
              <Text size={4} style={{ marginRight: 12, textDecorationLine: 'underline', marginBottom: 4 }}>
                {member}
              </Text>
            </View>
          ))}
        </ScrollView>
      ) : null}
      {showViewMoreButton ? (
        <Pressable style={{ flexDirection: 'row', alignItems: 'center' }} onPress={() => setShowMore(!showMore)}>
          <Text size={4} color={COLORS.TEXT_COLOR_SECONDARY} style={{ alignSelf: 'flex-start' }} >
            View {showMore ? 'fewer' : 'more'}
          </Text>
          <MaterialIcons
            name={!showMore ? 'keyboard-arrow-down' : 'keyboard-arrow-up'}
            size={12}
            color={COLORS.TEXT_COLOR_SECONDARY}
          />
        </Pressable>
      ) : null}
      <Button variant="medium" textProps={{ variant: 'medium' }} onPress={onJoinPress}>
        Join
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    padding: 16,
    elevation: 8,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 4,
    shadowOpacity: 0.2,
    borderRadius: 12,
    rowGap: 8,
  },
  spaceDetails: {
    flexDirection: 'row',
    columnGap: 8,
    alignItems: 'center',
  },

  imageStyle: {
    width: 96,
    height: 96,
    borderRadius: 12,
  },

  contentStyle: {
    flexDirection: 'column',
    flex: 1,
    rowGap: 4,
    marginLeft: 16,
  },
});

export default TeamViewCard;
