import { View, StyleSheet, FlatList } from 'react-native';
import _get from 'lodash/get';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { COLORS } from '../../constants/colors';
import NotificationItem from '../../components/NotificationItem';
import Separator from '../../components/Separator';
import Header from '../../components/Header';
import Text from '../../components/Text';
import { ROUTES } from '../../constants/routes';
import { getNotifications } from '../../utils/common';

function Notifications({ user, navigation }) {
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    setNotifications(getNotifications(_get(user, 'notifications')));
  }, [user]);

  const onBackPress = () => {
    navigation.navigate(ROUTES.PROFILE.name);
  };

  return (
    <View style={styles.container}>
      <Header title="Notifications" onBackPress={onBackPress} />
      <FlatList
        style={styles.containerPadding}
        data={notifications}
        renderItem={({ item }) => <NotificationItem notification={item} />}
        keyExtractor={(_, index) => index}
        contentContainerStyle={{ paddingBottom: 32 }}
        ItemSeparatorComponent={() => <Separator />}
        ListEmptyComponent={() => (
          <Text variant="bold" size={4} style={{ alignSelf: 'center' }}>
            No Notifications
          </Text>
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.PRIMARY_BACKGROUND,
  },
  containerPadding: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(Notifications);
