import React, { useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { useToast } from 'react-native-toast-notifications';
import { Entypo } from '@expo/vector-icons';
import * as Location from 'expo-location';
import _get from 'lodash/get';

import { COLORS } from '../../constants/colors';
import Text from '../Text';
import { checkinUserForBooking } from '../../api/common';
import Loader from '../Loader';

const CheckinSpace = ({ user, booking }) => {
  const [checkingInSpace, setCheckingInSpace] = useState(false);
  const toast = useToast();

  const onCheckInPress = async () => {
    try {
      const { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        toast.show('Access to location is needed to checkin');
        return;
      }
      setCheckingInSpace(true);
      const location = await Location.getCurrentPositionAsync({});
      const {
        coords: { latitude, longitude },
      } = location;
      const userId = _get(user, 'uid');
      const bookingId = _get(booking, 'bookingkey');
      const {
        data: { message },
      } = await checkinUserForBooking({ latitude, longitude, userId, bookingId });
      toast.show(message);
      setCheckingInSpace(false);
    } catch (error) {
      toast.show('Something went wrong. Please try again later.');
    } finally {
      setCheckingInSpace(false);
    }
  };
  return (
    <Pressable style={[styles.iconButtonContainer, styles.flexRowCenter]} onPress={onCheckInPress}>
      {checkingInSpace ? (
        <Loader style={{ paddingTop: 0 }} />
      ) : (
        <>
          <LinearGradient
            colors={['rgba(255, 199, 167, 0.2)', 'rgba(255, 213, 121, 0.2)']}
            style={styles.iconBackgroundStyle}>
            <Entypo name="location" size={12} color={COLORS.PRIMARY_COLOR} />
          </LinearGradient>
          <Text size={3} color={COLORS.TEXT_COLOR_SECONDARY}>
            Checkin
          </Text>
        </>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  iconBackgroundStyle: {
    width: 24,
    height: 24,
    padding: 6,
    borderRadius: 4,
  },
  iconButtonContainer: {
    columnGap: 8,
  },
  flexRowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default CheckinSpace;
