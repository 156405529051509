import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import {
  View,
  StyleSheet,
  ImageBackground,
  Image,
  Dimensions,
  ScrollView,
  Platform,
  Share,
  Pressable,
} from 'react-native';
import { Feather } from '@expo/vector-icons';
import * as Clipboard from 'expo-clipboard';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _head from 'lodash/head';
import _isEmpty from 'lodash/isEmpty';

import { useToast } from 'react-native-toast-notifications';
import { useNavigation } from '@react-navigation/native';
import { COLORS } from '../../constants/colors';
import { IMAGES } from '../../assets/images';
import Text from '../Text';
import Button from '../Button';
import Carousel from '../carousel/CarouselFlatlist';
import { ROUTES } from '../../constants/routes';
import LottieAnimation from './LottieAnimation';
import {
  getBookingConfirmationStatus,
  getBookingInfoToShare,
  getInviteTeamPayload,
  getMultipleBookingInfoToShare,
} from '../../utils/common';
import InviteTeam from '../inviteTeam';
import { getSurveyQuestions, inviteTeam } from '../../api/common';
import Survey from '../survey';

const { width } = Dimensions.get('window');
const ITEM_WIDTH = width - 2 * 32;

const BookingConfirmation = ({ bookingDetails, user }) => {
  const toast = useToast();
  const navigation = useNavigation();
  const [survey, setSurvey] = useState({});
  const [showSurvey, setShowSurvey] = useState(false);
  const [surveyFetched, setSurveyFetched] = useState(false);
  const uid = _get(user, 'uid');

  useEffect(() => {
    async function fetchSurveyQuestions() {
      const surveyResponse = await getSurveyQuestions(uid);
      setSurvey(surveyResponse);
      setSurveyFetched(true);
    }
    if (uid && _isEmpty(survey) && !surveyFetched) fetchSurveyQuestions();
    return () => {};
  }, [uid, survey, surveyFetched]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowSurvey(true);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, []);

  const onSharePress = useCallback(async () => {
    const message =
      _size(bookingDetails) > 1
        ? getMultipleBookingInfoToShare(bookingDetails)
        : getBookingInfoToShare(_get(_head(bookingDetails), 'booking'));
    if (Platform.OS === 'web') {
      if (navigator?.share) {
        await navigator.share({
          title: 'Booking Confirmation',
          text: message,
        });
        return;
      }
      await Clipboard.setStringAsync(message);
      toast.show('Message copied.');
      return;
    }

    try {
      const result = await Share.share({
        message,
        title: 'Booking Confirmation',
      });
    } catch (error) {
      console.log({ error });
    }
  }, [bookingDetails]);

  const onTrackBooking = () => {
    navigation.navigate(ROUTES.BOOKINGS.name);
  };

  const onInviteTeamPress = async (selectedMembers, setInvitingMembers, onCancel) => {
    if (_size(selectedMembers) > 0) {
      setInvitingMembers(true);
      try {
        const payload = getInviteTeamPayload(_get(_head(bookingDetails), 'booking'), user, selectedMembers);
        await inviteTeam(payload);
        toast.show('Team members invited successfully');
        onCancel();
      } catch {
        toast.show('Error inviting team. Please try again.');
      } finally {
        setInvitingMembers(false);
      }
    } else {
      toast.show('Please select team members to invite.');
    }
  };

  const { statusLabel, showMessage } = useMemo(() => getBookingConfirmationStatus(bookingDetails), [bookingDetails]);
  return (
    <>
      <ScrollView style={styles.container}>
        <ImageBackground style={styles.lottieContainer} source={IMAGES.BOOKING_CONFIRMATION_BG} resizeMode="stretch">
          <LottieAnimation />
          <Pressable
            onPress={() => navigation.goBack()}
            style={{ position: 'absolute', top: Platform.OS === 'ios' ? 44 : 16, left: 16 }}>
            <Feather name="arrow-left" size={24} color={COLORS.TEXT_COLOR_SECONDARY} />
          </Pressable>
          <Image source={IMAGES.BOOKING_CONFIRMATION_YAY} style={styles.yayImageStyle} />
        </ImageBackground>
        <View style={styles.bookingDetailsContainer}>
          <Image
            source={IMAGES.BOOKING_CONFIRMATION_CHECK}
            style={[styles.checkImageContainer, styles.alignItemsCenter]}
            resizeMode="contain"
          />
          <Text size={0} variant="bold" color={COLORS.WHITE} style={styles.alignItemsCenter}>
            {statusLabel}
          </Text>
          <View style={[styles.bookingDetails, styles.marginHorizontal]}>
            <Carousel
              data={bookingDetails}
              paginationWidth={ITEM_WIDTH}
              paginationContainerStyle={{ backgroundColor: COLORS.TEXT_COLOR_SECONDARY }}
              paginationStyle={{ bottom: -12 }}
              paginationDotStyle={{ backgroundColor: COLORS.TEXT_COLOR_SECONDARY }}
              renderItem={({ item: { booking } }) => (
                <View style={{ width: ITEM_WIDTH - 2 * 24 - 2 }}>
                  <View style={styles.bookingDetailsRow}>
                    <Text
                      size={3}
                      variant="medium"
                      color={COLORS.TEXT_COLOR_SECONDARY}
                      style={styles.bookingDetailType}>
                      Booking Id:{' '}
                    </Text>
                    <Text size={3} variant="semiBold"  style={{ textAlign: 'left' }}>
                      {_get(booking, 'bookingid')}
                    </Text>
                  </View>
                  <View style={styles.bookingDetailsRow}>
                    <Text
                      size={3}
                      variant="medium"
                      color={COLORS.TEXT_COLOR_SECONDARY}
                      style={styles.bookingDetailType}>
                      Booking For:{' '}
                    </Text>
                    <Text size={3} variant="semiBold" style={{ flex: 1, textAlign: 'left' }} numberOfLines={0}>
                      {_get(booking, 'spaceName')}
                    </Text>
                  </View>
                  <View style={styles.bookingDetailsRow}>
                    <Text
                      size={3}
                      variant="medium"
                      color={COLORS.TEXT_COLOR_SECONDARY}
                      style={styles.bookingDetailType}>
                      Booking Date:
                    </Text>
                    <Text size={3} variant="semiBold" style={{ flex: 1, textAlign: 'left' }} numberOfLines={0}>
                      {moment(_get(booking, 'startTime')).format('Do MMMM YYYY, hh:mma')}
                    </Text>
                  </View>
                </View>
              )}
              keyExtractor={(_, index) => index}
            />
          </View>
          {showMessage ? (
            <Text size={3} style={[styles.marginHorizontal, { textAlign: 'center' }]}>
              You will receive an SMS and email confirmation within 30 mins if the space is available.
            </Text>
          ) : null}
          <View style={styles.actionButtonsContainer}>
            {/* {_size(bookingDetails) === 1 ? <InviteTeam /> : null} */}
            <InviteTeam booking={_get(_head(bookingDetails), 'booking')} onSavePress={onInviteTeamPress} />
            <View style={{ flexDirection: 'row', columnGap: 8 }}>
              <Button
                onPress={onTrackBooking}
                style={{ backgroundColor: COLORS.WHITE, flex: 1 }}
                textProps={{
                  style: { letterSpacing: 0, color: COLORS.PRIMARY_COLOR },
                  size: 2,
                  variant: 'semiBold',
                }}>
                Track Booking
              </Button>
              <Button
                onPress={onSharePress}
                style={{ backgroundColor: COLORS.WHITE, flex: 1 }}
                textProps={{
                  style: { letterSpacing: 0, color: COLORS.PRIMARY_COLOR },
                  size: 2,
                  variant: 'semiBold',
                }}>
                Share
              </Button>
            </View>
          </View>
        </View>
      </ScrollView>
      {showSurvey && !_isEmpty(survey) ? <Survey survey={survey} user={user} /> : null}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.SECONDARY_COLOR,
  },
  lottieContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: 240,
    // position: 'relative',
  },
  yayImageStyle: {
    width: 150,
    height: 60,
  },
  bookingDetailsContainer: {
    marginTop: -80,
  },
  checkImageContainer: {
    width: 115,
    height: 115,
  },
  marginHorizontal: {
    marginHorizontal: 32,
  },
  bookingDetails: {
    backgroundColor: COLORS.WHITE,
    borderWidth: 1,
    borderColor: COLORS.TEXT_COLOR_SECONDARY,
    borderStyle: 'dashed',
    marginTop: 24,
    padding: 24,
    marginBottom: 16,
  },
  bookingDetailsRow: {
    flexDirection: 'row',
    flex: 1,
    marginBottom: 12,
  },
  bookingDetailType: {
    minWidth: 100,
    textAlign: 'left'
  },
  alignItemsCenter: {
    alignSelf: 'center',
  },
  actionButtonsContainer: {
    marginHorizontal: 20,
    marginBottom: 32,
    rowGap: 12,
    marginTop: 16,
  },
});

export default BookingConfirmation;
