import React from 'react';
import { View } from 'react-native';
import _map from 'lodash/map';
import _round from 'lodash/round';
import Animated, { Extrapolation, interpolate, useAnimatedStyle } from 'react-native-reanimated';
import { COLORS } from '../../constants/colors';

export function Pagination({ data, scrollX, paginationWidth, containerStyle = {} }) {
  // return <Pagination paginationStyleItem={styles.pagination} {...props} />;
  // return null;
  return (
    <View style={{ flexDirection: 'row' }}>
      {_map(data, (_, i) => {
        const inputRange = [(i - 1) * paginationWidth, i * paginationWidth, (i + 1) * paginationWidth];
        // const scale = scrollX.interpolate({
        //   inputRange,
        //   outputRange: [0.9, 1.4, 0.9],
        //   extrapolate: 'clamp',
        // });
        // const opacity = scrollX.interpolate({
        //   inputRange,
        //   outputRange: [0, 1, 0],
        //   extrapolate: 'clamp',
        // });

        const animatedScaleStyles = useAnimatedStyle(() => {
          'worklet';

          const scale = interpolate(scrollX.value, inputRange, [0.75, 1.4, 0.75], {
            extrapolateLeft: Extrapolation.CLAMP,
            extrapolateRight: Extrapolation.CLAMP,
          });

          return {
            transform: [{ scale }],
          };
        });

        const animatedOpacityStyles = useAnimatedStyle(() => {
          'worklet';

          const opacity = interpolate(scrollX.value, inputRange, [0, 1, 0], {
            extrapolateLeft: Extrapolation.CLAMP,
            extrapolateRight: Extrapolation.CLAMP,
          });

          return {
            opacity,
          };
        });

        return (
          <Animated.View
            key={`indicator - ${i}`}
            style={[
              {
                height: 10,
                width: 10,
                borderRadius: 5,
                backgroundColor: COLORS.PRIMARY_BACKGROUND,
                // opacity,
                marginRight: 8,
                ...containerStyle,
              },
              // animatedOpacityStyles,
            ]}>
            <Animated.View
              style={[
                {
                  height: 10,
                  width: 10,
                  borderRadius: 5,
                  backgroundColor: COLORS.PRIMARY_COLOR,
                  // transform: [{ scale }],
                  // opacity,
                  marginRight: 8,
                },
                animatedScaleStyles,
                animatedOpacityStyles,
              ]}
            />
          </Animated.View>
        );
      })}
    </View>
  );
}
