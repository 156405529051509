import React from 'react';
import { View, StyleSheet, Image, Dimensions, Pressable, Platform, Linking } from 'react-native';
import { connect } from 'react-redux';
import { useToast } from 'react-native-toast-notifications';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { COLORS } from '../../constants/colors';
import { getCanUseSOSFeature, getSOSHelpline } from '../../selectors/organization';
import { IMAGES } from '../../assets/images';
import { ROUTES } from '../../constants/routes';
import Header from '../../components/Header';
import Text from '../../components/Text';
import { sendSOS } from '../../api/common';
import { auth } from '../../../firebaseConfig';

const { width } = Dimensions.get('window');
const IMAGE_SIZE = width * 0.8;

const SOS = ({ navigation, user, canUseSOSFeature, sosHelplineNumber }) => {
  const toast = useToast();
  const onBackPress = () => {
    navigation.navigate(ROUTES.PROFILE.name);
  };

  const onSOSImagePress = async () => {
    if (auth?.currentUser?.getIdToken()) {
      try {
        const response = await sendSOS({
          userId: _get(user, 'uid'),
          displayName: _get(user, 'displayName'),
          email: _get(user, 'email'),
          platform: Platform.OS,
        });
        if (!_isEmpty(response)) {
          if (Platform.OS === 'android' || Platform.OS === 'ios') {
            toast.show('Your organisation’s SOS contacts have been notified');
            if (sosHelplineNumber) {
              Linking.openURL(`tel:${sosHelplineNumber}`);
            }
          }
        }
      } catch {
        toast.show('Error sending SOS. please try again.');
      }
    }
  };

  if (!canUseSOSFeature) {
    return (
      <>
        <Header title="SOS" onBackPress={onBackPress} />
        <View style={styles.container}>
          <Text size={3} variant="bold" numberOfLines={0}>
            You dont have any sos contacts added.
          </Text>
        </View>
      </>
    );
  }

  return (
    <>
      <Header title="SOS" onBackPress={onBackPress} />
      <View style={styles.container}>
        <Text size={3} variant="bold" numberOfLines={0}>
          Tapping this SOS button would notify designated contacts withing your organization via email about your
          emergency situation.
        </Text>
        <Pressable onPress={onSOSImagePress}>
          <Image style={styles.imageStyle} source={IMAGES.SOS} />
        </Pressable>
        <Text size={2} variant="bold" numberOfLines={0}>
          🚨 Disclaimer: Our SOS feature is designed to provide assistance during emergencies, but it's essential to
          remember that it's not a substitute for immediate, real-world help.
        </Text>
      </View>
    </>
  );
};

// Enchant the canvas with a dash of textual magic.
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
    textAlign: 'center',
    marginTop: -16,
  },
  imageStyle: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    marginVertical: 16,
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
  canUseSOSFeature: getCanUseSOSFeature(state),
  sosHelplineNumber: getSOSHelpline(state),
});

export default connect(mapStateToProps)(SOS);
