import { useEffect, useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import Text from './Text';
import { ROUTES } from '../constants/routes';
import Button from './Button';
import { COLORS } from '../constants/colors';

const DeletionConfirmation = ({  onConfirmAccountDeletion,onCancelAccountDeletion,requestAccountDeletionInProgress }) => {
  const modalizeRef = useRef(null);
  useEffect(() => {
    if (requestAccountDeletionInProgress) {
      onOpen();
    }
  }, [modalizeRef.current]);

  const onOpen = () => {
    if (modalizeRef?.current) {
      modalizeRef.current?.open();
    }
  };

  const onCancel = () => {
    modalizeRef.current?.close();
    onCancelAccountDeletion();
  };

  const onDeleteAccount = () => {
    modalizeRef.current?.close();
    onConfirmAccountDeletion();
  };
  return (
    <Portal>
      <Modalize
        ref={modalizeRef}
        modalHeight={300}
        withHandle={false}
        closeOnOverlayTap={false}
        modalStyle={{ padding: 16 }}>
        <View style={styles.marginBottom}>
          <Text size={9} variant="bold" style={styles.marginBottom}>
            Account deletion confirmation
          </Text>
          <Text numberOfLines={0} style={styles.marginBottom}>
          All of your bookings (if any) will be deleted and your account will also be deleted. 
          You will not be able to retrieve them after the deletion. 
          </Text>
          <Text numberOfLines={0} style={styles.marginBottom}>
          Are you sure you want to delete your account?
          </Text>
        </View>
        <Button variant="small" style={{ marginBottom: 8 }} onPress={onDeleteAccount}>
          Delete Account
        </Button>
        <Text
          numberOfLines={0}
          style={[styles.marginBottom, { textAlign: 'center', paddingHorizontal: 8 }]}
          onPress={onCancel}
          size={3}
          variant="bold"
          color={COLORS.PRIMARY_COLOR}>
          Cancel
        </Text>
      </Modalize>
    </Portal>
  );
};

const styles = StyleSheet.create({
  marginBottom: {
    marginBottom: 16,
  },
});

const mapStateToProps = state => ({
  user: state.user.user,
});

// export default connect(mapStateToProps)(DeletionConfirmation);
export default DeletionConfirmation;
